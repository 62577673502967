import React from "react";
import { Tooltip } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import appRoutes from "../../routes/app.routes";
import { Link } from "react-router-dom";
const IndustryDropdown = () => {

  return (
    <div className="dropdown-menu">
      <ul>
        <Tooltip title="Focuses on the management, operation, and logistics of aviation facilities and services, including airports, airlines, and air traffic control." placement="top" arrow>
          <li>Aviation & Airport Operations</li>
        </Tooltip>
        <Tooltip title="Deals with financial institutions, services, and technologies, including banking, investment, and financial technology innovations. " placement="top" arrow>
          <li>Banking, Financial & Fintech</li>
        </Tooltip>
        <Tooltip title="Involves strategies, tools, and techniques for boosting sales, marketing products, and services, and nurturing customer relationships to drive revenue growth." placement="top" arrow>
          <li>Business, Sales & Marketing</li>
        </Tooltip>
        <Tooltip title="Encompasses businesses involved in the cultivation, distribution, and sale of cannabis products for medicinal and recreational use." placement="top" arrow>
          <li>Cannabis</li>
        </Tooltip>
        <Tooltip title="Involves the construction industry, with a focus on sustainable practices, environmental conservation, and energy efficiency." placement="top" arrow>
          <li>Construction, Environmental & Energy</li>
        </Tooltip>
        <Tooltip title="Refers to integrating technology in education, including online learning platforms, educational apps, and digital teaching tools." placement="top" arrow>
          <li>Edtech</li>
        </Tooltip>
        <Tooltip title="Focuses on the design, development, and implementation of engineering solutions, automation technologies, and robotic systems across various industries." placement="top" arrow>
          <li>Engineering, Automation & Robotics</li>
        </Tooltip>
        <Tooltip title=" Involves governmental institutions, legal services, and technologies to enhance governance, regulation, and legal processes." placement="top" arrow>
          <li>Government & Legal</li>
        </Tooltip>
        <Tooltip title="Covers healthcare services, pharmaceuticals, medical devices, and technologies specifically addressing women's health needs." placement="top" arrow>
          <li>Healthcare, Life Sciences & Femtech</li>
        </Tooltip>
        <Tooltip title=" Deals with insurance companies, policies, and emerging technologies aimed at improving insurance processes and customer experiences." placement="top" arrow>
          <li>Insurance & Insurtech</li>
        </Tooltip>
        <Tooltip title="Encompasses companies developing technologies, devices, and platforms for the interconnectedness of devices and data exchange over the Internet." placement="top" arrow>
          <li>Technology & Internet of Things (IoT)</li>
        </Tooltip>
        <Tooltip title=" Includes companies involved in media production, content distribution, telecommunications services, business development, sales, and marketing strategies to promote products and services to consumers and businesses." placement="top" arrow>
          <li>Media, Telecommunications (TMT), Business, Sales & Marketing</li>
        </Tooltip>
        <Tooltip title=" Involves producing goods using traditional and cutting-edge technologies, including advanced manufacturing processes such as 3D printing, IoT integration, and automation." placement="top" arrow>
          <li>Manufacturing & Advanced Manufacturing</li>
        </Tooltip>
      </ul>

      <Link to={appRoutes.IndustryExpertise.generatePath()}>
        <div className="viewall">
          <span className="texteh"> VIEW ALL  </span>
          <span className="menuic"> <ArrowForwardIcon />  </span>
        </div>
      </Link>
    </div>
  );
};

export default IndustryDropdown;