import React, { useState, useEffect } from "react";
import NoResultFound from "./components/noResultFound";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Paper, Container, CircularProgress, Menu, MenuItem } from "@material-ui/core/";
import { Button, ConfirmDialog, FlashMessage } from "../../components/common";
import _ from "lodash";
import { openUrlInNewTab } from "../../utils/helper";
import appRoutes from "../../routes/app.routes";
import unknownProfile from "../../assets/images/unknownProfile.jpeg";
import networkServices from "../../services/myNetwork.services";
import useQuery from "../../hooks/useQuery";
import {
  followCompanyRequest,
  // companyRequest,
  updateCompanyRequest,
  companyResetData,
} from "../../reducers/SearchAndConnect/searchAndConnect.reducer";
import { useDispatch, useSelector } from "react-redux";
import { rootReducersState } from "../../reducers";
import Avatar from "../../components/common/AvtarImage";
import { MoreHoriz } from "@material-ui/icons";
import BlockUserModal from "../../components/common/BlockUser";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";

export default function MyNetwork({ setNetworkCount, searchFilter }: any) {
  const pageSize = 18;
  const queryPrams = useQuery();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const typeSearch = queryPrams.get("type");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [searchData, setSearchData] = useState<any>([]);
  const [searchType, setSearchType] = useState<string>(searchFilter);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [removeId, setRemoveId] = useState<String>("");
  const [companyProfile, setCompanyProfile] = useState<any>([]);
  const { companyData } = useSelector(
    ({ search }: rootReducersState) => search.search
  );
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRowUserId, setCurrentRowUserId] = useState<number | null>(null);
  const [blockedUsers, setBlockedUsers] = useState<(string | number)[]>([]); // Explicit type


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchType(searchFilter);
    dispatch(companyResetData());
    setCurrentPage(1);
    setTotalCount(0);
    if (searchFilter === "company") {
      fetchCompanyData();
    } else {
      setCurrentPage(1);
      setTotalCount(0);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  const fetchCompanyData = async () => {
    setLoading(true);
    const company = await networkServices.searchAllCompany({
      pageSize: pageSize,
      page: 1,
    });
    // dispatch(companyResetData());
    // dispatch(companyRequest(_.get(company, "data", [])));
    setLoading(false);
    setCount(companyData?.length + 1 || 0);
    setTotalCount(company?.data?.pagination?.totalPages || 0);
    setCompanyProfile(company?.data.data);
  };

  const fetchData = async () => {
    setLoading(true);
    const searchData = await networkServices.searchAll({
      pageSize: pageSize,
      page: 1,
    });
    setSearchData(searchData?.data?.data || []);
    setCount(searchData?.data?.data?.length || 0);
    setLoading(false);
    setTotalCount(searchData?.data?.pagination?.totalPages || 0);
    setNetworkCount(count);
  };

  const fetchMoreCompanyData = async () => {
    setLoading(true);
    setCurrentPage((currentPage) => currentPage + 1);
    const company = await networkServices.searchAllCompany({
      pageSize: pageSize,
      page: currentPage + 1,
    });
    // dispatch(companyRequest(_.get(company, "data", [])));
    setLoading(false);
    setCompanyProfile(company?.data.data);

    setCount(companyData?.length + 1 || 0);
    setTotalCount(company?.data?.pagination?.totalPages || 0);
  };

  const candidateShowModal = (row) => {
    openUrlInNewTab(
      appRoutes.candidatePublicProfile.generateFullPath(row.slug)
    );
  };
  const candidateMassagePage = () => {
    openUrlInNewTab(appRoutes.candidateMessages.altPath);
  };
  const removeConnection = (id: string) => {
    setShowConfirmModal(true);
    setRemoveId(id);
  };

  const _handleRemove = async () => {
    await networkServices.removeConnection(removeId);
    setSearchData(_.filter(searchData, (item) => item.id !== removeId));
    setCount(count - 1);
    setShowConfirmModal(false);
  };

  const renderUserCardActionButton = (data: object) => {
    return (
      <Button
        className="btn-primary btn profile-card-connect-button-disabled"
        onClick={() => {
          removeConnection(data.id);
        }}
      >
        Remove
      </Button>
    );
  };

  const viewProfile = (slug: string) => {
    const win = window.open(
      appRoutes.CompanyPublicView.generatePath(slug),
      "_blank"
    );
    win.focus();
  };

  const handleFollowCompany = (payload) => {
    dispatch(
      followCompanyRequest({
        id: _.get(payload, "follow_id", 0),
        type: _.get(payload, "type", ""),
      })
    );
    const updatedRows = companyData.map((row) => {
      if (row?.follow_id === _.get(payload, "follow_id", 0)) {
        return {
          ...row,
          follow: _.get(payload, "type", "") === "follow" ? true : false,
        };
      } else {
        return row;
      }
    });

    dispatch(updateCompanyRequest(updatedRows));
    // TODO - update the internal store
  };
  const handleOpenModal = () => {
    setShowBlockModal(true);
  };

  const handleMenu = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowUserId(userId);
  };

  const handleBlockToggle = async (userId) => {
    try {
      const response = await httpRequest().delete(API_URLS.unBlockUser(userId));
      if (response.data.message === "Success") {

        setBlockedUsers((prevState) =>
          prevState.filter((user) => user !== userId)
        );
      }
    }
    catch (error) {
      FlashMessage("Unable to process request", "error");
    }
  };

  const userCard = (row) => {
    return (
      <Grid
        item
        className="search-result-profle-card-container search-result-profile-card-wrap"
        xs={12}
        sm={6}
        md={6}
        lg={4}
        key={row?.id}
      >
        <Paper className="search-result-profle-card search-result-profle-wrapper">
          <Grid>
            <Grid item xs={12} className="profle-card-top">
              <div className="profle-card-top-img">
                <div className="profile-picture">
                  <div
                    className="profile-img"
                    onClick={() => candidateShowModal(row)}
                  >
                    <Avatar
                      src={_.get(row, "profile_image", unknownProfile)}
                      size="md"
                    />
                  </div>
                </div>
              </div>
              <div
                className="profle-card-top-info cursor-pointer"
                onClick={() => candidateShowModal(row)}
              >
                <h3>{`${_.get(row, "first_name", "")} ${_.get(
                  row,
                  "last_name",
                  ""
                )}`}</h3>
                <p className="job-type">
                  {_.get(row, "job_title_custom", "") === "" ||
                    _.get(row, "job_title_custom", "") === null
                    ? _.get(row, "job_title.title", "")
                    : _.get(row, "job_title_custom", "")}
                </p>
              </div>
              <span className="block-icon-container">
                <MoreHoriz onClick={(e) => handleMenu(e, row.user_id)} />
              </span>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                className="block-menu-option-wrapper"

              >
                {/* Block Option */}
                <div className="block-box-wrapper">
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      if (currentRowUserId !== null && blockedUsers.includes(currentRowUserId)) {
                        handleBlockToggle(currentRowUserId); // Unblock
                      } else if (currentRowUserId !== null) {
                        handleOpenModal(currentRowUserId); // Open block modal
                      }
                    }}
                  >
                    {blockedUsers.includes(currentRowUserId) ? "Unblock" : "Block"}
                  </MenuItem>
                </div>
              </Menu>
            </Grid>
            <Grid item xs={12} className="profle-card-middle">
              <p className="card-content">
                {_.get(row, "personal_values", "")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              className="profle-card-bottom profile-btn-wrapper "
            >
              <Button color="secondary" onClick={() => candidateShowModal(row)}>
                View&nbsp;Profile
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  candidateMassagePage();
                }}
              >
                Message
              </Button>
              {renderUserCardActionButton(row)}

            </Grid>
          </Grid>
        </Paper >
      </Grid >
    );
  };

  const companyCard = (row) => {
    return (
      <Grid
        item
        className="search-result-profle-card-container"
        xs={12}
        sm={6}
        md={6}
        lg={4}
      >
        <Paper className="search-result-profle-card">
          <Grid>
            <Grid item xs={12} className="profle-card-top">
              <div className="profle-card-top-img">
                <div className="profile-picture">
                  <div className="profile-img">
                    <Avatar
                      src={_.get(row, "company_logo", null)}
                      size="md"
                    />
                  </div>
                </div>
              </div>
              <div className="profle-card-top-info">
                <h3>{_.get(row, "company_name", "")}</h3>
                <p className="job-type">
                  {!_.isEmpty(_.get(row, "industry", ""))
                    ? `${_.get(row, "industry", "")} |`
                    : ""}{" "}
                  {_.get(row, "employeeSize", "")}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} className="profle-card-middle">
              <p className="card-content">{_.get(row, "who_we_are", "")}</p>
            </Grid>
            <Grid item xs={12} className="profle-card-bottom">
              <Button
                color="secondary"
                onClick={() => viewProfile(_.get(row, "slug", ""))}
              >
                View Profile
              </Button>
              <Button
                className="btn-primary btn"
                onClick={() =>
                  handleFollowCompany({
                    ...row,
                    type: row?.follow ? "unfollow" : "follow",
                  })
                }
              >
                {row?.follow ? "Unfollow" : "Follow"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const getResultCard = (row) => {
    return <>{searchType === "candidate" ? userCard(row) : companyCard(row)}</>;
  };

  const fetchMoreData = async () => {
    setBtnLoading(true);
    setCurrentPage(currentPage + 1);
    const search: any = await networkServices.searchAll({
      pageSize: pageSize,
      page: currentPage + 1,
    });
    const data = search?.data?.data || [];
    const mergeData = [...searchData, ...data];
    setSearchData([...mergeData]);
    setCount(mergeData.length);
    setBtnLoading(false);
  };

  window.onscroll = _.debounce(() => {
    const docBottom =
      document.documentElement.getBoundingClientRect().bottom - 1200;
    const reachedBottom = window.innerHeight > docBottom;
    if (reachedBottom && currentPage < totalCount) {
      if (searchFilter === "company") {
        fetchMoreCompanyData();
      } else {
        fetchMoreData();
      }
    }
  }, 100);

  return (
    <>
      <ConfirmDialog
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => _handleRemove()}
        bodyText="Are sure want to remove connection from your network?"
        confirmText="Ok"
        title="Remove connection"
      />
      <Container
        maxWidth="lg"
        className="p-0 sub-container search-main-container search-sub-container"
      >
        {loading ? (
          <Grid container spacing={3} className="search-result-grid ">
            {["20%", "20%", "20%"].map((row: any, i) => {
              return (
                <Grid
                  item
                  className="search-result-profle-card-container search-result-profile-card-wrap "
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  key={i}
                >
                  <Grid className="search-result-profle-card">
                    <Grid item xs={12} className="profle-card-top">
                      <Skeleton height={60} width={60} variant="circle" />
                      <Skeleton
                        height={80}
                        width={200}
                        style={{ marginLeft: "20px" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="profle-card-middle"
                      style={{ margin: "20px 0" }}
                    >
                      <div className="profle-card-top-img">
                        <div className="profile-picture">
                          <div className="profile-img">
                            <Skeleton height={20} variant="text" />
                            <Skeleton height={20} variant="text" />
                            <Skeleton height={20} variant="text" />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="profle-card-bottom">
                      <Skeleton height={40} width={"100%"} />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : _.isEmpty(searchData) ? (
          <NoResultFound />
        ) : (
          <>
            {/* Search result user cards */}
            <Grid container spacing={3} className="search-result-grid">
              {searchFilter === "candidate" &&
                (searchData || []).map((row) => {
                  return getResultCard(row);
                })}
              {searchFilter === "company" &&
                (companyProfile || []).map((row) => {
                  return getResultCard(row);
                })}
            </Grid>
            <div className="see-more">
              {btnLoading && <CircularProgress color="secondary" size={15} />}
            </div>
            {/* <div style={{ textAlign: "center" }}>
              {searchFilter === "company" && (
                <span
                  className="span-link"
                  hidden={currentPage < totalCount}
                  onClick={() => {
                    fetchMoreCompanyData();
                  }}
                >
                  SEE MORE
                </span>
              )}
            </div> */}
          </>
        )}
      </Container>
      {currentRowUserId && (
        <BlockUserModal
          show={showBlockModal}
          onClose={() => {
            setShowBlockModal(false);
          }}
          blockedId={currentRowUserId}
          onBlockComplete={(userId) => {
            setBlockedUsers((prevState) => {
              if (!prevState.includes(userId)) {
                return [...prevState, userId];
              }
              return prevState;
            });
          }}
        />
      )}
    </>
  );
}
