// import React, { useEffect, useRef, useState } from "react";

// interface CodeProps {
//   clear: boolean,
//   [key: string]: any;
// }
// const CodeEnter = ({ handleOnChange, clear }: CodeProps) => {
//   const [inputValues, setInputValues] = useState(Array(6).fill(''));
//   const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
//   const handleClear = () => {
//     setInputValues(Array(6).fill(''));
//     inputRefs.current = Array(6).fill(null);
//   }
//   useEffect(() => {
//     if (clear) {
//       handleClear();
//     }
//   }, [clear]);

//   const handleInputChange = (index, value) => {
//     if (value.length === 1) {
//       setInputValues(prevValues => {
//         const newValues = [...prevValues];
//         newValues[index] = value;
//         if (value && index < newValues.length - 1) {
//           const nextInput = inputRefs.current[index + 1];
//           if (nextInput) {
//             nextInput.focus();
//           }
//         }
//         return newValues;
//       });
//     }
//   };

//   const handleSelect = (e, index) => {
//     const prevIndex = index;
//     if (inputRefs.current[prevIndex]) {
//       inputRefs.current[prevIndex].select();
//     }
//     e.preventDefault();
//   }

//   const handleKeyDown = (index, e) => {

//     if (e.key === 'ArrowDown') {
//       const nextIndex = index + 1;
//       if (inputRefs.current[nextIndex]) {
//         inputRefs.current[nextIndex].select();
//       }
//       e.preventDefault();

//     } else if (e.key === 'ArrowUp') {
//       const prevIndex = index - 1;
//       if (inputRefs.current[prevIndex]) {
//         inputRefs.current[prevIndex].select();
//       }
//       e.preventDefault();

//     } else if (e.key === 'ArrowRight') {
//       const nextIndex = index + 1;
//       if (inputRefs.current[nextIndex]) {
//         inputRefs.current[nextIndex].select();
//       }
//       e.preventDefault();

//     } else if (e.key === 'ArrowLeft') {
//       const prevIndex = index - 1;
//       if (inputRefs.current[prevIndex]) {
//         inputRefs.current[prevIndex].select();
//       }
//       e.preventDefault();

//     } else if (e.key === 'Backspace') {
//       e.preventDefault();
//       const prevIndex = index - 1;
//       if (prevIndex >= 0) {
//         const prevInput = inputRefs.current[prevIndex];
//         if (prevInput) {
//           prevInput.focus();

//           setInputValues(prevValues => {
//             const newValues = [...prevValues];
//             newValues[prevIndex] = '';
//             return newValues;
//           });
//         }
//       }
//     }

//   };
//   return (
//     <div >
//       {[1, 2, 3, 4, 5, 6].map((_, index) => (
//         <input
//           type="number"
//           className="otp-enter"
//           key={index}
//           value={inputValues[index]}
//           onChange={(e) => {
//             handleInputChange(index, e.target.value)
//             if (
//               handleOnChange
//             )
//               handleOnChange(index, e);
//           }}
//           ref={el => inputRefs.current[index] = el}
//           maxLength={1}
//           onKeyDown={(e) => {
//             handleKeyDown(index, e)
//           }}
//           autoComplete="new-password"
//           onSelect={(e) => handleSelect(e, index)}

//         />
//       ))}
//     </div>
//   );
// };

// export default CodeEnter;

import React, { useEffect, useRef, useState } from "react";

interface CodeProps {
  clear: boolean,
  numBoxes?: number,
  handleOnChange?: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void,
  [key: string]: any;
}

const CodeEnter = ({ handleOnChange, clear, numBoxes = 6 }: CodeProps) => {
  // Create state and refs based on the number of boxes
  const [inputValues, setInputValues] = useState(Array(numBoxes).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleClear = () => {
    setInputValues(Array(numBoxes).fill(''));
    inputRefs.current = Array(numBoxes).fill(null);
  };

  useEffect(() => {
    if (clear) {
      handleClear();
    }
      // eslint-disable-next-line
  }, [clear]);

  const handleInputChange = (index: number, value: string) => {
    if (value.length === 1) {
      setInputValues(prevValues => {
        const newValues = [...prevValues];
        newValues[index] = value;
        // Auto-focus the next input if exists
        if (value && index < newValues.length - 1) {
          const nextInput = inputRefs.current[index + 1];
          if (nextInput) {
            nextInput.focus();
          }
        }
        return newValues;
      });
    }
  };
  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      const nextIndex = index + 1;
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex]?.select();
      }
      e.preventDefault();
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      const prevIndex = index - 1;
      if (inputRefs.current[prevIndex]) {
        inputRefs.current[prevIndex]?.select();
      }
      e.preventDefault();
    } else if (e.key === 'Backspace') {
      e.preventDefault();
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        if (inputRefs.current[prevIndex]) {
          inputRefs.current[prevIndex]?.focus();
          setInputValues(prevValues => {
            const newValues = [...prevValues];
            newValues[prevIndex] = '';
            // Notify the parent about the cleared value:
            if (handleOnChange) {
              // Simulate an event with an empty value.
              handleOnChange(prevIndex, { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
            }
            return newValues;
          });
        }
      }
    }
  };
  

  const handleSelect = (e: React.SyntheticEvent, index: number) => {
    e.preventDefault();
    if (inputRefs.current[index]) {
      inputRefs.current[index]?.select();
    }
  };

  // const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
  //     const nextIndex = index + 1;
  //     if (inputRefs.current[nextIndex]) {
  //       inputRefs.current[nextIndex]?.select();
  //     }
  //     e.preventDefault();
  //   } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
  //     const prevIndex = index - 1;
  //     if (inputRefs.current[prevIndex]) {
  //       inputRefs.current[prevIndex]?.select();
  //     }
  //     e.preventDefault();
  //   } else if (e.key === 'Backspace') {
  //     e.preventDefault();
  //     const prevIndex = index - 1;
  //     if (prevIndex >= 0) {
  //       if (inputRefs.current[prevIndex]) {
  //         inputRefs.current[prevIndex]?.focus();
  //         setInputValues(prevValues => {
  //           const newValues = [...prevValues];
  //           newValues[prevIndex] = '';
  //           return newValues;
  //         });
  //       }
  //     }
  //   }
  // };

  return (
    <div>
      {Array.from({ length: numBoxes }, (_, index) => (
        <input
          key={index}
          type="number"
          className="otp-enter"
          value={inputValues[index]}
          onChange={(e) => {
            handleInputChange(index, e.target.value);
            if (handleOnChange) handleOnChange(index, e);
          }}
          ref={el => inputRefs.current[index] = el}
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(index, e)}
          autoComplete="new-password"
          onSelect={(e) => handleSelect(e, index)}
        />
      ))}
    </div>
  );
};

export default CodeEnter;
