import React, { useRef } from "react";
import EmailEditor from "react-email-editor";
import marketingSolutionServices from "../../../../services/marketingSolution.services";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../../routes/app.routes";
import { rootReducersState } from "../../../reducers";
import { useSelector } from "react-redux";
import Button from "../../../../components/common/Button";

const EmailEditorScreen = () => {
  const emailEditorRef: any = useRef(null);
  const history: any = useHistory();
  const desing_json: any = history?.location?.state?.design_json;
  const templateId: string = history?.location?.state?.id;
  const is_campaign: boolean = history?.location?.state?.is_campaign;
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    const parsedData =
      typeof desing_json === "string" ? JSON.parse(desing_json) : desing_json;
    if (parsedData) {
      emailEditorRef.current.editor.loadDesign(parsedData);
    }
  };

  const onSave = () => {
    try {
      if (emailEditorRef && emailEditorRef.current) {
        emailEditorRef.current.editor.exportHtml(async (data) => {
          const { design, html } = data;

          const res = await marketingSolutionServices.saveEmailTemplate({
            design,
            html,
            ...(templateId && { templateId }),
          });
          if (res.flag) {
            if(is_campaign){
            history.push({
              pathname: appRoutes.startCampaign.generatePath(
                companySlug,
                templateId
              ),
              state: { id: templateId },
            });
          }
          else{
            history.push({
              pathname: appRoutes.emailTemplates.generatePath(
                companySlug, 
              ) 
            })
          }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  return (
    <div>
      <div className="mb-16">
        <Button className="ml-2" onClick={() => onSave()}>
          Save Template
        </Button>
      </div>

      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        minHeight="85vh"
      />
    </div>
  );
};

export default EmailEditorScreen;
