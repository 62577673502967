import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Button,
} from "../../../components/common";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { saveImportedContactsRequest } from "../../../reducers/company/companyMarketingSolution.reducer";
import { rootReducersState } from "../../../reducers";
import StatusModal from "./statusModal";
import appRoutes from "../../../routes/app.routes";
import { useHistory } from "react-router-dom";

const ReviewInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { parsedContacts, tags, loading, flag, subscription_status } =
    useSelector(
      ({ company }: rootReducersState) => company.companyMarketingSolution
    );
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const handleContinue = () => {
    const formData = new FormData();
    formData.append("contacts", JSON.stringify(parsedContacts));
    formData.append("tags", JSON.stringify(tags));
    formData.append("is_subscribe", subscription_status);
    dispatch(saveImportedContactsRequest(formData));
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    history.push(appRoutes.contactList.generatePath(companySlug));
  };
 
  return (
    <>
      <div className="review-wrapper">
        <div className="review-title">
          <h3>Review and complete your import contact information</h3>
          <Button className="back-btn outlined" onClick={() => history.goBack()}>
            <ArrowBackIosIcon
              width="10"
              height="18"
              style={{ color: "#FF0990A6" }}
            />{" "}
            Back
          </Button>
        </div>
        <div className="review-body">
          <List className="list-wrapper">
            <ListItem dense>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Imported from: File upload" />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                {subscription_status ? <CheckCircleIcon /> : <CancelIcon />}
              </ListItemIcon>
              <ListItemText
                primary={`Email Marketing Status: ${
                  subscription_status ? "Subscribe" : "Unsubscribe"
                }`}
              />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Update Existing contacts: No" />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <CheckCircleIcon fill="#FF0990" />
              </ListItemIcon>
              <ListItemText primary="Tagged: Member" />
            </ListItem>
          </List>
          <div className="continue-btn-wrapper btn-group">
            <Button onClick={handleContinue} className="continue-btn btn btn-primary" loading={loading}>
              Continue
            </Button>
            <Button
              onClick={() => console.log("cancel clicked")}
              variant="outlined"
              className="continue-btn cancel-btn btn"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {!loading && flag && isOpen && (
        <StatusModal open={isOpen} handleClose={handleClose} />
      )}
    </>
  );
};

export default ReviewInfo;
