import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import marketingSolutionServices from "../../../../services/marketingSolution.services";
import _ from "lodash";
import { formatDate } from "../../../../utils/helper";
import { Button } from "../../../../components/common";
import history from "../../../../utils/history";
import appRoutes from "../../../../routes/app.routes";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../../reducers";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const CampaignList = () => {
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [campaignList, setCampaignList] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({});
  const [isFetching, setIsFetching] = useState(false);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const handlePageChange = async (pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const response: any = await marketingSolutionServices.getCampaignList({
          page,
          pageSize,
        });
        if (response.flag) {
          setCampaignList(response.data);
          setPagination(response.pagination);
        } else {
          console.error("Failed to fetch campaign list:", response.message);
        }
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching campaign list:", error);
      }
      setIsFetching(false);
    };

    fetchData();
  }, [page]);

  const showDataAndTime = (dateString) => {
    return moment(dateString).format("YYYY-MM-DD HH:mm:ss");
  };
  return (
    <>
      <div className="campaign-btn">
        <Button
          onClick={() =>
            history.push({
              pathname: appRoutes.emailTemplates.generatePath(
                companySlug,
              )
            })
          }
        >
          View Templates
        </Button>
        <Button
          onClick={() =>
            history.push(appRoutes.emailCampaign.generatePath(companySlug))
          }
          className="ml-2"
        >
          Create Campaign
        </Button>
        <Button
          onClick={() => {
            history.push({
              pathname:
                appRoutes.emailEditor.generatePath(companySlug),
              state: { id: uuidv4(), is_campaign: false },
            })
          }}
          className="ml-2"
        >
          Create Template
        </Button>
      </div>
      <div className="tab-content">
        <TableContainer component={Paper} className="campaign-table-container">
          <Table className="table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="column">Campaign Name</TableCell>
                <TableCell className="column">Sender Name</TableCell>
                <TableCell className="column">Sender Email</TableCell>
                <TableCell className="column">Preview</TableCell>
                <TableCell className="column">Tags</TableCell>
                <TableCell className="column">Subject</TableCell>
                <TableCell className="column">Status</TableCell>
                <TableCell className="column">Date</TableCell>
                <TableCell className="column">Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {!_.isEmpty(campaignList) &&
                    campaignList?.map((item: any, index) => (
                      <TableRow key={index}>
                        <TableCell>{_.get(item, "campaign_name", "")}</TableCell>
                        <TableCell>{_.get(item, "sender_name", "")}</TableCell>
                        <TableCell>{_.get(item, "sender_email", "")}</TableCell>
                        <TableCell>{_.get(item, "preview_text", "")}</TableCell>
                        <TableCell>
                          {item.campaign_tags === -1
                            ? "All subscribers in audience"
                            : _.get(item, "tags.tag_name", "")}
                        </TableCell>
                        <TableCell>{_.get(item, "subject", "")}</TableCell>
                        <TableCell>
                          {item.is_sent === 0 &&
                            !_.isEmpty(_.get(item, "schedule_at")) ? (
                            <Tooltip
                              title={showDataAndTime(
                                _.get(item, "schedule_at", "")
                              )}
                              placement="top"
                            >
                              <span>Scheduled</span>
                            </Tooltip>
                          ) : item.is_sent === 1 ? (
                            "Sent"
                          ) : (
                            "Not sent yet"
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(_.get(item, "created_at", ""))}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              history.push(
                                appRoutes.startCampaign.generatePath(
                                  companySlug,
                                  item.campaign_id
                                )
                              )
                            }
                            className="btn btn-primary"
                          >
                            {item.is_sent === 0 ? "Send Now" : "Resend"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}

            </TableBody>
          </Table>
          <div className="custom-pagination">
            <Pagination
              count={pagination.totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </TableContainer>
      </div>
    </>
  );
};

export default CampaignList;
