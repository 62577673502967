import React, { useState, useEffect, ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Scrollbars } from "react-custom-scrollbars"
import EditIcon from "@material-ui/icons/Edit"
import GetAppIcon from "@material-ui/icons/CollectionsBookmark"
import DeleteIcon from "@material-ui/icons/DeleteOutline"
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye"
import ShareIcon from "@material-ui/icons/Share"
import copyToClipboard from "copy-to-clipboard"
import Pagination from "@material-ui/lab/Pagination"
import { createUpdateJobReset } from "../../../reducers/job/jobs.reducer"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import { get, forEach, isEmpty, isUndefined, omit } from "lodash"
import { formatDate, openUrlInNewTab } from "../../../utils/helper"
import FilterListIcon from "@material-ui/icons/FilterList"
import Spinner from "../../../components/common/Spinner"
import Form from "./Form"
import Archive from "./Archive"
import { ReducerFlag } from "../../../types/reducer.types"
import appRoutes from "../../../routes/app.routes"
import { ConfirmDialog } from "../../../components/common"
import NotFoundPage from "../../../components/common/NotFoundPage"
import jobsServices from "../../../services/jobs.services"
import FlashMessage from "../../../components/common/FlashMessage"

const _ = { get, forEach, isEmpty, isUndefined, omit }

interface Column {
  id:
  | "title"
  | "status"
  | "external_link"
  | "candidates"
  | "location"
  | "status"
  | "totalApplicants"
  | "created_at"
  | "actions"
  label: any
  minWidth?: number
  align?: "right"
  format?: (value: any) => any
}

const JobList = (props) => {
  const [dateSort, setDateSort] = useState(true)
  const [isArchiveJobId, setArchiveJobId] = useState(0)
  const [isArchiveModalShow, setArchiveModalShow] = useState(false)
  const dispatch = useDispatch()
  const open = _.get(props, "open", false)
  const page = _.get(props, "page", 1)
  const setPage = _.get(props, "setPage", null)
  const handleOpen = _.get(props, "handleOpen", null)
  const handleClose = _.get(props, "handleClose", null)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deleteJobId, setDeleteJobId] = useState<string>("")
  const [archiveflag, setArchiveflag] = useState(false)
  const [pagination, setPagination] = useState({})
  const [jobData, setJobData] = useState([])
  const [jobLoading, setJobLoading] = useState(false)

  const {
    jobs: { loading, jobCreateFlag, jobUpdateFlag, jobFilter },
  } = useSelector(({ job }: any) => job)

  const fetchJobList = async (payload) => {
    try {
      setJobLoading(true)
      const result = await jobsServices.listCompanyJobs(payload)
      if (result.flag) {
        setJobData(result.data?.data)
        setPagination({
          current:
            _.get(result, "data.pagination.page", 0) === 0
              ? 1
              : _.get(result, "data.pagination.page", 0),
          total: _.get(result, "data.pagination.totalPages", 0),
          pageSize: _.get(result, "data.pagination.pageSize", 10),
          totalRecords: _.get(result, "data.pagination.pageRecords", 0),
          totalResult: _.get(result, "data.pagination.totalResult", 0),
        })
        setJobLoading(false)
      } else {
        setJobLoading(false)
        FlashMessage(result.message, "error")
      }
    } catch (error) {
      FlashMessage(error?.message, "error")
    }
  }

  const handleSortByDate = () => {
    setDateSort(!dateSort)
    const payload = {
      ...jobFilter,
      orderField: "updated_at",
      orderBy: dateSort ? "asc" : "desc",
    }
    fetchJobList({ ...payload })
  }

  const columns: Column[] = [
    {
      id: "title",
      label: "Job Title",
      minWidth: 170,
    },
    {
      id: "location",
      label: "Location",
      minWidth: 100,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 80,
    },
    {
      id: "totalApplicants",
      label: "Total Applicants",
      minWidth: 80,
    },
    {
      id: "created_at",
      label: (
        <>
          {"Date Published"}
          <FilterListIcon
            className={`filter-icon ${dateSort && "filter-desc"}`}
            onClick={() => handleSortByDate()}
          />
        </>
      ),
      minWidth: 100,
      format: (value) => {
        return formatDate(value)
      },
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 80,
    },
  ]

  // Fetch Jobs Hook
  useEffect(() => {
    const path = _.get(props, "location.pathname", "")
    let payload = {}

    if (path.includes("active")) {
      payload = { type: "active" }
    } else if (path.includes("archive")) {
      payload = { type: "archive" }
    } else if (path.includes("drafts")) {
      payload = { type: "drafts" }
    }

    fetchJobList({ ...jobFilter, ...payload, page })
    // eslint-disable-next-line
  }, [jobFilter])

  // Create Job success Hook
  useEffect(() => {
    if (jobCreateFlag === ReducerFlag.SUCCESS) {
      handleClose()
      const path = _.get(props, "location.pathname", "")
      if (!path.includes("archive")) {
        fetchJobList({ ...jobFilter })
        dispatch(createUpdateJobReset())
      }
    }

    // eslint-disable-next-line
  }, [jobCreateFlag])

  // Update Job success Hook
  useEffect(() => {
    if (jobUpdateFlag === ReducerFlag.SUCCESS) {
      handleClose()
      const path = _.get(props, "location.pathname", "")
      if (!path.includes("archive")) {
        fetchJobList({ ...jobFilter })
      }
    }

    // eslint-disable-next-line
  }, [jobUpdateFlag])

  // Archive Job success Hook
  useEffect(() => {
    if (archiveflag === true) {
      handleArchiveModalClose()
      fetchJobList({ ...jobFilter })
    }

    // eslint-disable-next-line
  }, [archiveflag])

  const handleArchiveModalOpen = (_id) => {
    setArchiveJobId(_id)
    setArchiveModalShow(true)
  }

  const handleArchiveModalClose = () => {
    setArchiveModalShow(false)
  }

  const handleShowJob = (job) => {
    if (job?.slug) {
      openUrlInNewTab(appRoutes.jobDetail.generatePath(job.slug))
    }
  }

  const handleDelete = (id) => {
    setDeleteModal(true)
    setDeleteJobId(id)
  }

  const deleteJob = async (jodId) => {
    try {
      setJobLoading(true)
      const result = await jobsServices.deleteJob(jodId)
      if (result.flag) {
        const currentData: object[] = []
        if (jodId) {
          jobData.forEach((element: any) => {
            if (element?.id !== jodId) {
              currentData.push(element)
            }
          })
        }
        setJobData(currentData)
        setJobLoading(false)
      } else {
        setJobLoading(false)
        FlashMessage(result.message, "error")
      }
    } catch (error) {
      FlashMessage(error?.message, "error")
    }
  }

  const handleDeleteJob = () => {
    deleteJob(deleteJobId)
    setDeleteModal(false)
    setDeleteJobId("")
  }

  const archievedJob = async (jodId) => {
    try {
      setArchiveflag(true)
      const result = await jobsServices.archiveJob(jodId)
      if (result.flag) {
        setArchiveflag(false)
      } else {
        setArchiveflag(false)
        FlashMessage(result.message, "error")
      }
    } catch (error) {
      FlashMessage(error?.message, "error")
    }
  }
  const handleJobArchived = () => {
    archievedJob(isArchiveJobId)
  }

  const handlePaginationChange = (
    event: ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    fetchJobList({ ...jobFilter, page: value })
  }

  return (
    <>
      <Paper className="paper-wrapper">
        <Spinner visible={jobLoading} loadingTip={""} >
          {_.isEmpty(jobData) && !(jobLoading) ? (
            <NotFoundPage
              handleClick={() => handleOpen()}
              displayAddNewButton={true}
              buttonText="Add new Job"
              description="Add jobs to level up your empowerHER networks"
            />
          ) : (
            <Scrollbars
              renderThumbHorizontal={() => <div />}
              renderView={({ children }) => (
                <TableContainer className="job-list-wrapper">
                  {children}
                </TableContainer>
              )}
              className="job-list-scroller"
            >
              <Table stickyHeader aria-label="job listing table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(jobData || []).map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          if (column.id === "actions") {
                            return (
                              <TableCell
                                className="actions"
                                key={"actions"}
                                align={"center"}
                              >
                                <Tooltip title="Edit" placement="top" arrow>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => handleOpen(row.id)}
                                    disableRipple
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                {_.get(row, "is_public") === 1 && (
                                  <>
                                    <Tooltip title="Show" placement="top" arrow>
                                      <IconButton
                                        aria-label="show"
                                        onClick={() => handleShowJob(row)}
                                        disableRipple
                                      >
                                        <RemoveRedEyeIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Share"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        aria-label="show"
                                        onClick={() => {
                                          if (row?.slug) {
                                            const url =
                                              appRoutes.jobDetail.generatePath(
                                                row.slug
                                              )
                                            copyToClipboard(url)
                                            FlashMessage(
                                              "Link copied successfully"
                                            )
                                          }
                                        }}
                                        disableRipple
                                      >
                                        <ShareIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                                {row.is_public ? (
                                  <Tooltip
                                    title="Archive"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      aria-label="archive"
                                      title="Archive"
                                      onClick={() =>
                                        handleArchiveModalOpen(row.id)
                                      }
                                      disableRipple
                                    >
                                      <GetAppIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                {_.get(row, "is_public") === 1 && (
                                  <Tooltip title="Delete" placement="top" arrow>
                                    <IconButton
                                      aria-label="delete"
                                      title="Delete"
                                      onClick={() => handleDelete(row.id)}
                                      disableRipple
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            )
                          }
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Scrollbars>
          )}
        </Spinner>

        <Form open={open} handleClose={handleClose} />
        <Archive
          open={isArchiveModalShow}
          loading={loading}
          handleClose={handleArchiveModalClose}
          handleJobArchived={() => handleJobArchived()}
        />
      </Paper>
      <Grid container justify="center">
        {!_.isEmpty(jobData) && (
          <Pagination
            count={_.get(pagination, "total", 0)}
            defaultPage={page}
            variant="outlined"
            color="secondary"
            className="jobs-pagination"
            onChange={(e, value) => handlePaginationChange(e, value)}
          />
        )}
      </Grid>
      <ConfirmDialog
        visible={deleteModal}
        bodyText="Are you sure want to Delete this job?"
        title="Confirm"
        confirmText="Delete"
        loading={loading}
        onConfirm={() => handleDeleteJob()}
        onCancel={() => setDeleteModal(false)}
      />
    </>
  )
}
export default JobList
