import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, MenuItem, Menu, TextareaAutosize } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import _ from "lodash";
import appRoutes from "../../routes/app.routes";
import { Button, Modal, Input } from "../../components/common";
import authServices from "../../services/auth.services";
import { FlashMessage } from "./index";
import { rootReducersState } from "../../reducers";
import {
  sessionOutRequest,
  setCurrentCompanyRequest,
} from "../../reducers/auth/session.reducer";
import { ImageMimeTypes, IS_DEVELOPMENT } from "../../utils/appConstants";
import { readFile } from "../../utils/cropImageHelper";
import ProfileImageCrop from "./ProfileImageCrop";
import SelectNew from "./SelectNew";
import { pluckFromArray } from "../../utils/helper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import { industryListRequest } from "../../reducers/industry/industry.reducer";
import { employeeSizeListRequest } from "../../reducers/employeeSize/employeeSize.reducer";
import Image from "../../components/common/Image";
import { fetchSessionDataRequest } from "../../reducers/auth/session.reducer";
import AsyncSelect from "./AsyncSelect";
import commonServices from "../../services/common.services";
import useSessionUser from "../../hooks/useSessionUser";
import ReferFriend from "./ReferFriend";
import ConfirmDialog from "./ConfirmDialog";
import Avatar from "../common/AvtarImage";

let searchDelay: NodeJS.Timeout;

type FormInputs = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  company_logo: string;
  banner_image: string;
};

interface UserProfileIconInterface {
  showProfile?: boolean;
  fromSide?: "company" | "candidate";
}

const UserProfileIcon = (props: UserProfileIconInterface) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { IS_COMPANY, currentCompanyLogo, userFullName } = useSessionUser();

  // States
  const [showPasswordChangeModal, setShowPasswordChangeModal] =
    useState<boolean>(false);
  // eslint-disable-next-line
  const [changePasswordLoading, setChangePasswordLoading] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //TODO make the default state false
  const [showCreateCompanyModal, setShowCreateCompanyModal] =
    useState<boolean>(false);
  const [createCompanyLoading, setCreateCompanyLoading] =
    useState<boolean>(false);
  const [picData, setPicData] = useState<any>(null);
  const [bannerPath, setBannerPath] = useState("");
  const [companyFile, setCompanyFile] = useState<string | Blob>("");
  const [showCropImage, setShowCropImage] = useState(false);
  const [cropImage, setCropImage] = useState<any>(null);
  const [showCropImageBanner, setShowCropImageBanner] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bannerImage, setBannerImage] = useState<any>(null);
  const [fileName, setFileName] = useState("");
  const [industryOptions, setIndustryOptions] = useState([]);
  const [showEmploySizeError, setShowEmploySizeError] = useState(false);
  const [showIndustryError, setShowIndustryError] = useState(false);
  // eslint-disable-next-line
  const [finalImageBlob, setFinalImageBlob] = useState<Blob>();
  const [employeeSizeOption, setEmployeeSizeOption] = useState<any>([]);
  const [whoWeAre, setWhoWeAre] = useState("");
  // eslint-disable-next-line
  const [bannerImageName, setBannerImageName] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bannerImageFile, setBannerImageFile] = useState("");
  // eslint-disable-next-line
  const [logoImageName, setLogoImageName] = useState("");
  const [missionAndVision, setMissionAndVision] = useState("");
  const [diversityAndInclusion, setDiversityAndInclusion] = useState("");
  const [visibleReferFriend, setVisibleReferFriend] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const industries = useSelector(({ industry }: any) => industry);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    list: { data: employeeSizeData },
  } = useSelector(({ employeeSize }: any) => employeeSize);
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset: resetForm,
    setError,
    control,
  } = useForm<FormInputs>();

  const {
    companyProfile: {
      data: { companyProfile: detail },
    },
  } = useSelector(({ company }: any) => company);

  useEffect(() => {
    if (industries.industries.data) {
      let data = industries.industries.data.map((item) => {
        return { value: item.id, label: item.title };
      });
      setIndustryOptions(data);
    }
  }, [industries]);

  useEffect(() => {
    if (!_.isEmpty(employeeSizeData)) {
      const setOption = pluckFromArray(
        employeeSizeData,
        "id",
        "title",
        "value",
        "label"
      );
      setEmployeeSizeOption(setOption);
    }
  }, [employeeSizeData, detail]);

  // Reducers
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenUser = _.get(sessionReducer, "currentUser", {});
  const jobTitle =
    _.get(tokenUser, "profile.jobTitle.title", "") ||
    _.get(tokenUser, "profile.job_title_custom", "");
  // const getSlug = _.get(tokenUser, "slug", "");
  // const setSlug = getSlug.replace(/[^a-z]/gi,'');
  const token = _.get(sessionReducer, "token", null);
  // Hooks
  const currentMatchingPassword = watch("newPassword", "");
  const open = Boolean(anchorEl);
  const imagePath = IS_COMPANY
    ? currentCompanyLogo
    : _.get(tokenUser, "profile_image", "");
  const companyCreated = _.get(tokenUser, "companies", []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (redirect: boolean) => {
    setAnchorEl(null);

    if (redirect) {
      history.push(appRoutes.candidateProfileView.path);
    }
  };

  const _handleLogout = () => {
    setLoading(true);
    dispatch(sessionOutRequest());
    setLogoutModal(false);
  };

  const _handleShowPasswordChangeModal = () => {
    setShowPasswordChangeModal(true);
    setAnchorEl(null);
  };

  const handleScheduler = () => {
    history.push(appRoutes.scheduler.path);
  };

  const handlePostList = () => {
    history.push(appRoutes.myPostList.path);
  };

  const handleArticleList = () => {
    history.push(appRoutes.myArticleList.path);
  };

  const handleSettingsOnClick = () => {
    history.push(
      appRoutes.candidateProfileSettings.generatePath("profile-visibility")
    );
    setAnchorEl(null);
  };

  const handleReferAFriendOnClick = () => {
    setVisibleReferFriend(true);
    setAnchorEl(null);
  };

  const _handleClosePasswordChangeModal = () => {
    // Close only if request is not in progress
    if (!changePasswordLoading) {
      setShowPasswordChangeModal(false);
      resetForm({});
    }
  };

  // Company functions
  const _handleShowCreateCompanyModal = () => {
    if (!_.isEmpty(companyCreated)) {
      FlashMessage("You have reached maximum number of Company page", "error");
      return;
    }

    setShowCreateCompanyModal(true);
    setAnchorEl(null);

    if (tokenUser) {
      _.isEmpty(industryOptions) && dispatch(industryListRequest());
      _.isEmpty(employeeSizeData) && dispatch(employeeSizeListRequest());
    }
  };

  const _handleCloseCreateCompanyModal = () => {
    setShowCreateCompanyModal(false);
    resetForm({});
  };

  const onChangePicture = async (e) => {
    if (!_.isEmpty(e.target.files)) {
      const file = e.target.files[0];
      setCompanyFile(file);
      setLogoImageName(file.name);
      const fileType = file.type;

      if (ImageMimeTypes.includes(fileType)) {
        const imageDataUrl = await readFile(file);
        setCropImage(imageDataUrl);
        setShowCropImage(true);
      } else {
        FlashMessage("Please select valid png or jpeg file", "error");
      }
    }
  };

  const handleCropImage = async (blobData) => {
    setFinalImageBlob(blobData);
    const src = URL.createObjectURL(blobData);
    // setPicData(src);
    const payload = new FormData();
    payload.append("uploadType", "image");
    payload.append("files", blobData, fileName);
    if (showCropImageBanner) {
      //   const data = { type: "banner", payload: payload };
      setBannerPath(src);
      //   dispatch(uploadCompanyBannerImageRequest(data));
    } else {
      //   const data = { type: "image", payload: payload };
      setPicData(src);
      //   dispatch(uploadCompanyProfileImageRequest(data));
    }
  };

  const onSubmit = async (formData: FormInputs) => {
    setChangePasswordLoading(true);
    try {
      const data: any = await authServices.changePassword({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      });

      if (typeof data?.errors === "object") {
        _.forEach(data.errors, (errMsg, field: any) => {
          setError(field, { type: "manual", message: errMsg });
        });
      } else if (data.flag === true) {
        FlashMessage("Password changed successfully");
        _handleClosePasswordChangeModal();
      }
    } catch (error: any) {
      FlashMessage("Unable to change the password", "error");
    } finally {
      setChangePasswordLoading(false);
    }
  };

  const setBanner = async (e: any) => {
    if (!_.isEmpty(e.target.files)) {
      const file = e.target.files[0];
      const fileName = e.target.files[0].name;
      // setBannerImageFile(file);
      setBannerImageName(fileName);
      const fileType = file.type;

      if (ImageMimeTypes.includes(fileType)) {
        const imageDataUrl = await readFile(file);
        setBannerImage(imageDataUrl);
        setFileName(fileName);
        setCropImage(imageDataUrl);
        setShowCropImageBanner(true);
      } else {
        FlashMessage("Please select valid png or jpeg file", "error");
      }
    }
  };

  // const industryId = watch("industry_id");
  // const industry_id = JSON.parse(industryId);
  const _handleCreateCompany = async (formData) => {
    setCreateCompanyLoading(true);
    let form_Data = new FormData();
    form_Data.append("banner_image", bannerImageFile);
    form_Data.append("company_logo", companyFile);
    form_Data.append("company_name", formData.company_name);
    form_Data.append("city_id", formData?.city_id?.value || 0);
    form_Data.append("slug", formData.slug);
    form_Data.append("website", formData.website);
    form_Data.append("employee_size_id", formData.employee_size_id.value);
    form_Data.append("industry_id", formData.industry_id.value);
    form_Data.append("who_we_are", whoWeAre);
    form_Data.append("mission_and_vision", missionAndVision);
    form_Data.append("diversity_and_inclusion", diversityAndInclusion);
    if (!formData.industry_id.value) {
      setShowIndustryError(true);
    } else if (!formData.employee_size_id.value) {
      setShowEmploySizeError(true);
    } else {
      try {
        const response = await httpRequest().post(
          `${API_URLS.createCompany}`,
          form_Data
        );
        if (response.data?.status === 200) {
          setShowEmploySizeError(false);
          setShowIndustryError(false);
          setBannerImage(null);
          setPicData(null);
          _handleCloseCreateCompanyModal();
          FlashMessage(response.data?.message);
          dispatch(fetchSessionDataRequest());
        }
        return response;
      } catch (error) {
        const errorList = error?.response?.data?.errors || {};
        if (Object.values(errorList).length > 0) {
          const firstError = Object.values(errorList)[0] as string;
          FlashMessage(firstError, "error");
        } else {
          FlashMessage(error.response.data.message, "error");
        }
      }
    }
    setCreateCompanyLoading(false);
  };

  // Search location with delay of 1 second
  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = await commonServices.fetchLocations(search);
        const locations = _.get(locationData, "data.data", []).map((c) => ({
          value: c.id,
          label: c.location,
        }));

        resolve(locations);
      }, 1000);
    });

  const handleHelpAndSupport = () => {
    history.push(appRoutes.contactUs.path);
  };
  return (
    <>
      {!token ? (
        <div className="nav-item">
          <Link to={appRoutes.userLogin.path} className="nav-sign-in">
            {appRoutes.userLogin.title}
          </Link>
        </div>
      ) : (
        <div className={`nav-item profile-link`}>
          <div className="profile-link-inner">
            <div className="online-indicator"></div>
            <div className="user-profile-icon" onClick={handleMenu}>
              <Avatar src={imagePath} size="xsm"></Avatar>
              <div className="useravatar-info">
                <h2>Me</h2>
                <ArrowDropDownIcon />
              </div>
            </div>
            <Menu
              id="menu-appbar"
              className="profile-menu-option-wrapper"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={() => handleClose(false)}
            >
              <div className="profile-box-wrapper">
                <div className="user-info" onClick={() => handleClose(true)}>
                  <div className="user-info-icon">
                    <Avatar src={imagePath} size="xsm" />
                  </div>
                  <div className="ml-2">
                    <div className="font-bold">{userFullName}</div>
                    <div className="font-sm mt-2">{jobTitle}</div>
                  </div>
                </div>

                {!IS_COMPANY && (
                  <>
                    {_.isEmpty(companyCreated) && (
                      <MenuItem onClick={() => _handleShowCreateCompanyModal()}>
                        Create a Company
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => _handleShowPasswordChangeModal()}>
                      Change Password
                    </MenuItem>
                    <MenuItem onClick={() => handlePostList()}>
                      My Post
                    </MenuItem>
                    <MenuItem onClick={() => handleArticleList()}>
                      My Article
                    </MenuItem>
                    {IS_DEVELOPMENT && (
                      <>
                        <MenuItem onClick={() => handleScheduler()}>
                          Scheduler
                        </MenuItem>
                        <MenuItem onClick={() => handleSettingsOnClick()}>
                          Settings
                        </MenuItem>
                      </>
                    )}
                    <MenuItem onClick={() => handleReferAFriendOnClick()}>
                      Refer a Friend
                    </MenuItem>
                  </>
                )}
                {!_.isEmpty(companyCreated) &&
                  Array.isArray(companyCreated) && (
                    <>
                      <span className="manage-company">Manage Company</span>
                      {companyCreated.map((row: any) => (
                        <MenuItem
                          key={row?.slug}
                          className="company-name"
                          onClick={() => {
                            // Set the company
                            dispatch(setCurrentCompanyRequest(row));
                            const path =
                              appRoutes.companyDashboard.generatePath(
                                row?.slug
                              );
                            history.push(path);
                          }}
                        >
                          {row?.company_name}
                        </MenuItem>
                      ))}
                    </>
                  )}
                <MenuItem onClick={() => handleHelpAndSupport()}>Help & Support</MenuItem>
                <MenuItem onClick={() => setLogoutModal(true)}>Logout</MenuItem>
              </div>
            </Menu>
          </div>
        </div>
      )}

      <ConfirmDialog
        visible={logoutModal}
        bodyText="Are you sure want to logout from the application?"
        confirmText="Logout"
        title="Confirm"
        loading={loading}
        onConfirm={() => _handleLogout()}
        onCancel={() => setLogoutModal(false)}
      />

      <Modal
        visible={showPasswordChangeModal}
        onClose={() => _handleClosePasswordChangeModal()}
        title="Change Password"
        className="change-pw-modal"
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} className="pb-0">
              <Input
                name="currentPassword"
                type="password"
                externalLabel={{ label: "Current password" }}
                placeholder="Current password"
                validationObj={errors}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please enter current password",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} className="pb-0">
              <Input
                name="newPassword"
                type="password"
                externalLabel={{ label: "New password" }}
                placeholder="New password"
                validationObj={errors}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please enter new password",
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                    message:
                      "Must content at least one upper case, lower case, digit, special character and no white space",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} className="pb-0">
              <Input
                name="confirmPassword"
                type="password"
                externalLabel={{ label: "Confirm password" }}
                placeholder="Confirm password"
                validationObj={errors}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please enter confirm password",
                  },
                  validate: (value) =>
                    value === currentMatchingPassword ||
                    "The re-entered do not match",
                })}
              />
            </Grid>
            <Grid item xs={12} className="text-right form-actions">
              <Button
                type="submit"
                color="primary"
                loading={changePasswordLoading}
              >
                Change Password
              </Button>
              <Button
                onClick={() => _handleClosePasswordChangeModal()}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* create company popup */}
      <Modal
        visible={showCreateCompanyModal}
        onClose={() => {
          _handleCloseCreateCompanyModal();
          setBannerImage(null);
          setPicData(null);
        }}
        title="Create a Company"
        className="create-company-modal"
        size="width-1000"
      >
        <form noValidate onSubmit={handleSubmit(_handleCreateCompany)}>
          <Grid container>
            <Grid item xs={12} className="pb-0 bannerClass">
              <Input
                name="banner_image"
                style={{ display: "none" }}
                onChange={(e) => setBanner(e)}
                type="file"
                // validationObj={errors}
                // inputRef={register({
                //   required: {
                //     value: true,
                //     message: "Please Upload Company Banner Image",
                //   },
                // })}
                placeholder="Banner"
              />
              <label className={"banner-label"} htmlFor="banner_image">
                <div className="banner-img">
                  {bannerPath ? (
                    <Image src={bannerPath} alt="Company Banner" />
                  ) : (
                    <>
                      <span className={"mb-1 d-flex align-items-center"}>
                        <AddIcon /> ADD YOUR
                      </span>
                      <h3 className={"mt-1"}>COMPANY BANNER</h3>
                      {errors.banner_image ? (
                        <span className="error-helper">
                          Please Upload Company Banner Image
                        </span>
                      ) : null}
                    </>
                  )}
                </div>
              </label>
            </Grid>
            <div style={{ padding: "0 25px" }} className="body w-100">
              <Grid
                item
                xs={12}
                className="pb-0  mb-20 profile-grid p-relative"
              >
                <Input
                  name="company_logo"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e: any) => onChangePicture(e)}
                  placeholder="profile"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please Upload Company Logo",
                    },
                  })}
                />
                <div className="company-logo">
                  <label
                    htmlFor="company_logo"
                    className="profile-label d-flex"
                  >
                    {picData ? (
                      <Image src={picData} alt="Company Logo" />
                    ) : (
                      <>
                        <span className={"mb-1 d-flex align-items-center"}>
                          {" "}
                          <AddIcon />
                          ADD YOUR
                        </span>
                        <h3>COMPANY Profile</h3>
                      </>
                    )}
                  </label>
                </div>
                {errors.company_logo ? (
                  <span className="error-helper">
                    Please Upload Company Logo
                  </span>
                ) : null}
                {!picData ? (
                  <div className="img-info">
                    <span>Select new image / logo</span>
                    <p>Square format, JPG or PNG, up to 1 Mb</p>
                  </div>
                ) : null}
              </Grid>
              <Grid container spacing={2} className={"mb-20"}>
                <Grid item xs={12} md={4} className="pb-0">
                  <Input
                    name="company_name"
                    type="text"
                    externalLabel={{ label: "Company Name" }}
                    placeholder="Name"
                    validationObj={errors}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter company name",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4} className="pb-0">
                  <Input
                    name="slug"
                    type="text"
                    externalLabel={{
                      label: "Profile URL (employHER.com/company)",
                    }}
                    placeholder="Profile URL"
                    validationObj={errors}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter profile url",
                      },
                      maxLength: {
                        value: 20,
                        message: "Maximum text length exceeded",
                      },
                      pattern: {
                        value: /^[a-zA-Z-0-9]+$/,
                        message: "Only alphabets and dash are allowed",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4} className="pb-0">
                  <AsyncSelect
                    name="city_id"
                    placeholder="Search City, State or country..."
                    externalLabel={{
                      label: "Location",
                      className: "required",
                    }}
                    onStartSearching={searchLocations}
                    validationObj={errors}
                    control={control}
                    noOptionsMessage="No matching locations found"
                    rules={{
                      required: {
                        value: true,
                        message: "Please select location from list",
                      },
                    }}
                  />
                  {/* {showLocationError ? (
                      <span className="error-helper">
                        Please enter location
                      </span>
                    ) : null} */}
                </Grid>
              </Grid>
              <Grid container spacing={2} className={"mb-20"}>
                <Grid item xs={12} md={4} className="pb-0">
                  <SelectNew
                    name="industry_id"
                    externalLabel={{ label: "Industry" }}
                    placeholder="Select an Industry"
                    className="select-box"
                    options={industryOptions}
                    isSearchable={true}
                    validationObj={errors}
                    control={control}
                    required={true}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select an industry",
                      },
                    }}
                  />
                  {showIndustryError ? (
                    <span className="error-helper">
                      Please select an industry
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4} className="pb-0">
                  <SelectNew
                    name="employee_size_id"
                    externalLabel={{ label: "Employee size" }}
                    placeholder="Select the number of employees"
                    className="select-box"
                    options={employeeSizeOption}
                    isSearchable={true}
                    validationObj={errors}
                    control={control}
                    required={true}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter company employee size",
                      },
                    }}
                  />
                  {showEmploySizeError ? (
                    <span className="error-helper">
                      Please enter company employee size
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4} className="pb-0">
                  <Input
                    name="website"
                    type="text"
                    externalLabel={{
                      label: "Website (https://example.com)",
                    }}
                    placeholder="https://"
                    validationObj={errors}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter company website",
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={"mb-20"}>
                <Grid item xs={12} md={4} className="pb-0">
                  <label>
                    Who we are
                    <TextareaAutosize
                      name="who_we_are"
                      onChange={(e) => setWhoWeAre(e.target.value)}
                      placeholder="Write here"
                    />
                  </label>
                </Grid>
                <Grid item xs={12} md={4} className="pb-0">
                  <label>
                    Mission and Vision
                    <TextareaAutosize
                      name="mission_and_vision"
                      onChange={(e) => setMissionAndVision(e.target.value)}
                      placeholder="Write mission and vision here"
                    />
                  </label>
                </Grid>
                <Grid item xs={12} md={4} className="pb-0">
                  <label>
                    Diversity and inclusion
                    <TextareaAutosize
                      name="diversity_and_inclusion"
                      onChange={(e) => setDiversityAndInclusion(e.target.value)}
                      placeholder="Write diversity and inclusion here"
                    />
                  </label>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className=" form-actions"
                style={{
                  justifyContent: "center",
                  margin: "30px 0 35px",
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  style={{ marginRight: "30px" }}
                  onSubmit={(formdata: any) => _handleCreateCompany(formdata)}
                  loading={createCompanyLoading}
                >
                  CREATE COMPANY
                </Button>
                <Button
                  onClick={() => {
                    _handleCloseCreateCompanyModal();
                    setBannerImage(null);
                    setPicData(null);
                  }}
                  color="secondary"
                >
                  CANCEL
                </Button>
              </Grid>
            </div>
          </Grid>
        </form>
      </Modal>
      <ProfileImageCrop
        layout={showCropImageBanner ? "banner" : "profileImage"}
        visible={showCropImage || showCropImageBanner}
        cropImage={cropImage}
        onClose={() => {
          setShowCropImage(false);
          setShowCropImageBanner(false);
        }}
        onCrop={(data) => handleCropImage(data)}
      />
      <ReferFriend
        visible={visibleReferFriend}
        onClose={() => setVisibleReferFriend(false)}
      />
    </>
  );
};

// Default props of the component
UserProfileIcon.defaultProps = {
  showProfile: true,
};

export default UserProfileIcon;
