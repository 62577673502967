import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  commentPostRequest,
  commentEditRequest,
} from "../../../reducers/feeds/feedPost.reducer";
import { ErrorTextHelper } from "../../../components/common";
import EmojiPicker from "emoji-picker-react";
import Emoji from "../../Messages/components/emoji";
import _ from "lodash";
import { Grid, IconButton } from "@material-ui/core";
import { rootReducersState } from "../../../reducers";
import Avatar from "../../../components/common/AvtarImage";
import { Image as ImageIcon, Close as CloseIcon } from '@material-ui/icons';
import Button from "../../../components/common/Button";
import FlashMessage from "../../../components/common/FlashMessage";

type Inputs = {
  comment_text: string;
  comment_media?: FileList;
};

const ReplyPost = ({ data, item, setComment, reason }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();
  const [togglePicker, setTogglePicker] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const emojiRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const commentTextRef = useRef<HTMLTextAreaElement>(null);

  const hideEmojis = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setTogglePicker(false);
    }
  };

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  const currentUserImage = _.get(
    sessionReducer,
    "currentUser.profile_image",
    ""
  );

  useEffect(() => {
    if (reason === "edit-comment") setValue("comment_text", item?.comment_text);
  }, [reason, item?.comment_text, setValue]);

  useEffect(() => {
    document.addEventListener("click", hideEmojis, true);
    return () => {
      document.removeEventListener("click", hideEmojis, true);
    };
  }, []);

  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        FlashMessage("File size must be less than 5 MB.", "error");
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Reset the file input
        }
        return;
      }
      if (commentTextRef.current) {
        commentTextRef.current.value = '';
      }
      setValue("comment_text", "");
      setSelectedMedia(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const removeMedia = () => {
    setSelectedMedia(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // const onSubmit = (formData: any) => {
  //   const submitFormData = new FormData();
  //   submitFormData.append('post_id', data?.id ? data.id : item.post_id);
  //   if (!selectedMedia) {
  //     submitFormData.append('comment_text', formData.comment_text.trim());
  //   }
  //   submitFormData.append('parent_id', reason === "reply-comment" ? _.get(item, "id", "") : '');

  //   if (selectedMedia) {
  //     submitFormData.append('comment_media', selectedMedia);
  //   }

  //   if (reason === "edit-comment") {
  //     submitFormData.append('id', _.get(item, "id", ""));
  //     dispatch(commentEditRequest(submitFormData));
  //     setComment();
  //   } else {
  //     dispatch(commentPostRequest(submitFormData));
  //   }

  //   reset();
  //   removeMedia();
  //   if (commentTextRef.current) {
  //     commentTextRef.current.value = '';
  //   }
  // };

  const onSubmit = (formData: any) => {
    const tempId = Date.now(); // Generate temporary ID
    const submitFormData = new FormData();
    submitFormData.append('post_id', data?.id ? data.id : item.post_id);
    submitFormData.append('temp_id', tempId.toString());
    submitFormData.append('parent_id', reason === "reply-comment" ? _.get(item, "id", "") : '');

    const postId = data?.id || item?.post_id;
    if (!selectedMedia) {
      submitFormData.append('comment_text', formData.comment_text.trim());
    }

    if (selectedMedia) {
      submitFormData.append('comment_media', selectedMedia);
    }

    if (reason === "edit-comment") {
      const editId = _.get(item, "id", "");
      submitFormData.append('id', _.get(item, "id", ""));
      submitFormData.append('comment_type', _.get(item, "comment_type", ""));
      dispatch(commentEditRequest({ id: editId, formData: submitFormData }));
      setComment();
    } else {
      // Add optimistic update
      const optimisticComment = {
        id: tempId,
        post_id: data?.id || item?.post_id,
        comment_text: formData?.comment_text?.trim() || '',
        comment_type: selectedMedia ? 'image' : 'text',
        user: {
          first_name: _.get(sessionReducer, "currentUser.first_name", ""),
          last_name: _.get(sessionReducer, "currentUser.last_name", ""),
          profile_image: _.get(sessionReducer, "currentUser.profile_image", ""),
          id: _.get(sessionReducer, "currentUser.id", ""),
        },
        created_at: new Date().toISOString(),
        isLoading: true,
        parent_id: reason === "reply-comment" ? _.get(item, "id", null) : null,
      };

      dispatch(commentPostRequest({
        formData: submitFormData,
        optimisticComment,
        postId
      }));

    }

    reset();
    removeMedia();
    if (commentTextRef.current) {
      commentTextRef.current.value = '';
    }
  };

  // Removed the enter key submission for images

  // const onSubmit = (formData: any) => {
  //   const tempId = Date.now(); // Generate a temporary ID for UI
  //   const submitFormData = new FormData();
  //   submitFormData.append('post_id', data?.id ? data.id : item.post_id);
  //   if (!selectedMedia) {
  //     submitFormData.append('comment_text', formData.comment_text.trim());
  //   }
  //   submitFormData.append('parent_id', reason === "reply-comment" ? _.get(item, "id", "") : '');

  //   if (selectedMedia) {
  //     submitFormData.append('comment_media', selectedMedia);
  //   }

  //   // Add a loading placeholder comment
  //   dispatch({
  //     type: 'postFeed/commentPostSuccess',
  //     payload: {
  //       data: {
  //         data: {
  //           id: tempId,
  //           post_id: data?.id,
  //           parent_id: reason === "reply-comment" ? _.get(item, "id", "") : '',
  //           comment_text: selectedMedia ? '[Uploading image...]' : formData.comment_text,
  //           comment_type: selectedMedia ? 'image' : 'text',
  //           user: {
  //             first_name: _.get(sessionReducer, "currentUser.first_name", ""),
  //             last_name: _.get(sessionReducer, "currentUser.last_name", ""),
  //             user_id: _.get(sessionReducer, "currentUser.id", ""),
  //             profile_image: _.get(sessionReducer, "currentUser.profile_image", ""),
  //           },
  //           isLoading: true, // Mark as loading
  //         },
  //       },
  //     },
  //   });

  //   dispatch(commentPostRequest(submitFormData));
  //   reset();
  //   removeMedia();
  // };

  const checkKeyDown = (e) => {
    if (selectedMedia) {
      // Prevent any form submission when image is selected
      e.preventDefault();
      return;
    }

    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13 && !e.shiftKey) {
      handleSubmit(onSubmit)();
      e.preventDefault();
    }
  };

  return (
    <div className={`${reason === "main-comment" ? "" : "edit-content"}`}>
      <div className="comments-input">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <div className="comment-inner-wrapper">
                <div className="user-img">
                  {_.isEmpty(currentUserImage) ? (
                    <IconButton
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <Avatar />
                    </IconButton>
                  ) : (
                    <Avatar
                      src={currentUserImage}
                    />
                  )}
                </div>
                <div className="comment-input-wrapper">
                  <div className="comment-input">
                    {!selectedMedia && (
                      <textarea
                        name="comment_text"
                        placeholder={
                          reason === "main-comment"
                            ? "Add a comment..."
                            : "Add a reply..."
                        }
                        onKeyDown={checkKeyDown}
                        ref={(e) => {
                          register(e, {
                            maxLength: {
                              value: 1000,
                              message: "Can not exceed more then 1000 characters",
                            },
                            required: {
                              value: !selectedMedia,
                              message: "Comment can't be empty",
                            },
                          });
                          commentTextRef.current = e;
                        }}
                      />
                    )}
                    {previewUrl && (
                      <div className="comment-media-preview">
                        <div>
                          <span
                            className="media-close"
                            onClick={removeMedia}
                          >
                            <CloseIcon className="close-icon" />
                          </span>
                          <img
                            src={previewUrl}
                            alt="Preview"
                            className="preview-image"
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="submit-emoji-wrapper">
                    {!selectedMedia && (
                      <div className="emoji-icon-wrapper" ref={emojiRef}>
                        <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                        {togglePicker && (
                          <EmojiPicker
                            onEmojiClick={(e) => {
                              setValue(
                                "comment_text",
                                getValues("comment_text") + e.emoji
                              );
                            }}
                          />
                        )}
                      </div>
                    )}
                    {!selectedMedia && (
                      <div className="media-upload-wrapper">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleMediaChange}
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        />
                        <IconButton
                          onClick={() => fileInputRef.current?.click()}
                        >
                          <ImageIcon className="material-icons" />
                        </IconButton>
                      </div>
                    )}
                  </div>

                  {_.get(errors, "add_comment.message") && !selectedMedia && (
                    <ErrorTextHelper
                      text={_.get(errors, "add_comment.message", '')}
                    />
                  )}

                  {selectedMedia && (

                    <div className="gif-comment-wrapper">
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Comment
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default ReplyPost;