// // RedirectPage.tsx
// import React, { useEffect } from "react";

// const RedirectPage: React.FC = () => {
//   useEffect(() => {
//     const redirectBasedOnDevice = (): void => {
//       const userAgent = navigator.userAgent || navigator.vendor || window.opera;

//       // Redirect for Android devices
//       if (/android/i.test(userAgent)) {
//         console.log('android device')
//         window.location.href =
//           "https://play.google.com/store/apps/details?id=com.app.employher";
//         return;
//       }

//       // Redirect for iOS devices
//       if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//         console.log('iphone device')
//         window.location.href =
//           "https://apps.apple.com/in/app/employher/id6478541366";
//         return;
//       }

//       // Fallback (desktop or unknown device)
//       window.location.href = "https://www.employher.com/";
//     };

//     redirectBasedOnDevice();
//   }, []);

//   return (
//     <div style={{ textAlign: "center", marginTop: "50px" }}>
//       <h3>Redirecting, please wait...</h3>
//     </div>
//   );
// };

// export default RedirectPage;


// RedirectPage.tsx
import React, { useEffect } from "react";

const RedirectPage: React.FC = () => {
  useEffect(() => {
    const redirectBasedOnDevice = (): void => {
      // Cast window to any so that TS won't complain
      const opera = (window as any).opera;
      const MSStream = (window as any).MSStream;

      const userAgent = navigator.userAgent || navigator.vendor || opera;

      // Redirect for Android devices
      if (/android/i.test(userAgent)) {
        console.log('android call-----')
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.app.employher";
        return;
      }

      // Redirect for iOS devices
      if (/iPad|iPhone|iPod/.test(userAgent) && !MSStream) {
        console.log('app store call-----')
        window.location.href =
          "https://apps.apple.com/in/app/employher/id6478541366";
        return;
      }

      // Fallback (desktop or unknown device)
      window.location.href = "https://www.employher.com/";
    };

    redirectBasedOnDevice();
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h3>Redirecting, please wait...</h3>
    </div>
  );
};

export default RedirectPage;
