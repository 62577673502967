import React from "react";
import TagInput from "./tagInput";
import { Button, Chip } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { handleDeleteTag } from "../../../reducers/company/companyMarketingSolution.reducer";
import { rootReducersState } from "../../../reducers";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import appRoutes from "../../../routes/app.routes";

const AddTags = () => {
  // const [loading, setLoading] = useState<boolean>(false);
  const tags = useSelector(
    ({ company }: rootReducersState) => company.companyMarketingSolution.tags
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const handleContinue = () => {
    if (!_.isEmpty(tags)) {
      history.push(appRoutes.reviewInfo.generatePath(companySlug));
    }
  };

  return (
    <div className="tags-wrapper">
      <div className="title-wrapper">
        <h3>Add more contact information</h3>
        <Button className="back-btn btn-link" onClick={() => history.goBack()}>
          <ArrowBackIosIcon
            width="10"
            height="18"
            style={{ color: "#FF0990A6" }}
          />{" "}
          Back
        </Button>
      </div>
      <div className="tags-content-wrapper">
        <TagInput />
        <div className="custum-tags-wrapper">
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              variant="outlined"
              onDelete={() => dispatch(handleDeleteTag(tag))}
              deleteIcon={<CloseIcon />}
              className="tag"
            />
          ))}
        </div>
        <div className="continue-btn-wrapper">
          <Button onClick={handleContinue} className="continue-btn btn btn-primary">
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTags;
