import React, { useEffect } from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import AddIcon from "@material-ui/icons/Add";
import { Grid, Button, IconButton, InputLabel } from "@material-ui/core";
import moment from "moment";
import {
  AsyncCreatableSelect,
  Checkbox,
  Input,
} from "../../../components/common";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import RichTextEditor from "../../common/RichTextEditor";
import { stripHTMLTags } from "../../../utils/helper";
import commonServices from "../../../services/common.services";
import _ from "lodash";

let searchDelay: NodeJS.Timeout;

const FormItem = ({
  item,
  index,
  value,
  remove,
  searchJobTitles,
  ...props
}) => {
  const { setError, watch } = props;
  const maxLimit = 1000;
  const experiencesValue = watch(`experiences[${index}].description`, "");

  useEffect(() => {
    const tmpText = stripHTMLTags(experiencesValue);
    if (tmpText.length > maxLimit) {
      // Set an error for the description field of the first experience
      setError(`experiences[${index}].description`, {
        type: "validate",
        message: `Please enter less than ${maxLimit} characters`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiencesValue]);

  const jobTitleFieldName = item?.job_title_custom
    ? `experiences[${index}].job_title_custom`
    : `experiences[${index}].jobTitle`;

  return (
    <div className="career-differentiators-form-item">
      <div className="career-differentiators-row">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <AsyncCreatableSelect
              control={props.control}
              validationObj={props.errors}
              placeholder="Search Job..."
              externalLabel={{
                label: "Job Title",
                className: "required",
              }}
              name={jobTitleFieldName}
              onStartSearching={searchJobTitles}
              noOptionsMessage="No matching job found"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name={`experiences[${index}].company_name`}
              placeholder="Name"
              externalLabel={{ label: "Company Name" }}
              validationObj={props.errors}
              defaultValue={item.company_name}
              required={false}
              inputRef={props.register({
                required: {
                  value: false,
                  message: "Please enter a valid Project",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="date-input">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="date-fields w-100">
                    <Input
                      externalLabel={{ label: "Select Date" }}
                      name={`experiences[${index}].job_started`}
                      placeholder="Work Experience"
                      type="date"
                      defaultValue={item.job_started}
                      validationObj={props.errors}
                      required={false}
                      inputRef={props.register({
                        required: {
                          value: false,
                          message: "Please enter a valid Work Experience",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="date-fields edit-education-present w-100">
                    <Checkbox
                      onChange={(e) =>
                        props._handlePresentCheckBoxChange(e, index)
                      }
                      inputRef={props.register}
                      externalLabel={{ label: "Present" }}
                      checked={value[index]?.is_present}
                      name={`experiences[${index}].is_present`}
                    />
                    {value[index]?.is_present ? (
                      <InputLabel className="present-label">Present</InputLabel>
                    ) : (
                      <Input
                        name={`experiences[${index}].job_ended`}
                        placeholder="early career"
                        type="date"
                        defaultValue={item.job_ended}
                        validationObj={props.errors}
                        required={false}
                        inputProps={{
                          min: props.getValues?.(`experiences[${index}].job_started`) || item.job_started || ""
                        }}
                        inputRef={props.register({
                          required: {
                            value: false,
                            message: "Please enter a valid Date",
                          },
                        })}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="rich-text-editor-job">
              <RichTextEditor
                name={`experiences[${index}].description`}
                placeholder="Job Description"
                externalLabel={{ label: "Job Description" }}
                defaultValue={item.description}
                validationObj={props.errors}
                control={props.control}
                required={false}
                rules={{
                  required: {
                    value: false,
                    message: "Please enter a valid job Description",
                  },
                  validate: {
                    validLength: (value) => {
                      const tmpText = stripHTMLTags(value || "");
                      return (
                        tmpText.length <= maxLimit ||
                        `Please enter less than ${maxLimit} characters`
                      );
                    },
                  },
                }}
                showCount
                maxLimit={maxLimit}
              />
            </div>
            <div className="delete-icon-wrapper">
              <Button
                onClick={() => remove(index)}
                variant="text"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const CareerDifferentiatorsWork: React.FC<any> = (props) => {
  const control: Control<Record<string, any>> = props.control;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences",
  });
  const value = useWatch({
    name: "experiences",
    control,
  });
  const _handlePresentCheckBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const { checked } = e.target;
    if (checked) {
      props.setValue(
        `experiences[${index}].job_ended`,
        moment().format("YYYY-MM-DD")
      );
    } else {
      props.setValue(`experiences[${index}].job_ended`, "");
    }
  };

  const searchJobTitles = (search: string) =>
    new Promise((resolve) => {
      if (search !== "") {
        clearTimeout(searchDelay);
        searchDelay = setTimeout(async () => {
          const jobTitleDetails = await commonServices.fetchJobTitles(search);
          const locations = _.get(jobTitleDetails, "data", []).map((c) => ({
            value: c.id,
            label: c.title,
          }));
          resolve(locations);
        }, 1000);
      } else {
        resolve([]);
      }
    });

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <FormItem
            value={value}
            key={item.id}
            _handlePresentCheckBoxChange={_handlePresentCheckBoxChange}
            item={item}
            index={index}
            remove={remove}
            searchJobTitles={searchJobTitles}
            {...props}
          />
        );
      })}
      <IconButton
        className="add-button"
        onClick={() => {
          append({
            is_current_job: false,
            jobTitle: "",
            job_title_custom: "",
            description: "",
            company_name: "",
            job_location: "",
            job_started: "",
            job_ended: "",
            is_present: true,
          });
        }}
      >
        <AddIcon />
        Add New
      </IconButton>
    </div>
  );
};

export default CareerDifferentiatorsWork;
