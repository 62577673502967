import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Input } from "./../../../../components/common";
import EmailCampaign from "../../../../assets/svg/email-campaign.svg";

const CreateEmail = () => {
  return (
    <div className="email-campaign-wrapper">
      <Grid container alignItems="center">
        <Grid item sm={6}>
          <div className="email-content">
            <h2>
              Create Your <span>Email</span> With Us
            </h2>
            <p>
              Lorem Ipsum simply dummy text printing been typesetting a
              industry. lorem Ipsum has been industry's anstanda dummy text ever
              since.
            </p>
            <div className="form-wrapper">
              <Input name="email" type="email" placeholder="Ura@gmail.com" />
              <Button>Create</Button>
            </div>
          </div>
        </Grid>
        <Grid item sm={6}>
          <div className="email-img">
            <img src={EmailCampaign} alt="email-campaign" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateEmail;
