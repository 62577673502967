import _ from "lodash";
import React from "react";
import ReactPlayer from "react-player";
import ImageIcon from "@material-ui/icons/ImageOutlined";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { Button, Modal } from "../../common";
import EditBasicInformationModal from "./EditBasicInformationModal";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import { useHistory } from "react-router-dom";
//video text
// import { Switch } from "@material-ui/core";
// import httpRequest from "../../../utils/httpRequest";
// import API_URLS from "../../../utils/apiUrls";

interface IProfileHeader {
  candidateProfileData: any;
  showEditButton?: boolean;
  showShareButton?: boolean;
  setSureAboutVideoDelete: () => void;
  publicPage?: boolean;
  videoModal: boolean;
  basicProfile?: boolean;
  setBasicProfile?: any;
  setVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProfileHeader({
  candidateProfileData,
  showEditButton,
  setBasicProfile,
  basicProfile,
  setSureAboutVideoDelete,
  publicPage,
  videoModal,
  setVideoModal,
}: IProfileHeader) {
  const history = useHistory();
  const candidate = useSelector(
    ({ candidate }: rootReducersState) => candidate
  );

  const session = useSelector(({ session }: rootReducersState) => session);
  // const [captions, setCaptions] = useState<any[]>([]);
  // const [showCaptions, setShowCaptions] = useState<boolean>(true);
  // const [currentTime, setCurrentTime] = useState<number>(0);
  // const primaryVideoId = candidateProfileData.primary_video_id

  // const fetchVideoText = async (retryCount = 1) => {
  //   try {
  //     const response = await httpRequest().get(API_URLS.getVideoText(primaryVideoId));
  //     const videoTextData = response.data?.data;

  //     if (videoTextData !== "Processing") {
  //       // Set captions once the text is ready
  //       setCaptions(JSON.parse(videoTextData));
  //     } else {
  //       // If still processing, retry with exponential backoff
  //       const delay = Math.min(20000, 1000 * Math.pow(2, retryCount)); // Cap delay at 30 seconds
  //       setTimeout(() => fetchVideoText(retryCount + 1), delay);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching video text:", error);
  //   }
  // };
  // useEffect(() => {

  //   fetchVideoText()

  // }, [candidateProfileData?.profile?.profile_video, candidate?.candidates?.videoUrl]);


  // const handleProgress = (progress: { playedSeconds: number }) => {
  //   setCurrentTime(progress.playedSeconds);
  // };

  // const formatCaptions = () => {
  //   return captions
  //     .filter(caption => {
  //       const startTime = parseFloat(caption.startTime || "0");
  //       const endTime = parseFloat(caption.endTime || startTime);
  //       return currentTime >= startTime && currentTime <= endTime;
  //     })
  //     .map((caption, index) => (
  //       <div key={index} className="caption">
  //         {caption.text}
  //       </div>
  //     ));
  // };
  return (
    <>
      <div className="profile-view-header mt-12">
        <div
          className={`video-wrapper ${_.isEmpty(
            _.get(candidateProfileData, "profile.personal_values", "")
          )
            ? "video-wrapper-changed"
            : ""
            } ${publicPage
              ? "video-wrapper-public"
              : !showEditButton
                ? "video-wrapper-public-modal"
                : null
            }`}
        >
          {!_.isEmpty(
            _.get(candidateProfileData, "profile.personal_values", "")
          ) && (
              <div className={`about ${publicPage && "mt-30"}`}>
                <p>
                  {_.get(candidateProfileData, "profile.personal_values", "")}
                </p>
              </div>
            )}
        </div>
      </div>
      <Modal
        title="Profile Video"
        visible={videoModal}
        className="profile-modal share-profile-modal editable-modal no-scroll-on-edit-video-modal"
        onClose={() => setVideoModal(false)}
        size="x-large"
      >
        <div className="edit-modal-wrapper">
          <>
            <div className="video-player-wrapper">
              {_.isEmpty(
                _.get(candidateProfileData, "profile.profile_video", "")
              ) && _.isEmpty(_.get(candidate, "candidates.videoUrl", "")) ? (
                <div className="video-placeholder-wrapper">
                  <ImageIcon />
                </div>
              ) : (
                <div className="video-img">
                  <ReactPlayer
                    playing={true}
                    controls
                    playIcon={
                      <div className="play-icon">
                        <PlayArrowOutlinedIcon />
                      </div>
                    }
                    className="video-player-inner-wrapper"
                    url={
                      candidate?.candidates?.videoUrl === ""
                        ? candidateProfileData?.profile?.profile_video
                        : candidate?.candidates?.videoUrl
                    }
                  // onProgress={handleProgress}
                  />
                  {/* {!_.isEmpty(captions) && showCaptions && (
                    <div className="captions-overlay">{formatCaptions()}</div>
                  )} */}
                </div>
              )}
            </div>
            {/* <Switch
              checked={showCaptions}
              onChange={() => setShowCaptions(!showCaptions)}
              inputProps={{ 'aria-label': 'Toggle captions' }}
            /> */}
            {!publicPage && (
              <div className="d-flex justify-content-center m-10">
                <Button
                  color="secondary"
                  className="submit-button text-uppercase mr-10"
                  onClick={setSureAboutVideoDelete}
                >
                  Delete
                </Button>
                <Button
                  color="primary"
                  className="submit-button text-uppercase ml-10"
                  onClick={() => {
                    history.push(
                      `/video-studio/edit/${session?.currentUser?.primary_video_id}`
                    );
                  }}
                >
                  Edit
                </Button>
              </div>
            )}
          </>
        </div>
      </Modal>
      <EditBasicInformationModal
        profile={candidateProfileData}
        basicProfile={basicProfile}
        setBasicProfile={setBasicProfile}
      />
    </>
  );
}

export default ProfileHeader;

