/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import NoResultFound from "./components/noResultFound";
import CandidatesModal from "./components/CandidateProfileModal";
import InvitationModal from "./components/InvitationModal";
import VideoModal from "./components/profileVideoModal";
import CreateSearchAlert from "./components/CreateSearchAlert";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Grid,
  Paper,
  GridList,
  GridListTile,
  MenuItem,
  Select,
  Tooltip,
  Container,
  CircularProgress,
  Menu,
} from "@material-ui/core/";
import { Button, ConfirmDialog, FlashMessage } from "../../components/common";
import _ from "lodash";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {
  searchAllRequest,
  followCompanyRequest,
  updateSearchAllListData,
  sendInvitationRequest,
  resetSendInvitationFlag,
  followCompanyReset,
  clearAllRequest,
} from "../../reducers/SearchAndConnect/searchAndConnect.reducer";
import { useSelector, useDispatch } from "react-redux";
import appRoutes from "../../routes/app.routes";
import moment from "moment";
import useQuery from "../../hooks/useQuery";
import unknownProfile from "../../assets/images/unknownProfile.jpeg";
import { usePrevious } from "../../hooks/index";
import {
  connectionRequestStatus,
  DATE_TIME_FORMAT,
  RESEND_CONNECTION_REQUEST_TIME_LIMIT,
} from "../../utils/appConstants";
import { ReducerFlag } from "../../types/reducer.types";
import { useHistory } from "react-router-dom";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import searchAndConnectServices from "../../services/searchAndConnect.services";
import useSessionUser from "../../hooks/useSessionUser";
import Avatar from "../../components/common/AvtarImage";
import { rootReducersState } from "../../reducers";
import BlockUserModal from "../../components/common/BlockUser";
import { MoreHoriz } from "@material-ui/icons";
const PAGE_SIZE = 21;

export default function Candidates() {
  const dispatch = useDispatch();
  const queryPrams = useQuery();
  const history = useHistory();
  const { IS_COMPANY } = useSessionUser();

  const tmpSearchTerm = queryPrams.get("q");
  const typeSearch = queryPrams.get("type");

  const [page, setPage] = useState(1);
  const [invitationModalStatus, setInvitationOpen] = useState(false);
  const [candidateVideoUrl, setCandidateVideoUrl] = useState("");
  const [searchTerm, setSearchTerm] = useState<any>(tmpSearchTerm);
  const [videoModalStatus, setVideoOpen] = useState(false);
  const [cancelSentRequestShow, setCancelSentRequestShow] = useState(false);
  const [currentUserDetails, setCurrentUserDetails] = useState<any>({});
  const [candidateModalStatus, setCandidateModal] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState<
    Record<string, string | unknown>
  >({});
  const [createSearchAlertModalStatus, setCreateSearchAlertOpen] =
    useState(false);
  const [searchFilter, setSearchFilter] = useState("user");
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const currentUserId = _.get(sessionReducer, "currentUser.id", "");
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [blockId, setBlockId] = useState<number | null>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [blockedUsers, setBlockedUsers] = useState<(string | number)[]>([]); // Explicit type

  const {
    search: {
      searchData,
      loading,
      pagination,
      sendInviteFlag,
      companyFollowFlag,
      companyFollowData,
      sendInviteData,
    },
  } = useSelector(({ search }: any) => search);

  const totalCount = _.get(pagination, "totalCount", 0);

  const searchingType = typeSearch === "company" ? "company" : "user";

  const prevTmpSearchTerm = usePrevious(tmpSearchTerm);

  useEffect(() => {
    return () => {
      // Clear search data
      dispatch(clearAllRequest());
    };
  }, []);

  // Update search when user change any keywords from top handler
  useEffect(() => {
    if (
      typeof prevTmpSearchTerm !== "undefined" &&
      prevTmpSearchTerm !== tmpSearchTerm
    ) {
      setSearchTerm(tmpSearchTerm);
      setPage(1);
    }

    // eslint-disable-next-line
  }, [tmpSearchTerm]);

  // Search only if dependence data changed
  useEffect(() => {
    // dispatch(clearAllRequest());
    dispatch(
      searchAllRequest({
        q: searchTerm,
        type: searchingType,
        page,
        allResult: true,
        pageSize: PAGE_SIZE,
      })
    );
    setIsFetching(false);
    // eslint-disable-next-line
  }, [searchTerm, page, typeSearch]);

  window.onscroll = _.debounce(() => {
    const docBottom =
      document.documentElement.getBoundingClientRect().bottom - 1200;
    const reachedBottom = window.innerHeight > docBottom;
    if (
      reachedBottom &&
      !isFetching &&
      !loading &&
      page < pagination.totalPages
    ) {
      setPage((prevPage) => prevPage + 1);
      setIsFetching(true);
    }
  }, 500);

  useEffect(() => {
    if (searchingType && searchingType !== "all") {
      setSearchFilter(searchingType);
    }

    // eslint-disable-next-line
  }, [searchingType]);

  useEffect(() => {
    if (sendInviteFlag === ReducerFlag.SUCCESS) {
      const userId = currentCandidate.id;

      const updatedRow = searchData.map((row) => {
        if (row.id === userId) {
          return {
            ...row,
            connect: {
              connect_id: sendInviteData.id,
              status: sendInviteData.status,
              reject_reason: null,
              cancelled_at: null,
            },
          };
        } else {
          return row;
        }
      });

      dispatch(updateSearchAllListData(updatedRow));
      setCurrentCandidate({});
      dispatch(resetSendInvitationFlag());
    }

    // eslint-disable-next-line
  }, [sendInviteFlag]);

  // Update company follow and unfollow
  useEffect(() => {
    if (companyFollowFlag === ReducerFlag.SUCCESS) {
      const followID = companyFollowData.follow_id;
      const follow = companyFollowData.follow;

      const updatedRows = searchData.map((row) => {
        if (row?.follow_id === followID) {
          return { ...row, follow: follow };
        } else {
          return row;
        }
      });

      dispatch(updateSearchAllListData(updatedRows));
      dispatch(followCompanyReset());
    }

    // eslint-disable-next-line
  }, [companyFollowFlag]);

  const handleOnInviteSent = ({ note }) => {
    dispatch(
      sendInvitationRequest({ note: note || "", id: currentCandidate.id })
    );
  };

  const candidateShowModal = (row) => {
    setCandidateModal(true);
    setCurrentCandidate(row);
  };

  const handleSearchFilterChange = (event) => {
    let type =
      event.target.value === "company" ? event.target.value : "candidate";
    const searchText = searchTerm || "";
    const url = `${appRoutes.searchAndConnect.path}?q=${searchText}&type=${type}`;
    history.push(url);
    dispatch(clearAllRequest());
    setSearchFilter(event.target.value);
    dispatch(
      searchAllRequest({
        page: 1,
        q: searchText,
        type: event.target.value,
        allResult: true,
        pageSize: PAGE_SIZE,
      })
    );
  };

  const removeConnection = async (data) => {
    try {
      await httpRequest().put(
        API_URLS.removeCandidateConnection(data?.connect?.connect_id || 0)
      );

      // Update the store
      const updatedRow = [...searchData].map((row) => {
        if (row.id === data.id) {
          return {
            ...row,
            connect: null,
          };
        }
        return row;
      });

      dispatch(updateSearchAllListData(updatedRow));
    } catch (error) {
      const errMessage = error.response.data.message || "Something went wrong";
      FlashMessage(errMessage, "error");
    }
  };

  const handleCancelSentRequest = async () => {
    try {
      const connectId = _.get(currentUserDetails, "connect.connect_id");
      const cancelledStatus =
        connectionRequestStatus.find(
          (row) => row.status_text === "cancelled_24_hours"
        )?.status_id || 0;

      await searchAndConnectServices.updateCandidateConnection({
        id: connectId,
        type: "cancelled",
        reject_reason: "",
      });

      // Update the store
      const updatedRow = [...searchData].map((row) => {
        if (row.id === currentUserDetails.id) {
          return {
            ...row,
            connect: {
              connect_id: connectId,
              status: cancelledStatus,
              reject_reason: null,
              cancelled_at: moment().format(DATE_TIME_FORMAT),
            },
          };
        }
        return row;
      });

      dispatch(updateSearchAllListData(updatedRow));
    } catch (error) { }

    setCancelSentRequestShow(false);
  };

  const invitationShowModal = (row) => {
    setCurrentCandidate(row);
    setInvitationOpen(true);
  };

  const invitationModalHandleClose = () => {
    setInvitationOpen(false);
  };

  // const createSearchAlertShowModal = () => {
  // 	setCreateSearchAlertOpen(true);
  // };

  const createSearchAlertModalHandleClose = () => {
    setCreateSearchAlertOpen(false);
  };

  const videoShowModal = (url) => {
    setCandidateVideoUrl(url);
    setVideoOpen(true);
  };

  const videoModalHandleClose = () => {
    setVideoOpen(false);
  };

  const candidateModalHandleClose = () => {
    setCandidateModal(false);
  };

  const handleFollowCompany = (payload) => {
    dispatch(
      followCompanyRequest({
        id: _.get(payload, "follow_id", 0),
        type: _.get(payload, "type", ""),
      })
    );
    // TODO - update the internal store
  };

  const handleBlockToggle = async (userId) => {
    try {
      const response = await httpRequest().delete(API_URLS.unBlockUser(userId));
      if (response.data.message === "Success") {

        setBlockedUsers((prevState) =>
          prevState.filter((user) => user !== userId)
        );
      }
    }
    catch (error) {
      FlashMessage("Unable to process request", "error");
    }
  };

  const renderUserCardActionButton = (data: object) => {
    const connectRequestStatusId = _.get(data, "connect.status", null) || 0;
    const connectRequestRejectReason =
      _.get(data, "connect.reject_reason", null) || "";
    const connectRequestStatus = connectionRequestStatus.find(
      (row) => row.status_id === connectRequestStatusId
    ); const connectRequestStatusText = _.get(
      connectRequestStatus,
      "status_text",
      ""
    );

    switch (connectRequestStatusText) {
      case "accepted":
        return (
          <Button
            className="btn-primary btn profile-card-connect-button-disabled"
            onClick={() => removeConnection(data)}
          >
            Remove
          </Button>
        );
      case "rejected":
        return (
          <Tooltip title={connectRequestRejectReason} placement="top">
            <Button
              disabled
              className="btn-primary btn profile-card-connect-button-disabled"
            >
              Rejected
            </Button>
          </Tooltip>
        );
      case "pending":
        return (
          <Button
            color="secondary"
            onClick={() => {
              setCurrentUserDetails(data);
              setCancelSentRequestShow(true);
            }}
          >
            {(currentUserId && _.get(data, "request.connect_id")) ? (
              "Cancel Request"
            ) : (
              "Withdraw"
            )}


          </Button>
        );
      case "cancelled_24_hours":
        return (
          <Button color="light-green" disabled style={{ fontSize: "15px" }}>
            Wait {RESEND_CONNECTION_REQUEST_TIME_LIMIT} hours
          </Button>
        );
      default:
        return (
          <Button
            onClick={() => invitationShowModal(data)}
            className="btn-primary btn profile-card-connect-button"
          >
            Connect
          </Button>
        );
    }
  };
  const handleOpenModal = (id: number) => {
    setBlockId(id);
    setShowBlockModal(true);
  };

  const handleMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row); // Save the selected row
  };

  const userCard = (row) => {
    return (
      <Grid
        item
        className="search-result-profle-card-container search-result-profile-card-wrap"
        sm={6}
        lg={4}
      >
        <Paper className="search-result-profle-card">
          <Grid>
            <Grid item xs={12} className="profle-card-top">
              <div className="profle-card-top-img">
                <div className="profile-picture">
                  <div className="profile-img">
                    <Avatar
                      src={_.get(row, "profile_image", unknownProfile)}
                      size="md"
                      onClick={() => {
                        candidateShowModal(row);
                      }}
                    />
                  </div>
                  {_.get(row, "candidateProfile.profile_video", "") && (
                    <>
                      <div className="video-play-btn">
                        <PlayArrowIcon
                          onClick={() =>
                            videoShowModal(
                              _.get(row, "candidateProfile.profile_video", "")
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="profle-card-top-info ">
                <h3
                  className="name-label cursor-pointer"
                  onClick={() => {
                    candidateShowModal(row);
                  }}
                >
                  {`${_.get(row, "first_name", "")} ${_.get(
                    row,
                    "last_name",
                    ""
                  )}`}
                </h3>
                <p className="job-type">
                  {_.get(row, "candidateProfile.job_title_custom", "") === "" ||
                    _.get(row, "candidateProfile.job_title_custom", "") === null
                    ? _.get(row, "candidateProfile.jobTitle.title", "")
                    : _.get(row, "candidateProfile.job_title_custom", "")}
                </p>
              </div>
              <span className="block-icon-container">
                <MoreHoriz onClick={(e) => handleMenu(e, row)} />


              </span>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                className="block-menu-option-wrapper"

              >
                {/* Block Option */}
                <div className="block-box-wrapper">
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      if (blockedUsers.includes(currentRow?.id)) {
                        handleBlockToggle(currentRow?.id); // Unblock
                      } else {
                        setBlockId(row.id);
                        handleOpenModal(currentRow?.id); // Open block modal
                      }
                    }}
                  >
                    {blockedUsers.includes(currentRow?.id) ? "Unblock" : "Block"}
                  </MenuItem>
                </div>
              </Menu>


            </Grid>
            <Grid item xs={12} className="profle-card-middle">
              <p>{_.get(row, "candidateProfile.personal_values", "")}</p>
            </Grid>
            <Grid item xs={12} className="profle-card-bottom">
              <Button
                color="secondary"
                onClick={() => {
                  candidateShowModal(row);
                }}
              >
                Quick View
              </Button>
              {renderUserCardActionButton(row)}
            </Grid>
          </Grid>
        </Paper >
      </Grid >
    );
  };

  const viewProfile = (slug: string) => {
    const win = window.open(
      appRoutes.CompanyPublicView.generatePath(slug),
      "_blank"
    );
    win.focus();
  };

  const companyCard = (row) => {
    return (
      <Grid item className="search-result-profle-card-container" sm={6} lg={4}>
        <Paper className="search-result-profle-card">
          <Grid>
            <Grid item xs={12} className="profle-card-top">
              <div className="profle-card-top-img">
                <div className="profile-picture">
                  <div
                    className="profile-img"
                    onClick={() => viewProfile(_.get(row, "slug", ""))}
                  >
                    <Avatar
                      src={_.get(row, "company_logo", null)}
                      size="md"
                      type="company"
                    />
                  </div>
                </div>
              </div>
              <div className="profle-card-top-info">
                <h3
                  className="cursor-pointer"
                  onClick={() => viewProfile(_.get(row, "slug", ""))}
                >
                  {_.get(row, "company_name", "")}
                </h3>
                <p className="job-type">
                  {!_.isEmpty(_.get(row, "industry", ""))
                    ? `${_.get(row, "industry", "")} |`
                    : ""}{" "}
                  {_.get(row, "employeeSize", "")}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} className="profle-card-middle">
              <p>{_.get(row, "who_we_are", "")}</p>
            </Grid>
            <Grid item xs={12} className="profle-card-bottom">
              <Button
                color="secondary"
                onClick={() => viewProfile(_.get(row, "slug", ""))}
              >
                View Profile
              </Button>
              <Button
                className="btn-primary btn"
                onClick={() =>
                  handleFollowCompany({
                    ...row,
                    type: row?.follow ? "unfollow" : "follow",
                  })
                }
              >
                {row?.follow ? "Unfollow" : "Follow"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const getResultCard = (row) => {
    return (
      <>
        {searchingType === "user" ? userCard(row) : null}
        {searchingType === "company" ? companyCard(row) : null}
      </>
    );
  };

  return (
    <div>
      <Container
        maxWidth="lg"
        className="p-0 sub-container search-main-container"
      >
        <Grid container className="search-result-container">
          {!IS_COMPANY && (
            <Grid className="search-result-filters" item sm={12} md={12}>
              <Select
                className="search-result-filters-button"
                value={searchFilter}
                onChange={handleSearchFilterChange}
              >
                <MenuItem value={"user"}>Profiles</MenuItem>
                <MenuItem value={"company"}>Companies</MenuItem>
              </Select>
            </Grid>
          )}
          <span className="search-result-countText text-center">
            {!loading && totalCount > 0 && (
              <>About {totalCount || ""} Results</>
            )}
          </span>
        </Grid>
        {loading && totalCount === 0 ? (
          <GridList cellHeight="auto" cols={3} spacing={50}>
            {["20%", "20%", "20%"].map((per, i) => {
              return (
                <GridListTile cols={0}>
                  <Skeleton height={520} />
                </GridListTile>
              );
            })}
          </GridList>
        ) : _.isEmpty(searchData) ? (
          <NoResultFound />
        ) : (
          <>
            {/* Search result user cards */}
            <Grid container spacing={3} className="search-result-grid">
              {(searchData || []).map((row) => {
                return getResultCard(row);
              })}
            </Grid>
          </>
        )}
        {loading && totalCount > 0 && (
          <Grid container className="search-result-pagination">
            <CircularProgress color="secondary" size={15} />
          </Grid>
        )}
      </Container>
      <CandidatesModal
        status={candidateModalStatus}
        payload={currentCandidate}
        handleOnClose={candidateModalHandleClose}
      />
      <InvitationModal
        open={invitationModalStatus}
        payload={currentCandidate}
        onInviteSent={handleOnInviteSent}
        handleOnClose={invitationModalHandleClose}
      />
      <CreateSearchAlert
        status={createSearchAlertModalStatus}
        handleOnClose={createSearchAlertModalHandleClose}
      />
      <VideoModal
        status={videoModalStatus}
        candidateVideoUrl={candidateVideoUrl}
        handleOnClose={videoModalHandleClose}
      />

      <ConfirmDialog
        visible={cancelSentRequestShow}
        title={`Cancel sent request`}
        onCancel={() => setCancelSentRequestShow(false)}
        bodyText={`Request to cancel will not allow to reconnect with this person for up to ${RESEND_CONNECTION_REQUEST_TIME_LIMIT} hours.`}
        onConfirm={() => handleCancelSentRequest()}
        confirmText="Okay"
      />

      {blockId && (
        <BlockUserModal
          show={showBlockModal}
          onClose={() => {
            setShowBlockModal(false);
            setBlockId(null);
          }}
          blockedId={blockId}
          onBlockComplete={(userId) => {
            setBlockedUsers((prevState) => {
              // Ensure userId is added only once to avoid duplicates
              if (!prevState.includes(userId)) {
                return [...prevState, userId];
              }
              return prevState; // Return the same array if userId already exists
            });
          }}
        />
      )}
    </div>
  );
}
