import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button } from "../../../components/common";
import Avatar from "../../../components/common/AvtarImage";
import Skeleton from "@material-ui/lab/Skeleton";
import NoNotificationFound from "../../../components/Candidates/noNotificationFound";
import httpRequest from "../../../utils/httpRequest";
import API_URLS from "../../../utils/apiUrls";
import { convertUTCToLocal } from "../../../utils/helper";

const BlockUserList = () => {
  const [userList, setUserList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);


  const fetchBlockListing = async () => {
    setIsFetching(true);
    try {
      const response = await httpRequest().get(API_URLS.blockUserList);
      const listData = response?.data?.data || [];
      setUserList(listData);
    } catch (error) {
      console.error("Error fetching block user list:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchBlockListing();
  }, []);

  const unblockUser = async (id) => {
    try {
      const response = await httpRequest().delete(API_URLS.unBlockUser(id));
      if (response.data.message === "Success") {
        fetchBlockListing();
      }
    } catch (error) {
      console.error("Error fetching block user list:", error);
    }
  }

  const renderSkeletons = () =>
    [1, 2, 3].map((index) => (
      <div className="connection-item-wrapper" key={index}>
        <div className="avatar">
          <Skeleton variant="circle" width={50} height={50} />
        </div>
        <div className="details">
          <Skeleton variant="text" width={300} />
        </div>
        <Skeleton variant="text" width={100} height={40} />
      </div>
    ));

  const renderUserList = () =>
    userList.map((blockData, index) => {

      return (
        <div className="connection-item-wrapper" key={index}>
          <div className="avatar">
            <Avatar src={_.get(blockData, "blockedProfileImageUrl", "")} />
          </div>
          <div>
            <div className="details">
              <div className="block-title-info">
                <h2>{`${_.get(blockData, "blockedFirstName", "")} ${_.get(
                  blockData,
                  "blockedLastName",
                  ""
                )}`}</h2>
                <span>{convertUTCToLocal(blockData.created_at)} ago</span>
              </div>
              <div>
                {`${_.get(blockData, "reason", "")}`}
              </div>
              <div className="actions">
                <Button
                  color="secondary"
                  className="text-uppercase"
                  onClick={() => unblockUser(blockData.blockedId)}
                >
                  UNBLOCK
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <div className="content">
      {isFetching ? (
        renderSkeletons()
      ) : _.isEmpty(userList) ? (
        <NoNotificationFound label="connections" />
      ) : (
        renderUserList()
      )}
    </div>
  );
};

export default BlockUserList;
