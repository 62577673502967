import React, { useState, useEffect } from "react";
import "quill-mention";
import {
  SingleFileDropZone,
  Button,
  Modal,
  Input
} from "../../components/common";
import _ from 'lodash';
import Image from "../../components/common/Image";
// import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-icon.svg";
import { readFile } from "../../utils/cropImageHelper";
import ProfileImageCrop from "../../components/common/ProfileImageCrop";
import RichTextEditor from "../../components/common/RichTextEditor";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import FlashMessage from "../../components/common/FlashMessage";
import { useHistory, useParams } from "react-router-dom";
import appRoutes from "../../routes/app.routes";
import { Delete } from "@material-ui/icons";

interface ArticleType {
  seo_title: string;
  seo_description: string;
  title: string;
  description: string;
}

function Article() {

  const [showCropImage, setShowCropImage] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cropPreviewImage, setPreviewImage] = useState("");
  const [uploadImage, setUploadImage] = useState<Blob>();
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [articleData, setArticleData] = useState<ArticleType>({} as ArticleType);
  const params = useParams();
  const articleId = _.get(params, "id", "");
  const flag = articleId ? "update" : "create";
  const history = useHistory();

  useEffect(() => {
    if (articleId) {
      fetchArticle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileUploadSuccess = async (file) => {
    let imageFile: any = await readFile(file)
    setShowCropImage(true);
    setCropImage(imageFile);
  };
  const removeMedia = () => {
    setCropImage(null);
    setPreviewImage("");
    setUploadImage(undefined);
  };
  const handleCropImage = async (blobData) => {
    setUploadImage(blobData);
    const src = URL.createObjectURL(blobData);
    setPreviewImage(src);
  };

  const _handleCloseSettingsModel = () => {
    setShowSettingsModal(false);
  };

  const validateArticle = async () => {
    const title = articleData.title;
    const description = articleData.description;
    if (!title || title.length > 250) {
      FlashMessage("please enter headLine", "error");
      return false;
    }
    if (!description) {
      FlashMessage("Please enter description", "error");
      return false;
    }
    return true
  }
  const saveArticle = async () => {
    const isValid = await validateArticle(); // Await the result of validateArticle
    if (!isValid) {
      return; // Exit function if validation fails
    }
    try {
      const payload = new FormData();
      const title = articleData.title || "";
      const description = articleData.description || "";
      const seo_title = articleData.seo_title || "";
      const seo_description = articleData.seo_description || "";

      payload.append("title", title);
      payload.append("description", description);
      payload.append("seo_title", seo_title);
      payload.append("seo_description", seo_description);
      if (uploadImage) {
        payload.append("cover_image", uploadImage || "");
      }
      /*jslint eqeq: true*/
      if (flag === "create") {
        const data = await httpRequest().post<{
          data: ArticleType;
        }>(API_URLS.article, payload);
        if (data.status === 200) {
          history.push(appRoutes.Feed.path);
        }
      } else {
        const updateData = await httpRequest().put(API_URLS.articleUpdate(articleId), payload);

        const data = {
          title: updateData.data.data.title,
          description: updateData.data.data.content || "",
          seo_title: updateData.data.data.seo_title || "",
          seo_description: updateData.data.data.seo_description || "",
        }
        const coverImage = updateData.data.data.cover_image || ''

        if (coverImage) {
          setPreviewImage(coverImage);
        }
        setArticleData(data)
        if (updateData.status === 200) {
          history.push(appRoutes.Feed.path);
        }
      }


    } catch (error) {
      const firstErrorKey = Object.keys(error.response.data.errors || {})[0]; // Get the first error key
      const firstErrorMessage = error.response.data.errors[firstErrorKey]; // Get the first error message
      if (firstErrorMessage) {
        FlashMessage(firstErrorMessage, "error"); // Display the first error message
      } else {
        FlashMessage(error?.message || "An error occurred", "error");
      }

    }
  };
  const fetchArticle = async () => {

    try {
      const oldData = await httpRequest().get(API_URLS.fetchArticle(articleId));
      const data = {
        title: oldData.data.data.title,
        description: oldData.data.data.content || "",
        seo_title: oldData.data.data.seo_title || "",
        seo_description: oldData.data.data.seo_description || "",
      }
      const image = oldData.data.data.cover_image || "";
      setPreviewImage(image);
      setArticleData(data);

    } catch (error) {
      console.log("error", error);
    }
  }
const saveSetting=()=>{
  setShowSettingsModal(false);
FlashMessage("settings updated successfully", "success");
}
  return (
    <>
      <div className="article-main-container">
        <div className="article-container">
          <div className="menu-item">
            <Button color="secondary"
              onClick={() => setShowSettingsModal(true)}>Setting
            </Button>
          </div>
          <div className="article-wrapper">
            <div className="form-group article-title">
              <textarea className="form-control "
                id="title"
                maxLength={150}
                placeholder="Headline"
                rows={1} cols={30}
                value={articleData?.title || ''}
                onChange={(e) => {
                  const data = e?.currentTarget?.value || "";
                  setArticleData((prevState) => ({
                    ...prevState,
                    title: data
                  }))
                }}
              >
              </textarea>
            </div>
            <div className="article-image">
              <div className="article-input-type-file">
                {cropPreviewImage ?

                  <div className="article-src" >
                    <Image src={cropPreviewImage} alt="media image" />{" "}
                    <span
                      className="article-close"
                      onClick={() => removeMedia()}
                    >
                      <Delete className="delete-icon" />
                    </span>
                  </div>
                  :
                  <SingleFileDropZone
                    allowFileTypes={["png", "jpg", "jpeg"]}
                    allowFileSize={1048576}
                    // content={
                    //   <div className="flex-column gap-2">
                    //     <p>Add a cover image that complements your article to attract more readers.</p>
                    //     <p>We recommend uploading an image that is 1920x1080 pixels.</p>
                    //   </div>
                    // }
                    onSuccess={(file) => fileUploadSuccess(file)}
                  />
                }
              </div>
            </div>
            <RichTextEditor
              name="description"
              value={articleData?.description || ''}
              onChange={(value) => {
                // setArticleData(e)
                const data = value || "";
                setArticleData((prevState) => ({
                  ...prevState,
                  description: data
                }))
              }}

            >
            </RichTextEditor>
            <div className='btn-wrapper'>
              <Button onClick={saveArticle}>Save</Button>
            </div>
          </div>
        </div>
      </div>

      <ProfileImageCrop
        layout={'banner'}
        visible={showCropImage}
        cropImage={cropImage}
        onClose={() => setShowCropImage(false)}
        onCrop={(data) => handleCropImage(data)}
      />
      <Modal
        className="article-settings"
        visible={showSettingsModal}
        title="SEO Settings"
        size="width-1000"
        onClose={() => _handleCloseSettingsModel()}
      >
        <div className="sub-description-main">
          <h6>SEO title</h6>
          <p className="sub-description">We'll replace your article title with the SEO title you provided for better visibility on search engine result pages, like Google search.
          </p>
          <div className="form-group">
            <Input
              name="seo_title"
              className="article"
              type="text"
              externalLabel={{ label: "Title" }}
              placeholder="Ex:My Article"
              value={articleData?.seo_title || ''}
              onChange={(e) => {
                const data = e?.currentTarget?.value || "";
                setArticleData((prevState) => ({
                  ...prevState,
                  seo_title: data
                }))
              }}
            />
          </div>
          <h6>Description</h6>
          <p>
            We will replace the first few lines of your article on search engine result pages with the SEO description you provide. It is recommended to use keywords, summarize your content, and keep it within 140-160 characters.
          </p>
          <div className="form-group">
            <Input
              name="seo_description"
              className="article"
              type="text"
              externalLabel={{ label: "Description" }}
              placeholder="Ex:A recap about what the article is about"
              value={articleData?.seo_description || ''}
              onChange={(e) => {
                const data = e?.currentTarget?.value || "";
                setArticleData((prevState) => ({
                  ...prevState,
                  seo_description: data
                }))
              }}
            />
          </div>
          <div className="article-button">
            <Button color="secondary" onClick={() => _handleCloseSettingsModel()}>Cancel</Button>
            <Button
              onClick={saveSetting}>Save</Button>
          </div>
        </div>
      </Modal>

    </>
  );
}

export default Article;
