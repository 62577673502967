import httpRequest from "../utils/httpRequest";
import { get, omit } from "lodash";
import API_URLS from "../utils/apiUrls";
import { objectToParams } from "../utils/helper";

const _ = { get, omit };

const addSubscriber = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.addSubscriber}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const addNewTag = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.addNewTag}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const addBulkContacts = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.bulkContacts}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const getListTag = () => {
  return httpRequest()
    .get(`${API_URLS.getListTag}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const parseContactsFile = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.parseContactsFile}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const saveContacts = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.saveContacts}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
        numberOfFailedContacts: _.get(res, "data.numberOfFailedContacts", 0),
				numberOfSavedContacts: _.get(res, "data.numberOfSavedContacts", 0),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const fetchContacts = (payload: any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.fetchContacts}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const removeContact = async (contact_id: string) => {
  try {
    const res = await httpRequest().delete(`${API_URLS.removeContact(contact_id)}`);
    return {
      flag: true,
      message: _.get(res.data, "message", "Contact removed successfully"),
      data: _.get(res.data, "data", {}),
    };
  } catch (err) {
    return {
      flag: false,
      message: _.get(err, "response.data.message", "Something went wrong while removing the contact"),
      errors: _.get(err, "response.data.errors", {}),
    };
  }
};


const saveEmailTemplate = (payload: any) => {
  return httpRequest()
    .post(`${API_URLS.saveEmailTemplate}`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const fetchEmailTemplates = () => {
  return httpRequest()
    .get(`${API_URLS.fetchEmailTemplates}`)
    .then((res) => {
      return {
        flag: true,
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};


const createCampaign = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.createCampaign}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const getCampaignById = (template_id: string) => {
  return httpRequest()
    .get(`${API_URLS.getCampaignById(template_id)}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const getCampaignList = (payload:any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.getCampaignList}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", []),
        pagination: _.get(res, "data.pagination", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};



const sendCampaign = (formData: any) => {
  console.log({payload: formData})
  return httpRequest()
    .post(`${API_URLS.sendCampaign}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const testEmailCampaign = (formData: any) => {
  console.log({payload: formData})
  return httpRequest()
    .post(`${API_URLS.testEmailCampaign}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};



const marketingSolutionServices = {
  addSubscriber, 
  addNewTag,
  addBulkContacts,
  getListTag,
  parseContactsFile,
  saveContacts, 
  fetchContacts,
  removeContact,
  saveEmailTemplate,
  fetchEmailTemplates,
  createCampaign,
  getCampaignById,
  getCampaignList,
  sendCampaign,
  testEmailCampaign
};

export default marketingSolutionServices;
