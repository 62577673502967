import React from "react";
import { Grid, Button, IconButton, InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import { Checkbox, Input } from "../../../components/common";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";

const FormItem = ({ item, value, remove, index, ...props }) => (

  <div className="career-differentiators-form-item education-form-item">
    <div className="career-differentiators-row education-form-row">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div>
            <Input
              name={`education[${index}].institute_name`}
              placeholder="Name"
              externalLabel={{ label: "Institution Name" }}
              defaultValue={item.institute_name}
              validationObj={props.errors}
              required={false}
              inputRef={props.register({
                required: {
                  value: false,
                  message: "Please enter a valid Institution Name",
                },
              })}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="date-input">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div>
                  <Input
                    externalLabel={{ label: "Select Date" }}
                    name={`education[${index}].degree_from`}
                    placeholder="Start Date"
                    type="date"
                    defaultValue={item.degree_from}
                    validationObj={props.errors}
                    required={false}
                    inputRef={props.register({
                      required: {
                        value: false,
                        message: "Please enter a valid Date",
                      },
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="edit-education-present">
                  <Checkbox
                    onChange={(e) =>
                      props._handlePresentCheckBoxChange(e, index)
                    }
                    inputRef={props.register}
                    externalLabel={{ label: "Present" }}
                    checked={value[index]?.is_present}
                    name={`education[${index}].is_present`}
                  />
                  {value[index]?.is_present ? (
                    <InputLabel className="present-label">Present</InputLabel>
                  ) : (
                    <Input
                      name={`education[${index}].degree_to`}
                      defaultValue={item.degree_to}
                      placeholder="End date"
                      type="date"
                      validationObj={props.errors}
                      required={false}
                      inputProps={{
                        min: props.getValues?.(`education[${index}].degree_from`) || item.degree_from || ""
                      }}
                      inputRef={props.register({
                        required: {
                          value: false,
                          message: "Please enter a valid Date",
                        },
                      })}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>

    <div className="career-differentiators-row education-form-row">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div>
            <Input
              name={`education[${index}].field_of_study`}
              placeholder="Field of study"
              externalLabel={{ label: "Field of Study" }}
              validationObj={props.errors}
              defaultValue={item.field_of_study}
              required={false}
              inputRef={props.register({
                required: {
                  value: false,
                  message: "Please enter a valid field of study",
                },
              })}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Input
              name={`education[${index}].degree_title`}
              placeholder="Degree"
              externalLabel={{ label: "Degree" }}
              validationObj={props.errors}
              defaultValue={item.degree_title}
              required={false}
              inputRef={props.register({
                required: {
                  value: false,
                  message: "Please enter a valid degree",
                },
              })}
            />
          </div>
          <div className="delete-icon-wrapper">
            <Button
              onClick={() => remove(index)}
              variant="text"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);
function _Educations(props) {
  const control: Control<Record<string, any>> = props.control;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "education",
  });

  const value = useWatch({
    name: "education",
    control,
  });

  const _handlePresentCheckBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const { checked } = e.target;
    if (checked) {
      props.setValue(
        `education[${index}].degree_to`,
        moment().format("YYYY-MM-DD")
      );
    } else {
      props.setValue(`education[${index}].degree_to`, "");
    }
  };

  return (
    <div className="info-wrapper career-differentiators-wrapper education-form-wrapper">
      {fields.map((item, index) => {
        return (
          <FormItem
            value={value}
            key={item.id}
            item={item}
            _handlePresentCheckBoxChange={_handlePresentCheckBoxChange}
            index={index}
            remove={remove}
            {...props}
          />
        );
      })}
      <IconButton
        className="add-button"
        onClick={() => {
          append({
            degree_title: "",
            institute_name: "",
            degree_from: "",
            degree_to: "",
            field_of_study: "",
            is_present: false,
          });
        }}
      >
        <AddIcon />
        Add New
      </IconButton>
    </div>
  );
}

export default _Educations;
