import React from "react";
import { Button } from "../../../components/common";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";

const ThankyouFeedback = () => {
  const history = useHistory();
  return (
    <div className="contact-thankyou-message text-center">
      <div>
        <CheckCircleOutlineOutlinedIcon className="circle-success" />
        <h3>Thank you</h3>
        <p>We’ll use your feedback to improve the experience.</p>
        <Button
          color="primary"
          onClick={() => history.push(appRoutes.contactUs.path)}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default ThankyouFeedback;
