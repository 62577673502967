/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FormHelperText, InputAdornment } from "@material-ui/core";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  AsyncCreatableSelect,
  Button,
  EditableText,
  Input,
  Radio,
} from "../../../components/common";
import { rootReducersState } from "../../../reducers";
import {
  userSignUpRequest,
  userSignUpReset,
  companySignUpRequest,
} from "../../../reducers/auth/signUp.reducer";
import appRoutes from "../../../routes/app.routes";
import SelectNew from "../../../components/common/SelectNew";
import useQuery from "../../../hooks/useQuery";
import { ReactComponent as PersonIcon } from "../../../assets/svg/person.svg";
import { ReactComponent as EnvelopeIcon } from "../../../assets/svg/envelope.svg";
import { ReactComponent as LockIcon } from "../../../assets/svg/lock.svg";
import Affirmation from "../../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CUSTOM_GENDER_OPTIONS } from "../../../utils/appConstants";
import companyServices from "../../../services/company.services";
import FlashMessage from "../../../components/common/FlashMessage";
import httpRequest from "../../../utils/httpRequest";
import API_URLS from "../../../utils/apiUrls";
// import AutoModeIcon from '@material-ui/icons/Loop';

type Inputs = {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  employee_id: string;
  verify_email: string;
  password: string;
  confirm_password: string;
  gender_optional: string;
  termsAndConditions: boolean;
};
let searchDelay: any = null;

const SignUp: React.FC = () => {
  // Hooks
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const userSignUpReducer = useSelector(
    ({ auth }: rootReducersState) => auth.signUp
  );
  // const location = useLocation();
  // const path = location.pathname
  const [genderVal, setGenderVal] = useState(null);
  const [disabledReferEmail, setDisabledReferEmail] = useState<boolean>(false);
  const loading = _.get(userSignUpReducer, "loading", false);
  const emailToken = _.get(userSignUpReducer, "token", "");
  const signUpFlag = _.get(userSignUpReducer, "flag");
  const signUpErrors = _.get(userSignUpReducer, "errors", {});
  const signUpErrorMessage = _.get(userSignUpReducer, "message");
  const [signUpState, setSignUpState] = useState("employer");
  //captcha state
  // const [captchaImage, setCaptchaImage] = useState("");
  // const [captchaAnswer, setCaptchaAnswer] = useState();

  const { register, handleSubmit, errors, watch, setError, reset, control } =
    useForm<Inputs>();

  // Watch the field for verify process
  const password = useRef({});
  password.current = watch("password", "");
  const currentEmail = useRef({});
  currentEmail.current = watch("email", "");
  const referId = query.get("refer") || null;
  const phone = watch("contact_info");
  // Mount component
  useEffect(() => {
    if (referId) {
      fetchReferEmail();
    }
  }, []);

  // useEffect(() => {
  //   if (path === '/sign-up') {
  //     createCaptcha();
  //   }
  // }, [path])

  const fetchReferEmail = async () => {
    try {
      const { data } = await httpRequest().post<{
        data: string;
      }>(API_URLS.fetchReferFriendEmail, { referId });

      const referEmail = data?.data || "";
      if (referEmail) {
        setDisabledReferEmail(true);
        reset({
          email: referEmail,
          verify_email: referEmail,
        });
      }
    } catch (error) {
      //
    }
  };

  // Sign up success Hook
  useEffect(() => {
    if (signUpFlag === true) {
      reset();
      dispatch(userSignUpReset());
      history.push({
        pathname: appRoutes.userVerify.generatePath(emailToken),
        state: { currentEmail, phone }, // Pass email & phone securely
      });
      // history.push(appRoutes.userVerify.generatePath(emailToken));
    } else if (signUpFlag === false) {
      dispatch(userSignUpReset());
      _.forEach(signUpErrors, (value, key: any) => {
        setError(key, { type: "manual", message: value });
      });

      if (signUpErrorMessage) {
        FlashMessage(signUpErrorMessage, "error");
      }
    }
  }, [signUpFlag]);

  const onSubmit = async (formData) => {
    // const captcha = formData.captcha_answer
    // if (captcha === captchaAnswer) {

    let payload = {
      first_name: formData.first_name || "",
      last_name: formData.last_name || "",
      email: currentEmail.current || "",
      password: formData.password || "",
      confirm_password: formData.confirm_password || "",
      gender: genderVal,
      // token: captcha,
      action: "sign_up",
      refer: referId,
      contact_info:formData.contact_info||''
    };

    if (genderVal === "custom") {
      payload["gender_pronoun"] = _.get(formData, "gender_pronoun.value", 0);
      payload["gender_optional"] = formData.gender_optional || "";
    }
    if (signUpState === "company") {
      delete payload["gender"];
      payload["employee_id"] = formData.employee_id;
      payload["company_name"] = formData.company_name.value;

      dispatch(companySignUpRequest(payload));
    } else {
      dispatch(userSignUpRequest(payload));
    }

    // } else {
    //   FlashMessage("Please Enter Valid Captcha", "error")
    // }
  };

  const updateSignUpState = (type: "company" | "employer"): void => {
    setSignUpState(type);
  };

  const searchCompanies = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const companiesData = await companyServices.searchCompanyByName(search);

        const companies = _.get(companiesData, "data", []).map((c: any) => ({
          value: c?.id,
          label: c?.company_name,
        }));

        resolve(companies);
      }, 1000);
    });

  // const createCaptcha = async () => {
  //   const data = await httpRequest().post(API_URLS.captcha);
  //   const svgImage = data.data.data.data
  //   const responseCaptchaAnswer = data.data.data.text
  //   setCaptchaAnswer(responseCaptchaAnswer)
  //   setCaptchaImage(svgImage)
  // }

  return (
    <>
      <div className="left-part">
        <Affirmation />
      </div>
      <div className="right-part">
        <div className="">
          <div className="back-website">
            <div className="back">
              <Link to={appRoutes.home.path}>
                {" "}
                <ArrowBackIosIcon />{" "}
              </Link>
            </div>
            <Link to={appRoutes.home.path}>Back to website</Link>
          </div>
          <div className="auth-card auth-signup-wrapper">
            <h2 className="auth-card-title">Create an account</h2>

            <p className="outer-link sign-up-outer-link">
              {signUpState === "employer" ? (
                <div>
                  Are you an Employer?{" "}
                  <span
                    className="span-link"
                    onClick={() => updateSignUpState("company")}
                  >
                    Click Here
                  </span>
                </div>
              ) : (
                <div>
                  Are you a Candidate?{" "}
                  <span
                    className="span-link"
                    onClick={() => updateSignUpState("employer")}
                  >
                    Click Here
                  </span>
                </div>
              )}
            </p>

            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <p className="form-group-label">Full Name</p>
                <div className="inline-inputs">
                  <Input
                    name="first_name"
                    placeholder="First Name"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter first name",
                      },
                    })}
                  />
                  <Input
                    name="last_name"
                    placeholder="Last Name"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter last name",
                      },
                    })}
                  />
                </div>
              </div>
         
              
              <div className="phone-email-wrapper">
                <div className="form-group">
                  <p className="form-group-label">Email Address</p>
                  <div className="inline-inputs email-input">
                    <Input
                      name="email"
                      disabled={disabledReferEmail}
                      placeholder="Email"
                      validationObj={errors}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EnvelopeIcon />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter email address",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Enter valid email address",
                        },
                      })}
                    />

                    {/* <Input
                    name="verify_email"
                    disabled={disabledReferEmail}
                    placeholder="Re-enter Email"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EnvelopeIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter verify email address",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Enter valid verify email address",
                      },
                      validate: (value) =>
                        value === currentEmail.current ||
                        "The verify email do not match",
                    })}
                  /> */}
                  </div>
                </div>

                <div className="form-group">
                  <p className="form-group-label">Phone</p>
                  <div className="sign-up-phone">
                    <EditableText
                      inputClassName="editable-input"
                      control={control}
                      name="contact_info"
                      country="us"
                      error={errors.contact_info}
                      rules={{
                        required: {
                          value: true,
                          message: "Phone is required",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <p className="form-group-label">Password</p>
                <div className="inline-inputs">
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter password",
                      },
                      minLength: {
                        value: 4,
                        message: "Password must be between 4 to 16 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "Password must be between 4 to 16 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        message:
                          "Must content at least one upper case, lower case, digit, special character and no white space",
                      },
                    })}
                  />
                  <Input
                    type="password"
                    name="confirm_password"
                    placeholder="Re-enter Password"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter  password",
                      },
                      validate: (value) =>
                        value === password.current ||
                        "The re-entered do not match",
                    })}
                  />
                </div>
              </div>
              {signUpState === "company" ? (
                <div className="form-group">
                  <p className="form-group-label">Company Name</p>
                  <div className="inline-inputs">
                    <AsyncCreatableSelect
                      control={control}
                      // validationObj={errors}
                      placeholder="Search Company..."
                      name="company_name"
                      onStartSearching={searchCompanies}
                      noOptionsMessage="No matching company found"
                      rules={{
                        required: {
                          value: true,
                          message: "Please select company",
                        },
                      }}
                    />
                    <Input
                      name="employee_id"
                      placeholder="Enter Employee ID"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="form-group gender-group">
                    <p className="form-group-label">Gender</p>
                    <Radio
                      name="gender"
                      control={control}
                      wrapperClassName="gender-radio-wrapper w-100"
                      labelClassName="gender-radio"
                      validationObj={errors}
                      onChange={(e: React.ChangeEvent<any>) => {
                        setGenderVal(_.get(e, "target.value", ""));
                      }}
                      options={[
                        { label: "Female", value: "female" },
                        { label: "They", value: "they" },
                        { label: "Custom", value: "custom" },
                      ]}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please select gender",
                        },
                      })}
                    />
                  </div>
                  <div className="form-group pronoun-fields">
                    {genderVal === "custom" && (
                      <>
                        <div className="inline-inputs">
                          <div className="select-wrapper">
                            <SelectNew
                              name="gender_pronoun"
                              placeholder="Select your pronoun"
                              className="select-box"
                              options={CUSTOM_GENDER_OPTIONS}
                              isSearchable={false}
                              validationObj={errors}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please select your pronoun",
                                },
                              }}
                            />
                          </div>
                          <Input
                            name="gender_optional"
                            placeholder="Customize your pronoun"
                            className="geneder-field"
                            validationObj={errors}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Customize your pronoun",
                              },
                              maxLength: {
                                value: 15,
                                message: "Can't exceed more then 15 Character",
                              },
                            })}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {/* <div className="form-group captcha-view">
                <div dangerouslySetInnerHTML={{
                  __html: captchaImage,
                }} />

                <Input
                  name="captcha_answer"
                  placeholder="Enter Captcha Answer"
                  validationObj={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AutoModeIcon className="captcha-icon" onClick={() => createCaptcha()} />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter captcha answer",
                    },
                    pattern: {
                      value: /[0-9]+/,
                      message: "Enter valid captcha answer",
                    },
                    validate: (value) =>
                      value === captchaAnswer ||
                      "The verify captcha do not match",
                  })}
                />
              </div> */}
              <div className="form-group">
                <div className="inline-inputs check">
                  <label
                    htmlFor="privacyAgreement"
                    className="privacy-agreement"
                  >
                    By signing up, I agree to employ<span>HER</span>’s{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={appRoutes.termsOfService.generatePath()}
                    >
                      Terms of Service
                    </a>
                    .
                  </label>
                  {errors.termsAndConditions && (
                    <FormHelperText className="text-danger">
                      {errors.termsAndConditions.message}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="form-group btn-group">
                <Button type="submit" color="primary" loading={loading}>
                  Create account
                </Button>
              </div>
            </form>
            {/* <div className="social-login-wrapper">
          <div className="label-wrapper">
            <p>Or sign up with</p>
          </div>
          <div className="icons">
            <div>
              <Image src={GoogleIcon} alt="social-icon-google" />
            </div>
            <div>
              <Image src={FacebookIcon} alt="social-icon-facebook" />
            </div>
          </div>
        </div> */}
          </div>
          <p className="outer-link sign-up-outer-link">
            Already have an account?{" "}
            <Link to={appRoutes.userLogin.path}>Sign in</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUp;
