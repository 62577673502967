import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../../routes/app.routes";
import { rootReducersState } from '../../../reducers';
import { useSelector } from 'react-redux';

enum cardType {
  EMAIL_EDITOR = "editor",
  SAVED_TEMPLATES = "template_library",
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  card: {
    transition: "box-shadow 0.3s",
    position: "relative",
    "&:hover": {
      boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.2)",
      border: "2px solid #ff0a90",
      "& $overlayWrapper": {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(2),
  },
  overlayWrapper: {
    cursor: "pointer",
    backgroundColor: "#fff",
    padding: "46px",
    position: "absolute",
    transition: "visibility 0s,opacity .15s ease-in-out",
    top: 0,
    width: "248px",
    opacity: 0,
    visibility: "hidden",
    textAlign: "center",
  },
}));
const CreateEmailTemplate = () => {
  const classes = useStyles();
  const history = useHistory();

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const handleCardClick = (type) => {
    if (type === cardType.EMAIL_EDITOR) {
      history.push(appRoutes.emailEditor.generatePath(companySlug));
    }else{
      history.push(appRoutes.emailTemplates.generatePath(companySlug));
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* Start from scratch card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <PlayCircleOutlineIcon className={classes.icon} color="primary" />
              <Typography variant="h6" align="center">
                Start from Scratch
              </Typography>
              <div className={classes.overlayWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCardClick(cardType.EMAIL_EDITOR)}
                >
                  Apply
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Choose from saved templates card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <SaveIcon className={classes.icon} color="primary" />
              <Typography variant="h6" align="center">
                Choose from Saved Templates
              </Typography>
              <div className={classes.overlayWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCardClick(cardType.SAVED_TEMPLATES)}
                >
                  Apply
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateEmailTemplate;
