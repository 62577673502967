import React from "react";
import { useForm } from "react-hook-form";
import { TextField, InputLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setTags } from "../../../reducers/company/companyMarketingSolution.reducer";

interface TagInputType {
  tag: string;
}

const TagInput = () => {
  const { register, handleSubmit, setValue } = useForm<TagInputType>();
  // const [tags, setTags] = useState([]);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    // const tagsData = [];
    // tagsData.push(data.tag);
    // setTags((prev) => [...prev, ...tagsData]);
    dispatch(setTags(data.tag));
    setValue("tag", "");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="tags-form">
        <InputLabel htmlFor="custom-textfield">Create a tag</InputLabel>
        <TextField
          className="text-field"
          name="tag"
          inputRef={register({
            required: {
              value: true,
            },
          })}
          id="custom-textfield"
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }
          }}
          placeholder="Create a tag"
        />
      </form>
    </>
  );
};

export default TagInput;
