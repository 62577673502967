import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  AsyncSelect,
  Button,
  EditableText,
  Input,
  Modal,
  Radio,
} from "./../../../../components/common";
import EmailCampaign from "../../../../assets/svg/start-campaign.svg";
import { useForm } from "react-hook-form";
import history from "../../../../utils/history";
import appRoutes from "../../../../routes/app.routes";
import { rootReducersState } from "../../../../reducers";
import { useSelector } from "react-redux";
import marketingSolutionServices from "../../../../services/marketingSolution.services";
import _ from "lodash";
import { useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FlashMessage from "../../../../components/common/FlashMessage";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ReactComponent as PrevArrow } from "../../../../assets/svg/angle-left-pink.svg";
import { ReactComponent as NextArrow } from "../../../../assets/svg/angle-right-pink.svg";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

interface CampaignData {
  user_id?: number;
  campaign_name?: string;
  campaign_id?: string;
  is_sent?: number;
  subject?: string;
  preview_text?: string;
  sender_name?: string;
  sender_email?: string;
  template_id?: string;
  created_at?: string;
  updated_at?: string;
  tags?: {
    tag_name: string;
    id: number;
  };
  test_emails?: []
}
const StartCampaign = () => {
  const { register, handleSubmit, errors, control, reset } = useForm({});

  const initialCampaignData = {
    campaign_name: "",
    is_sent: 0,
    subject: "",
    preview_text: "",
    sender_name: "",
    sender_email: "",
    template_id: "",
  };
  const [templateOption, setTemplateOption] = useState<string>("");
  const [templateModal, setTemplateModal] = useState<boolean>(false);
  const templateId: any = history?.location?.state?.id;
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [openTestModal, setOpenTestModal] = useState<boolean>(false);
  const [testEmailLoader, setTestEmailLoader] = useState<boolean>(false);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  const [openCardModal, setOpenCardModal] = useState<string>("");
  const [templatesList, setTemplatesList] = useState([]);
  const [listTag, setListTag] = useState<Array<any>>([]);
  const { campaignId }: any = useParams();
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [campaignData, setCampaignData] = useState<CampaignData>({
    ...initialCampaignData,
  });
  const [sendLoader, setSendLoader] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("00:00");
  const [scheduleLoading, setScheduleLoading] = useState<boolean>(false);

  const logDateTime = (date, time) => {
    const dateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      parseInt(time.split(":")[0]),
      parseInt(time.split(":")[1])
    );
    setSelectedDate(dateTime);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    logDateTime(date, selectedTime);
  };

  const generateTimeOptions = () => {
    const times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      times.push(`${formattedHour}:00`, `${formattedHour}:30`);
    }
    return times;
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    logDateTime(selectedDate, event.target.value);
  };

  const getTagList = async () => {
    const result: any = await marketingSolutionServices.getListTag();
    setListTag(result?.data?.rows || []);
  };
  useEffect(() => {
    getTagList(); // Fetch tag list data when component mounts
  }, []);

  const customLoadOptions = async () => {
    // Your existing customLoadOptions function logic here
    let categories = listTag.map((category) => ({
      value: category.id, // Use tag_name as value
      label: category.tag_name, // Use tag_name as label
    }));

    const options = [
      {
        label: "All audience",
        options: [
          { value: -1, label: "All subscribers in audience" },
          // Add more static options for all audience as needed
        ],
      },
      {
        label: "Tags",
        options: categories, // Use filtered categories as dynamic options under "Tags"
      },
    ];

    return options;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: any = await marketingSolutionServices.fetchEmailTemplates();
        if (res.flag) {
          setTemplatesList(res?.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  const filteredList = (id) => {
    return templatesList?.filter((item: any) => item.template_id === id);
  };

  const onSubmit = async (data) => {
    setCampaignLoading(true);
    const payload = {
      campaign_id: campaignId,
      campaign_name: _.get(data, "campaign_name", ""),
      sender_email: _.get(data, "sender_email", ""),
      sender_name: _.get(data, "sender_name", ""),
      subject: _.get(data, "subject", ""),
      tags: _.get(data, "sent_to.value", ""),
      preview_text: _.get(data, "preview_text", ""),
      template_id: selectedTemplate || templateId,
    };

    try {
      const result = await marketingSolutionServices.createCampaign(payload);
      if (result.flag) {
        const {
          campaign_name,
          sent_to,
          subject,
          preview_text,
          sender_name,
          sender_email,
          template_id,
        } = data;

        reset({
          campaign_name,
          sent_to,
          subject,
          preview_text,
          sender_name,
          sender_email,
          template_id,
        });
        setCampaignLoading(false);
        setCampaignData((prevCampaignData: CampaignData) => ({
          ...prevCampaignData,
          campaign_name,
          tags: sent_to,
          subject,
          preview_text,
          sender_name,
          sender_email,
          template_id: selectedTemplate || templateId,
        }));
        FlashMessage(result.message);
      }
    } catch (error) {
      setCampaignLoading(false);
      console.error("Error creating campaign:", error);
      FlashMessage("error", error.message);
    }
  };

  // getCampaignById
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result: any = await marketingSolutionServices.getCampaignById(
          campaignId
        );

        if (result && Object.keys(result).length > 0 && result.flag) {
          // Extract relevant fields from the result object
          const {
            campaign_name,
            tags,
            subject,
            preview_text,
            sender_name,
            sender_email,
            template_id,
          } = result.data;

          const sent_to = {
            value: tags.id, // Use tag_name as value
            label: tags.tag_name,
          };
          setCampaignData(result.data);
          setSelectedTemplate(template_id);
          // Populate the form fields with the fetched campaign data using reset
          reset({
            campaign_name,
            sent_to,
            subject,
            preview_text,
            sender_name,
            sender_email,
            template_id,
          });
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const sendCampaign = async (data) => {

    setSendLoader(true);
    try {
      setTimeout(() => {
        setSendLoader(false);
        history.push(appRoutes.marketingSolution.generatePath(companySlug)); // Redirect to the desired route
      }, 3000);
      const Payload = {
        ...data,
        campaign_id: campaignId, // Add campaignId from useParams
      };
      await marketingSolutionServices.sendCampaign(Payload); // No need to use the result anymore
    } catch (error) {
      setSendLoader(false);
      console.error("Error sending campaign:", error);
    }
  };

  const testEmailCampaign = async (payload) => {
    setTestEmailLoader(true);
    try {
      const response = await marketingSolutionServices.testEmailCampaign(
        payload
      );
      if (response.flag) {
        FlashMessage(response.message);
        setTestEmailLoader(false);
        setOpenTestModal(false);
      }
    } catch (error) {
      setTestEmailLoader(false);
      console.error("Error sending test email campaign:", error);
    }
  };




  return (
    <div className="email-main-wrapper">
      <div className="email-campaign-wrapper">
        <Grid container alignItems="center">
          <Grid item md={6}>
            <div className="email-content start-campaign">
              <h2>
                Lets Get Starts Draft an <span>Email</span>
              </h2>
              <p>
                Lorem Ipsum simply dummy text printing been typesetting a
                industry. lorem Ipsum has been industry's anstanda dummy text
                ever since.
              </p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="email-img start-campaign-img">
              <img src={EmailCampaign} alt="email-campaign" />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="email-campaign-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="campaign-name-wrapper">
            <div className="campaign-header">
              <div className="campaign-title">
                <EditableText
                  name="campaign_name"
                  defaultValue="Campaign Name"
                  inputClassName="editable-input"
                  control={control}
                  textProps={{
                    className: "m-0 font-lg",
                  }}
                  country="US"
                  error={errors.campaign_name}
                  rules={{
                    required: { value: true, message: "Name is required" },
                    maxLength: {
                      value: 20,
                      message: "Name must be between 2 to 20 characters long",
                    },
                    minLength: {
                      value: 2,
                      message: "Name must be between 2 to 20 characters long",
                    },
                  }}
                />
              </div>
              <div className="share-icon">
                <Button
                  className="back-btn btn-link"
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIosIcon
                    width="10"
                    height="10"
                    className="angle-left"
                  />{" "}
                  Back
                </Button>
              </div>
            </div>
            <div className="campaign-content">
              <div className="campaign-card">
                <div className="card-head">
                  <div className="card-title">
                    <b>To:</b> (Who are you sending this campaign to?)
                  </div>
                  <div className="add-btn">
                    <Button onClick={() => setOpenCardModal("To")}>
                      Add Recipient
                    </Button>
                  </div>
                </div>
                {(openCardModal === "To" || !_.isEmpty(campaignData?.tags)) && (
                  <div className="card-modal">
                    <div className="modal-content">
                      <AsyncSelect
                        name="sent_to"
                        placeholder="Search for audience..."
                        externalLabel={{
                          label: "Send to",
                          className: "required",
                        }}
                        onStartSearching={customLoadOptions}
                        validationObj={errors}
                        control={control}
                        noOptionsMessage="No matching audience found"
                        rules={{
                          required: {
                            value: true,
                            message: "Please select audience from the list",
                          },
                        }}
                      />
                    </div>
                    <div className="import-btn-wrapper">
                      <Button
                        className="btn-secondary"
                        onClick={() => setOpenCardModal("")}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-primary filled-btn"
                        type="submit"
                        loading={campaignLoading}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div className="campaign-card">
                <div className="card-head">
                  <div className="card-title">
                    <b>Content:</b> (Design a content for Email)
                  </div>
                  <div className="add-btn">
                    <Button
                      disabled={_.isEmpty(campaignData.tags)}
                      onClick={() => setOpenCardModal("Content")}
                    >
                      Design Email
                    </Button>
                  </div>
                </div>
                {!_.isEmpty(
                  filteredList(
                    templateId && _.isEmpty(selectedTemplate)
                      ? templateId
                      : selectedTemplate
                  )
                ) &&
                  filteredList(
                    templateId && _.isEmpty(selectedTemplate)
                      ? templateId
                      : selectedTemplate
                  ).map((template: any, index) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: template.design_html,
                      }}
                      className="template-content"
                    />
                  ))}
                {(openCardModal === "Content" ||
                  !_.isEmpty(campaignData.campaign_id)) && (
                    <div className="card-modal">
                      <div className="modal-content">
                        <div className="template-card">
                          <Radio
                            name="choose_template"
                            control={control}
                            wrapperClassName="template-radio-wrapper w-100"
                            labelClassName="gender-radio"
                            validationObj={errors}
                            onChange={(e: React.ChangeEvent<any>) => {
                              setTemplateOption(_.get(e, "target.value", ""));
                            }}
                            options={[
                              {
                                label: "Start from scratch",
                                value: "scratch",
                              },
                              {
                                label: "Select from existing template",
                                value: "existence",
                              },
                            ]}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please select a template",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="import-btn-wrapper content-btn">
                        <Button
                          className="btn-secondary"
                          onClick={() => {
                            setTemplateOption("");
                            setOpenCardModal("");
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={templateOption === ""}
                          className="btn-primary filled-btn"
                          onClick={() => {
                            if (templateOption === "scratch") {
                              history.push({
                                pathname:
                                  appRoutes.emailEditor.generatePath(companySlug),
                                state: { id: campaignId, is_campaign: true },
                              });
                            } else {
                              setTemplateModal(true);
                            }
                          }}
                        >
                          {templateOption === "scratch"
                            ? "Create Template"
                            : "Choose Template"}
                        </Button>
                        <Button
                          className="btn-primary filled-btn"
                          type="submit"
                          disabled={!templateId && !selectedTemplate}
                          loading={campaignLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
              </div>

              <div className="campaign-card">
                <div className="card-head">
                  <div className="card-title">
                    <b>From:</b> (Who is sending this campaign ?)
                  </div>
                  <div className="add-btn">
                    <Button
                      disabled={_.isEmpty(campaignData.template_id)}
                      onClick={() => setOpenCardModal("Sender")}
                    >
                      Add Sender
                    </Button>
                  </div>
                </div>
                {(openCardModal === "Sender" ||
                  !_.isEmpty(campaignData?.sender_name) ||
                  !_.isEmpty(campaignData?.sender_email)) && (
                    <div className="card-modal">
                      <div className="modal-content">
                        <div className="form-control">
                          <Input
                            name="sender_name"
                            placeholder="Sender Name"
                            externalLabel={{ label: "Sender Name" }}
                            validationObj={errors}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please enter the sender name",
                              },
                            })}
                          />
                        </div>
                        <div className="form-control">
                          <Input
                            name="sender_email"
                            type="email"
                            placeholder="abc@gmail.com"
                            externalLabel={{ label: "Sender Email Address" }}
                            validationObj={errors}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please enter the sender email address",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="import-btn-wrapper">
                        <Button
                          className="btn-secondary"
                          onClick={() => setOpenCardModal("")}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-primary filled-btn"
                          type="submit"
                          loading={campaignLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
              </div>

              <div className="campaign-card">
                <div className="card-head">
                  <div className="card-title">
                    <b>Subject:</b> (What's the subject line for this campaign?)
                  </div>
                  <div className="add-btn">
                    <Button
                      disabled={
                        _.isEmpty(campaignData.sender_name) &&
                        _.isEmpty(campaignData.sender_email)
                      }
                      onClick={() => setOpenCardModal("Subject")}
                    >
                      Add Subject
                    </Button>
                  </div>
                </div>

                {(openCardModal === "Subject" ||
                  !_.isEmpty(campaignData?.subject) ||
                  !_.isEmpty(campaignData?.preview_text)) && (
                    <div className="card-modal">
                      <div className="modal-content">
                        <div className="form-control">
                          <Input
                            name="subject"
                            placeholder="Subject"
                            externalLabel={{ label: "Subject" }}
                            validationObj={errors}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please the subject",
                              },
                            })}
                          />
                        </div>
                        <div className="form-control">
                          <Input
                            name="preview_text"
                            placeholder="Preview Text"
                            externalLabel={{ label: "Preview Text" }}
                            validationObj={errors}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please enter the preview text",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="import-btn-wrapper">
                        <Button
                          className="btn-secondary"
                          onClick={() => setOpenCardModal("")}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-primary filled-btn"
                          type="submit"
                          loading={campaignLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="btn-wrapper mt-4 center">
              <Button
                variant="outlined"
                className="outlined"
                onClick={() => {
                  setScheduleModal(true);
                }}
              >
                Send Later
              </Button>
              <Button
                variant="outlined"
                className="outlined"
                loading={sendLoader}
                onClick={() => setOpenTestModal(true)}
                disabled={
                  !campaignData.template_id ||
                  !campaignData.preview_text ||
                  !campaignData.subject
                }
              >
                Test Send
              </Button>
              <Button
                className="secondary"
                loading={sendLoader}
                onClick={() => sendCampaign({ ...campaignData, is_sent: 1 })}
                disabled={
                  !campaignData.template_id ||
                  !campaignData.preview_text ||
                  !campaignData.subject
                }
              >
                Start Campaign
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        size="x-large"
        title="Choose an existing template"
        onClose={() => setTemplateModal(false)}
        visible={templateModal}
        className="template-modal"
      >
        <div className="existing-card-wrapper">
          {templatesList.map((template: any, index) => (
            <div key={index} className="existing-template-card">
              <div
                dangerouslySetInnerHTML={{ __html: template.design_html }}
                className="template-content"
              />
              <div className="load-btn">
                <Button
                  className="btn-secondary"
                  onClick={() => {
                    setSelectedTemplate(template?.template_id);
                    setTemplateModal(false);
                  }}
                >
                  Choose Template
                </Button>
                <Button
                  onClick={() => {
                    const newRoute = appRoutes.emailTemplates.generatePath(
                      companySlug,
                      template.id
                    );
                    window.open(newRoute, "_blank");
                  }}
                >
                  View Template
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <Button onClick={() => setTemplateModal(false)}>Cancel</Button>
        </div>
      </Modal>

      <Modal
        size="medium"
        title="Schedule campaign time"
        onClose={() => setScheduleModal(false)}
        visible={scheduleModal}
        className="template-modal calenderModal"
      >
        <div className="date-content">
          <div className="calendar-date-wrapper">
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              next2Label={false}
              prev2Label={false}
              minDate={new Date()}
              prevLabel={
                <span className="custom-prev-label">
                  <PrevArrow />
                </span>
              }
              nextLabel={
                <span className="custom-prev-label">
                  <NextArrow />
                </span>
              }
            />
            <FormControl className="mt-4 d-flex w-100">
              <InputLabel id="time-select-label">Select Time</InputLabel>
              <Select
                labelId="time-select-label"
                id="time-select"
                value={selectedTime}
                onChange={handleTimeChange}
                defaultValue={selectedTime}
              >
                {generateTimeOptions().map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="btn-wrapper mt-4 center">
          <Button
            variant="outlined"
            className="outlined mr-2 btn-primary"
            onClick={() => {
              setScheduleModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="secondary"
            loading={scheduleLoading}
            onClick={async () => {
              setScheduleLoading(true);
              try {
                const result: any =
                  await marketingSolutionServices.createCampaign({
                    ...campaignData,
                    schedule_at: selectedDate.toISOString(),
                    tags: campaignData.tags?.id,
                    is_sent: 0,
                  });
                if (result.flag) {
                  FlashMessage("Campaign scheduled successfully");
                  setScheduleLoading(false);
                  setScheduleModal(false);
                  history.push(
                    appRoutes.marketingSolution.generatePath(companySlug)
                  );
                }
              } catch (error) {
                FlashMessage("error", error.message);
                setScheduleLoading(false);
                setScheduleModal(false);
              }
            }}
            disabled={selectedDate.toISOString() === ""}
          >
            Schedule
          </Button>
        </div>
      </Modal>

      <Modal
        size="large"
        title="Send campaign to test emails."
        onClose={() => setOpenTestModal(false)}
        visible={openTestModal}
        className="template-modal calenderModal"
      >
        <form
          onSubmit={handleSubmit((data) => {
            const emailArray = data.test_emails
              .split(",")
              .map((email) => email.trim());
            testEmailCampaign({ ...campaignData, test_emails: emailArray });
          })}
        >
          <div className="form-control">
            <Input
              name="test_emails"
              placeholder="Enter up to 2 test emails, separated by commas"
              externalLabel={{ label: "Test email" }}
              validationObj={errors}
              inputRef={register({
                required: {
                  value: true,
                  message: "Please enter at least one test email",
                },
                validate: (value) => {
                  const emails = value.split(",").map((email) => email.trim());
                  if (emails.length > 2) {
                    return "You can only enter up to two email addresses.";
                  }
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  const invalidEmails = emails.filter(
                    (email) => !emailRegex.test(email)
                  );
                  if (invalidEmails.length > 0) {
                    return `Invalid email(s): ${invalidEmails.join(", ")}`;
                  }
                  return true; // Validation passes
                },
              })}
            />
          </div>
          <div className="flex justify-content-center mt-4">
            <Button
              className="secondary"
              loading={testEmailLoader}
              type="submit"
              disabled={testEmailLoader || !_.isEmpty(errors)}
            >
              Test Campaign
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default StartCampaign;
