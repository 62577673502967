import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { Button, Input } from "../../components/common";
import {
  resetPasswordRequest,
  resetPasswordState,
  resetPasswordMessages,
} from "../../reducers/auth/resetPassword.reducer";
import { FlashMessage } from "../../components/common";
import appRoutes from "../../routes/app.routes";
import { Link } from "react-router-dom";
import { verifyResetPasswordLink } from "../../utils/appUser";
import { Helmet } from "react-helmet";
import { rootReducersState } from "../../reducers";
import { InputAdornment } from "@material-ui/core";
import { ReactComponent as PersonIcon } from "../../assets/svg/person.svg";
import Affirmation from "../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import httpRequest from "../../utils/httpRequest";
// import API_URLS from "../../utils/apiUrls";
// import { useLocation } from "react-router-dom";
// import AutoModeIcon from '@material-ui/icons/Loop';

const _ = { get };

type Inputs = {
  new_password: string;
  confirm_password: string;
};

const ResetPassword = (props: any) => {
  // Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageTitle, setPageTitle] = useState<string>("Reset Password");
  const [validToken, setValidToken] = useState<boolean>(false);
  // const [captchaImage, setCaptchaImage] = useState("");
  // const [captchaAnswer, setCaptchaAnswer] = useState();
  // const location = useLocation();
  // const path = location.pathname

  const resetPassword = useSelector(
    ({ auth }: rootReducersState) => auth.resetPassword
  );
  const successMessage = _.get(resetPassword, "successMessage", "");
  const errorMessage = _.get(resetPassword, "errorMessage", "");
  const loadingFlag = _.get(resetPassword, "loading", false);
  const resetPasswordFlag = _.get(resetPassword, "flag", false);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenData = _.get(sessionReducer, "currentUser", {});

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset: resetForm,
  } = useForm<Inputs>();

  const watchNewPassword = watch("new_password"); // Watch email change to reset the success or error message

  // Did mount effect
  useEffect(() => {
    // Check if user is already logged in
    const userRole = _.get(tokenData, "id", "");
    if (userRole) {
      history.push("/");
    }

    const token = _.get(props, "match.params.token", "");

    // Check if link is expired or not
    const tokenInfo = verifyResetPasswordLink(token);
    const isValidToken = tokenInfo?.email ? true : false;
    let pgTitle = pageTitle;

    if (tokenInfo?.source === "COMPANY_INVITE") {
      pgTitle = "Create Password";
    } else if (tokenInfo?.source === "WELCOME_INVITATION") {
      pgTitle = "Create Password";
    }
    setPageTitle(pgTitle);
    setValidToken(isValidToken);

    // Did unmounted, reset the store
    return () => {
      dispatch(resetPasswordState());
    };

    // eslint-disable-next-line
  }, []);

  // Clear the error or success message only if feasible
  useEffect(() => {
    if (errorMessage || successMessage) {
      dispatch(resetPasswordMessages());
    }

    // eslint-disable-next-line
  }, [watchNewPassword]);

  // Hook, once user forgot password sent successfully
  useEffect(() => {
    if (resetPasswordFlag === true) {
      FlashMessage(successMessage);
      dispatch(resetPasswordState());
      resetForm();
      history.push(appRoutes.userLogin.path);
    } else if (resetPasswordFlag === false) {
      FlashMessage(errorMessage, "error");
      dispatch(resetPasswordState());
    }

    // eslint-disable-next-line
  }, [resetPasswordFlag]);

  // useEffect(() => {
  //   if (path.includes('/reset-password')) {
  //     createCaptcha();
  //   }
  // }, [path])

  const onSubmit = async (formData) => {
    // const captcha = formData.captcha_answer

    // if (captcha === captchaAnswer) {
    //   const payload = {
    //     verification_token: _.get(props, "match.params.token", ""),
    //     newPassword: _.get(formData, "new_password", ""),
    //     confirmPassword: _.get(formData, "confirm_password", ""),
    //     token: captcha,
    //     action: "reset_password",
    //   };

    //   dispatch(resetPasswordRequest(payload));
    // }

    const payload = {
      verification_token: _.get(props, "match.params.token", ""),
      newPassword: _.get(formData, "new_password", ""),
      confirmPassword: _.get(formData, "confirm_password", ""),
      // token: captcha,
      action: "reset_password",
    };

    dispatch(resetPasswordRequest(payload));
  };

  // const createCaptcha = async () => {
  //   const data = await httpRequest().post(API_URLS.captcha);
  //   const svgImage = data.data.data.data
  //   const responseCaptchaAnswer = data.data.data.text
  //   setCaptchaAnswer(responseCaptchaAnswer)
  //   setCaptchaImage(svgImage)
  // }

  return (
    <>
      <Helmet>
        <title>employHER | {pageTitle}</title>
      </Helmet>
      <div className="auth-wrapper">
        <div className="left-part">
          <Affirmation />
        </div>
        <div className="right-part">
          <div>
            <div className="back-website">
              <div className="back">
                <Link to={appRoutes.home.path}>
                  {" "}
                  <ArrowBackIosIcon />{" "}
                </Link>
              </div>
              <Link to={appRoutes.home.path}>Back to website</Link>
            </div>
            <div className="auth-card auth-login-wrapper reset-password-wrapper">
              <h2 className="auth-card-title">{pageTitle}</h2>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                {validToken ? (
                  <>
                    <div className="form-group">
                      <Input
                        name="new_password"
                        type="password"
                        validationObj={errors}
                        externalLabel={{ label: "New password" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Please enter new password",
                          },
                          minLength: {
                            value: 4,
                            message:
                              "Password must be between 4 to 16 characters",
                          },
                          maxLength: {
                            value: 16,
                            message:
                              "Password must be between 4 to 16 characters",
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                            message:
                              "Must content at least one upper case, lower case, digit, special character and no white space",
                          },
                        })}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        name="confirm_password"
                        type="password"
                        validationObj={errors}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                        externalLabel={{ label: "Confirm password" }}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Please enter confirm password",
                          },
                        })}
                      />
                    </div>
                    {/* <div className="captcha-view">
                      <div dangerouslySetInnerHTML={{
                        __html: captchaImage,
                      }} />

                      <Input
                        name="captcha_answer"
                        placeholder="Enter Captcha Answer"
                        validationObj={errors}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoModeIcon className="captcha-icon" onClick={() => createCaptcha()} />
                            </InputAdornment>
                          ),
                        }}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Please enter captcha answer",
                          },
                          pattern: {
                            value: /[0-9]+/,
                            message: "Enter valid captcha answer",
                          },
                          validate: (value) =>
                            value === captchaAnswer ||
                            "The verify captcha do not match",
                        })}
                      />
                    </div> */}
                    <div className="row text-center mt-10">
                      <Button
                        type="submit"
                        color="primary"
                        loading={loadingFlag}
                      >
                        {pageTitle}
                      </Button>
                    </div>
                  </>
                ) : (
                  <p className="text-center">
                    <span className={`text-sm text-danger`}>
                      The link is expired, please{" "}
                      <Link to={appRoutes.userForgotPassword.path}>
                        click here
                      </Link>{" "}
                      to request for new password
                    </span>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
