import React from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Modal } from "../../components/common";
import FlashMessage from "./FlashMessage";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";

interface BlockUserModalProps {
  show: boolean;
  onClose: () => void;
  blockedId: string | number;
  onBlockComplete: (userId: string | number) => void;
}

interface FormInputs {
  block_reason: string;
}

const BlockUserModal = ({ show, onClose, blockedId: userId, onBlockComplete }: BlockUserModalProps) => {
  const { register, handleSubmit, errors } = useForm<FormInputs>();


  const onSubmit = async (formData: FormInputs) => {
    try {
      const payload = {
        blockedId: userId,
        reason: formData.block_reason,
      };
      const response = await httpRequest().post(API_URLS.blockUser, payload);


      if (response.data.message === "Success") {
        onClose();
        onBlockComplete(userId);
      }
    } catch (error: any) {
      FlashMessage("Unable to block user", "error");
    }
  };

  return (
    <>
      <Modal
        visible={show}
        onClose={onClose}
        title="Block User"
        className="block-user-modal"
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>

          <Input
            name="block_reason"
            type="text"
            externalLabel={{ label: "Block Reason" }}
            placeholder="Enter Block Reason"
            validationObj={errors}
            inputRef={register({
              required: {
                value: true,
                message: "Please enter block reason"
              },
            })}
          />

          <div className="d-flex justify-content-center mt-30">
            <Button
              type="submit"
              color="primary"
              className="submit-button text-uppercase"
            >
              Block User
            </Button>
            <Button
              variant="text"
              onClick={onClose}
              color="secondary"
              className="cancel-button text-uppercase"
            >
              Cancel
            </Button>
          </div>

        </form>
      </Modal>
    </>

  );
};

export default BlockUserModal;
