import React, { useEffect } from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import AddIcon from "@material-ui/icons/Add";
import { Grid, Button, IconButton, InputLabel } from "@material-ui/core";
import moment from "moment";
import { Checkbox, Input } from "../../../components/common";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import RichTextEditor from "../../common/RichTextEditor";
import { stripHTMLTags } from "../../../utils/helper";

const FormItem = ({ item, value, remove, index, ...props }) => {
  const { setError, watch } = props;
  const maxLimit = 1000;
  const projectValue = watch(`projects[${index}].description`, "");

  useEffect(() => {
    const tmpText = stripHTMLTags(projectValue);
    if (tmpText.length > maxLimit) {
      setError(`projects[${index}].description`, {
        type: "validate",
        message: `Please enter less than ${maxLimit} characters`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectValue]);

  return (
    <div className="career-differentiators-form-item">
      <div className="career-differentiators-row">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <div>
              <Input
                name={`projects[${index}].title`}
                placeholder="Project Title"
                externalLabel={{ label: "Project Title" }}
                defaultValue={item.title}
                validationObj={props.errors}
                required={false}
                inputRef={props.register({
                  required: {
                    value: false,
                    message: "Please enter a valid Project Title",
                  },
                })}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div>
              <Input
                name={`projects[${index}].link`}
                placeholder="Project URL"
                externalLabel={{ label: "Project URL" }}
                validationObj={props.errors}
                defaultValue={item.link}
                required={false}
                inputRef={props.register({
                  required: {
                    value: false,
                    message: "Please enter a valid Project URL",
                  },
                  pattern: {
                    value:
                      /[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
                    message: "Enter a valid Project URL",
                  },
                })}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="date-input">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="date-fields">
                    <Input
                      externalLabel={{ label: "Select Date" }}
                      name={`projects[${index}].start_date`}
                      placeholder="Work Experience"
                      type="date"
                      defaultValue={item.start_date}
                      validationObj={props.errors}
                      required={false}
                      inputRef={props.register({
                        required: {
                          value: false,
                          message: "Please enter a valid Work Experience",
                        },
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="date-fields edit-education-present">
                    <Checkbox
                      onChange={(e) =>
                        props._handlePresentCheckBoxChange(e, index)
                      }
                      inputRef={props.register}
                      checked={value[index]?.is_present}
                      externalLabel={{ label: "Present" }}
                      name={`projects[${index}].is_present`}
                    />
                    {value[index]?.is_present ? (
                      <InputLabel className="present-label">Present</InputLabel>
                    ) : (
                      <Input
                        name={`projects[${index}].end_date`}
                        defaultValue={item.end_date}
                        placeholder="End date"
                        type="date"
                        validationObj={props.errors}
                        required={false}
                        inputProps={{
                          min: props.getValues?.(`projects[${index}].start_date`) || item.start_date || ""
                        }}
                        inputRef={props.register({
                          required: {
                            value: false,
                            message: "Please enter a valid Date",
                          },
                        })}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="rich-text-editor">
              <RichTextEditor
                name={`projects[${index}].description`}
                externalLabel={{
                  label: "Project Description",
                }}
                defaultValue={item.description}
                placeholder="Project Description"
                validationObj={props.errors}
                control={props.control}
                rules={{
                  required: {
                    value: false,
                    message: "Please enter a valid Project Description",
                  },
                  validate: {
                    validLength: (value) => {
                      const tmpText = stripHTMLTags(value || "");
                      return (
                        tmpText.length <= maxLimit ||
                        `Please enter less than ${maxLimit} characters`
                      );
                    },
                  },
                }}
                showCount
                maxLimit={maxLimit}
              />
            </div>
            <div className="delete-icon-wrapper">
              <Button
                onClick={() => remove(index)}
                variant="text"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const CareerDifferentiatorsWork: React.FC<any> = (props) => {
  const control: Control<Record<string, any>> = props.control;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "projects",
  });

  const value = useWatch({
    name: "projects",
    control,
  });

  const _handlePresentCheckBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const { checked } = e.target;
    if (checked) {
      props.setValue(
        `projects[${index}].end_date`,
        moment().format("YYYY-MM-DD")
      );
    } else {
      props.setValue(`projects[${index}].end_date`, "");
    }
  };

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <FormItem
            value={value}
            key={item.id}
            _handlePresentCheckBoxChange={_handlePresentCheckBoxChange}
            item={item}
            index={index}
            remove={remove}
            {...props}
          />
        );
      })}
      <IconButton
        className="add-button"
        onClick={() => {
          append({
            link: "",
            title: "",
            end_date: "",
            start_date: "",
            description: "",
            is_present: false,
          });
        }}
      >
        <AddIcon />
        Add New
      </IconButton>
    </div>
  );
};

export default CareerDifferentiatorsWork;
