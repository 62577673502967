import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContactsListRequest } from "../../../reducers/company/companyMarketingSolution.reducer";
import { rootReducersState } from "../../../reducers";
import _ from "lodash";
import Contacts from "./contacts";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";
import { Button } from "../../../components/common";

const pageSize = 50;

const ContactList = () => {
  const [page, setPage] = useState(1);
  const history = useHistory();
  const marketingSolutionData = useSelector(
    ({ company }: rootReducersState) => company.companyMarketingSolution
  );
  const [contactList, setContactList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const pagination = _.get(marketingSolutionData, "contactList.pagination", {});
  const contactListCount = _.get(pagination, "totalPages", 0);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  const dispatch = useDispatch();

  useEffect(() => {
    const updatedContactList = _.get(marketingSolutionData, "contactList.data", []);
    const loading = _.get(marketingSolutionData, "loading", false);
    setFetching(loading);
    setContactList(updatedContactList);
  }, [marketingSolutionData]);

  useEffect(() => {
    dispatch(getContactsListRequest({ page, pageSize }))
  }, [page, dispatch]);


  const fetchContactsForPage = (currentPage) => {
    dispatch(getContactsListRequest({ currentPage, pageSize }));
  };

  return (
    <div className="marketing-wrapper">
      <div className="campaign-btn">
        <Button
          onClick={() =>
            history.push(appRoutes.emailCampaign.generatePath(companySlug))
          }
        >
          Create Campaign
        </Button>
      </div>
      <div className="tab-content">
        <Contacts
          contactList={contactList}
          setContactList={setContactList}
          page={page}
          setPage={setPage}
          count={contactListCount}
          fetchContactsForPage={fetchContactsForPage}
          isFetching={fetching}
        />
      </div>
    </div>
  );
};

export default ContactList;
