import React from "react";
import ContactBgImage from "../../../assets/images/contact-bg-img.png";
import { NavLink } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";

const ContactUsHeader = () => {
  const ContactUsIndexHeader = () => {
    return (
      <div className="contact-us-wrapper">
        <div className="col-left">
          <div className="contact-bg-img">
            <img src={ContactBgImage} alt="contact us" />
          </div>
        </div>
        <div className="col-right">
          <h3>Contact Our Team</h3>
          <div className="list-wrapper">
            <div className="list">
              Contact our sales team. Click{" "}
              <NavLink to={appRoutes.salesInquiry.path}>
                <span>here</span>
              </NavLink>
              .
            </div>
            <div className="list">
              Share Your Experience While Using employHER. Submit a Bug Report{" "}
              <NavLink to={appRoutes.feedbackForm.path}>
                <span>here</span>
              </NavLink>
              .
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <ContactUsIndexHeader />;
};

export default ContactUsHeader;
