import _ from "lodash";
import React, { useEffect } from "react";
import iconImage from "../icons/pen_icon.svg";
import { Modal, Button } from "../../common";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { updateCandidateEducationRequest } from "../../../reducers/candidate/candidate.reducer";
import Educations from "./EducationFields";
import Image from "../../common/Image";

interface IEducation {
  education: [];
  publicPage: boolean;
  educationModal: boolean;
  setEducationModal: any;
  showEditButton?: any;
}

function Education({
  education,
  publicPage,
  educationModal,
  setEducationModal,
  showEditButton,
}: IEducation) {
  const dispatch = useDispatch();
  const { handleSubmit, register, control, errors, setValue, reset, getValues } = useForm({
    defaultValues: { education },
  });

  useEffect(() => {
    reset({ education });

    // eslint-disable-next-line
  }, [education]);

  const getEducation = (formData) => {
    const payload = {
      ...formData,
    };
    setEducationModal(false);
    dispatch(updateCandidateEducationRequest(payload));
  };

  return (
    <>
      <div className="edit-wrapper">
        {publicPage && <h1>Education</h1>}
        {!_.isEmpty(education) && !publicPage && showEditButton && (
          <span onClick={() => setEducationModal(true)}>
            <Image src={iconImage} alt="pen icon" />
          </span>
        )}
      </div>
      <div
        className={`profile-view-card user-description user-description-education ${publicPage && "profile-public-card"
          }`}
      >
        <div>
          {publicPage && _.isEmpty(education) ? (
            <h2 className="bold text-center w-100">No data available</h2>
          ) : (
            _.isEmpty(education) && (
              <h2
                className="bold link-text"
                onClick={() => setEducationModal(true)}
              >
                Add Your Education
              </h2>
            )
          )}
          {education.map((educations: any, index) => (
            <div key={index} className="d-flex">
              <div className="item-1">
                <h2 className="bold">Institute name</h2>
                <p>{_.get(educations, "institute_name", "") || "-"}</p>
              </div>
              <div className="item-2">
                <h2 className="bold">Degree</h2>
                <p> {_.get(educations, "degree_title", "") || "-"} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title="Edit Education"
        visible={educationModal}
        className="profile-modal share-profile-modal editable-modal edit-size-model-education"
        onClose={() => setEducationModal(false)}
      >
        <div className="edit-modal-wrapper">
          <>
            <form onSubmit={handleSubmit(getEducation)}>
              <Educations
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
              <div className="d-flex justify-content-center">
                <Button
                  type="submit"
                  color="primary"
                  className="submit-button text-uppercase"
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  className="cancel-button text-uppercase"
                  onClick={() => setEducationModal(false)}
                >
                  cancel
                </Button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
}

export default Education;
