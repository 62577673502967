/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenterRounded";
import CloudUploadRounded from "@material-ui/icons/CloudUploadRounded";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Button } from "../../../components/common";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import UploadContact from "./upload";
import AddSubscriber from "./addSubscriber";

const AddContacts = () => {
  const history = useHistory();
  const [value, setValue] = React.useState("manually");
  const [pathName, setPathName] = useState<string>("");
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue((event.target as HTMLInputElement).value);

  const handleContinue = () => {
    if (value === "file") {
      history.push(appRoutes.uploadContact.generatePath(companySlug));
    } else {
      history.push(appRoutes.manuallyContact.generatePath(companySlug));
    }
  };

  const handleBack = () => {
    history.push(appRoutes.marketingSolution.generatePath(companySlug));
  };

  useEffect(() => {
    const path = window?.location?.pathname.split("/");
    setPathName(path[4]);
  }, []);

  return (
    <div className="add-contact-wrapper">
      {pathName === "contact-manually" && (
        <div>
          <div className="text-right">
            <Button onClick={handleBack} variant="text" color="none">
              Back
            </Button>
          </div>
          <div>
            <AddSubscriber />
          </div>
        </div>
      )}
      {pathName === "contact-upload" && <UploadContact />}
      {!pathName && (
        <>
          <h3>Add Contacts</h3>
          <FormControl className="contact-form">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <div className="radio-wrapper">
                <div className="radio-card">
                  <div className="card-head">
                    <div className="icon">
                      <div className="icon">
                        <BusinessCenterIcon />
                      </div>
                    </div>
                    <FormControlLabel
                      value="manually"
                      control={<Radio />}
                      label="Add Subscriber manually"
                      labelPlacement="start"
                      className="m-0"
                    />
                  </div>
                  <div className="card-body">
                    <p>
                      Lorem Ipsum simply dummy text printing been typesetting a
                      industry. lorem Ipsum has been industry's anstanda dummy
                      text ever since
                    </p>
                  </div>
                </div>
                <div className="radio-card">
                  <div className="card-head">
                    <CloudUploadRounded />
                    <FormControlLabel
                      value="file"
                      control={<Radio />}
                      label="Upload a file"
                      labelPlacement="start"
                      className="m-0"
                    />
                  </div>
                  <div className="card-body">
                    <p>
                      Lorem Ipsum simply dummy text printing been typesetting a
                      industry. lorem Ipsum has been industry's anstanda dummy
                      text ever since
                    </p>
                  </div>
                </div>
              </div>
            </RadioGroup>
          </FormControl>
          <Button onClick={handleContinue}>Continue</Button>
        </>
      )}
    </div>
  );
};
export default AddContacts;
