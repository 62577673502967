import React from "react";
import { Modal, Box, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";

const StatusModal = ({ open, handleClose }) => {
  const { numberOfSavedContacts, numberOfFailedContacts } = useSelector(
    ({ company }: rootReducersState) => company.companyMarketingSolution
  );
  console.log(
    numberOfSavedContacts,
    ":::numberOfSavedContacts:::",
    numberOfFailedContacts
  );
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="successful-imported-modal"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "807px",
        width: "100%",
        borderRadius: "30px",
        margin: "0 auto",
      }}
    >
      <Box
        bgcolor="white"
        boxShadow={5}
        p={4}
        borderRadius={4}
        textAlign="center"
      >
        <div className="check-icon">
          <CheckCircleIcon color="primary" style={{ fontSize: 50 }} />
        </div>
        <p>
          Successfully imported {numberOfSavedContacts} Contacts, failed{" "}
          {numberOfFailedContacts} contacts
        </p>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default StatusModal;
