import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { Button } from "../../components/common";
import {
  verifyUserEmailRequest,
  resetVerifyUserEmail,
} from "../../reducers/auth/verifyUserEmail.reducer";
import {
  requestVerifyUserEmailRequest,
  resetRequestVerifyUserEmail,
} from "../../reducers/auth/requestUserEmailVerification.reducer";
import { FlashMessage, PageTitle } from "../../components/common";
import appRoutes from "../../routes/app.routes";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { rootReducersState } from "../../reducers";
import {
  setCurrentCompanyRequest,
  setSessionTokenRequest,
  setSessionUserRequest,
} from "../../reducers/auth/session.reducer";
import Affirmation from "../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { COMPANY_FLAG } from "../../utils/appConstants";
import CodeEnter from "../../components/common/CodeEnter";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {},
    },
  })
);

type Inputs = {
  verification_code: string;
};

const VerifyUser = (props: any) => {
  console.log(props)
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const formClasses = useFormStyles();
  const dispatch = useDispatch();
const [inputValues, setInputValues] = useState(Array(4).fill(''));
const [clearFlag, setClearFlag] = useState(false);
console.log(setClearFlag)
// const[codeFlag,setCodeFlag] = useState("email");
const codeFlag="email"
  const [tokenVerifyError, setTokenVerifyError] = useState('')

  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(30); // Default to 30 seconds
  const [isFirstResend, setIsFirstResend] = useState(true);
  const verifyUserEmail = useSelector(
    ({ auth }: rootReducersState) => auth.verifyUserEmail
  );
  
  const location = useLocation();
  const { currentEmail, phone } = location.state || {};

  const errorMessage = _.get(verifyUserEmail, "errorMessage", "");
  const loadingFlag = _.get(verifyUserEmail, "loading", false);
  const verifyEmailFlag = _.get(verifyUserEmail, "flag", false);

  const requestUserEmailVerification = useSelector(
    ({ auth }: rootReducersState) => auth.requestUserEmailVerification
  );
  const userEmailVerificationLoading = _.get(
    requestUserEmailVerification,
    "loading",
    false
  );
  const userEmailVerificationMessage = _.get(
    requestUserEmailVerification,
    "message",
    null
  );
  const userEmailVerificationFlag = _.get(
    requestUserEmailVerification,
    "flag",
    null
  );
  useEffect(() => {
    if (resendDisabled) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setResendDisabled(false);
            setCountdown(isFirstResend ? 30 : 60); // Switch wait time after the first resend
            setIsFirstResend(false);
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendDisabled, isFirstResend]);
    const handleInputChange = (index, value) => {

    console.log(value.length)
    // if (value.length === 1) {
      setInputValues(prevValues => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });
    
    // }  
  };

  useEffect(() => {
    if (!inputValues.includes(''))  {
      setTokenVerifyError('')
    }
  }, [inputValues]);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenData = _.get(sessionReducer, "currentUser", {});

  // const { register, handleSubmit, errors } = useForm<Inputs>();

  const {  handleSubmit } = useForm<Inputs>();

  // Redirect user to home page is already logged in
  useEffect(() => {
    const userId = _.get(tokenData, "id", "");
    // Navigate user based on role
    if (userId) {
      // TODO - redirect to company
      navigateUser();
    }

    // eslint-disable-next-line
  }, []);

  // Hook, once user email verification done successfully
  useEffect(() => {
    if (verifyEmailFlag === true) {
      const data = _.get(verifyUserEmail, "data", {});

      const token = _.get(data, "token", null);
      const sessionData: any = _.get(data, "session", {});

      // Navigate user based on role
      const IS_COMPANY =
        _.get(data, "session.is_company") === COMPANY_FLAG.YES ? true : false;
      let companyDetails =
        sessionData?.companies?.find((row) => row.privileges === true) || null;

      // Check for first company access
      if (!companyDetails) {
        companyDetails = sessionData?.companies[0] || {};
      }

      const companySlug = companyDetails?.slug || "";

      if (IS_COMPANY && companySlug) {
        // Set the company
        dispatch(setCurrentCompanyRequest(companyDetails));
        const companyPath =
          appRoutes.companyDashboard.generatePath(companySlug);
        history.push(companyPath);
      } else {
        history.push(appRoutes.candidateProfileView.path);
      }

      // Set session data
      dispatch(setSessionTokenRequest(token));
      dispatch(setSessionUserRequest(sessionData));
      dispatch(resetVerifyUserEmail());
    }

    // eslint-disable-next-line
  }, [verifyEmailFlag]);

  // Hook, when user email verification code sent
  useEffect(() => {
    if (userEmailVerificationFlag !== null) {
      if (userEmailVerificationFlag === true) {
        FlashMessage(userEmailVerificationMessage);
      } else if (userEmailVerificationFlag === false) {
        FlashMessage(userEmailVerificationMessage, "error");
      }

      dispatch(resetRequestVerifyUserEmail());
    }

    // eslint-disable-next-line
  }, [userEmailVerificationFlag]);

  const navigateUser = () => {
    history.push(appRoutes.candidateProfileView.path);
  };

  const onSubmit = async (formData) => {
    if (!inputValues.includes('')) {
      const verification_token = _.get(props, "match.params.token", "");
      const verifyCode = inputValues.join('');
      const code =verifyCode
      //  _.get(formData, "verification_code", "");
  
      dispatch(
        verifyUserEmailRequest({
          verification_token,
          code,
          action: "verify_user",
        })
      );
    }else{
      setTokenVerifyError("Please enter 4 digit verification code");
    } 
  };


  // const _handleResendVerification = () => {
  //   dispatch(resetVerifyUserEmail());
  //   const token = _.get(props, "match.params.token", "");

  //   dispatch(requestVerifyUserEmailRequest({ token }));
  // };

  const _handleResendVerification = (flag:string) => {
    if (!resendDisabled) {
      setResendDisabled(true);
      setCountdown(isFirstResend ? 30 : 60); // Set appropriate wait time
      dispatch(resetVerifyUserEmail());
      const token = props.match?.params?.token || "";
       dispatch(requestVerifyUserEmailRequest({ token, codeFlag: flag }));
    }
  };

  return (
    <>
      {PageTitle("Verify Email")}
      <div className="auth-wrapper">
        <div className="left-part">
          <Affirmation />
        </div>
        <div className="right-part">
          <div>
            <div className="back-website">
              <div className="back">
                <Link to={appRoutes.home.path}>
                  {" "}
                  <ArrowBackIosIcon />{" "}
                </Link>
              </div>
              <Link to={appRoutes.home.path}>Back to website</Link>
            </div>
            <div
              className={`${classes.root} eh-tab auth-login-wrapper login-form-container auth-card email-verification-wrapper`}
            >
              <form
                className={`${formClasses.root} verify-form`}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="verify-code-container">
                  <h2 className="auth-card-title">{`${codeFlag==="email"?"Verify Your email":"Verify Phone Number"}`}</h2>
                
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      {/* <Input
                        name="verification_code"
                        externalLabel={{ label: "Verification code" }}
                        placeholder="Email Verification code"
                        validationObj={errors}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Please enter email verification code",
                          },
                          minLength: {
                            value: 6,
                            message: "Please enter valid verification code",
                          },
                        })}
                      /> */}
                      
                      <div className="text-center">
                        <div className="code-send-text">
                        <p >Enter the code we sent to </p>
                        {codeFlag === "email" ? (
                          <span>{`${currentEmail.current.slice(0, 3)}***@${currentEmail.current.split('@')[1]}`}</span>
                        ) : (
                          <span>{`${phone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4')}`}</span>
                        )}
                        {/* <span>{`${codeFlag === "email" ? `${currentEmail.current.slice(0, 3)}***@${currentEmail.current.split('@')[1]}` : `${phone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4')}`}`}`}</span> */}
                                            </div>
                     <CodeEnter
                      clear={clearFlag} numBoxes={4} 
                               handleOnChange={(index, e) => handleInputChange(index, e.target.value)}
                     />
                      <div className="row mt-0">
                        {userEmailVerificationLoading === true ? (
                          <span className="span-link">
                            Requesting new code...
                          </span>
                        ) : (
                          <span
                            className="span-link"
                            onClick={() => _handleResendVerification(codeFlag)}
                          >
                            {resendDisabled
                              ? `Request new code in ${countdown} seconds`
                              : "Request new verification code"}
                            {/* Request new verification code */}
                          </span>
                        )}
                      </div>
                      {errorMessage && (
                        <span className="text-sm text-danger">
                          {errorMessage}
                        </span>
                      )}
                      {tokenVerifyError && (
                        <span className="text-sm text-danger">
                          {tokenVerifyError}
                        </span>
                      )}
  <div className="row mt-0">
                        {/* {codeFlag === "email" ? (
                          <span
                          className="via-link"
                          onClick={() => {
                            setCodeFlag("sms");
                            _handleResendVerification("sms");
                          }}
                          >
                          Verify via SMS
                          </span>
                        ) : (
                          <span
                          className="via-link"
                          onClick={() => {
                            setCodeFlag("email");
                            _handleResendVerification("email");
                          }}
                          >
                          Verify via Email
                          </span>
                        )} */}
                             {/* <span
                          className="via-link"
                          onClick={() => {
                            setCodeFlag("email");
                            _handleResendVerification("email");
                          }}
                          >
                          Verify via Email
                          </span> */}
                        </div>
                      </div>
                      
                                           {/* <div className="row mt-0">
                       
                          <span
                            className="span-link"
                            onClick={() => _handleResendVerification()}
                          >
                          {`verify via ${codeFlag==="email"?"SMS":"Email"}`}
                          </span> */}
                    
                      {/* </div> */}

                     
                    </Grid>

                    <Grid item xs={12} className="text-center">
                      <div className="row text-center justify-content-center mt-2 form-group">
                        <Button
                          color="primary"
                          type="submit"
                          className="btn-group"
                          loading={loadingFlag}
                        >
                          Verify 
                        </Button>
                      </div>
                      <div className="d-block mt-10">
                        <Link
                          to={appRoutes.userLogin.path}
                          className="forgot-password"
                        >
                          {" "}
                          Back{" "}
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyUser;
