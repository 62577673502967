import React from "react";
import { Card } from "@material-ui/core";
import ReactPlayer from "react-player";
import Button from "../common/Button";
// import _ from "lodash";
interface IVideoCardDetails {
  title: string;
  description: string;
  video_file: string;
  created_at: string;
  is_primary: number;
  video_text?: string; // Add video_text as an optional prop
}

interface VideoCardProps {
  details: IVideoCardDetails;
  onDelete?: (data: any) => void;
  onEdit?: (data: any) => void;
  setAsPrimaryVideo?: (data: any) => void;
  [key: string]: any;
}

const VideoCard = ({
  details,
  onDelete,
  onEdit,
  setAsPrimaryVideo,
}: VideoCardProps) => {

  //video-text
  // const [captions, setCaptions] = useState<any[]>([]);
  // const [showCaptions, setShowCaptions] = useState<boolean>(true);
  // const [currentTime, setCurrentTime] = useState<number>(0);
  // const playerRef = useRef<ReactPlayer | null>(null);

  // useEffect(() => {
  //   if (details.video_text) {
  //     try {
  //       const parsedCaptions = JSON.parse(details.video_text);
  //       console.log('parsedCaptions', parsedCaptions);
  //       setCaptions(parsedCaptions);
  //     } catch (error) {
  //       console.error("Failed to parse video_text as JSON:", error);
  //       // Optionally, set a default value or handle the error gracefully
  //       setCaptions([]);
  //     }
  //   }
  // }, [details.video_text]);


  // const handleProgress = (progress: { playedSeconds: number }) => {
  //   setCurrentTime(progress.playedSeconds);
  // };

  // const formatCaptions = () => {
  //   return captions
  //     .filter(caption => {
  //       const startTime = parseFloat(caption.startTime || "0");
  //       const endTime = parseFloat(caption.endTime || startTime);
  //       return currentTime >= startTime && currentTime <= endTime;
  //     })
  //     .map((caption, index) => (
  //       <div key={index} className="caption">
  //         {caption.text}
  //       </div>
  //     ));
  // };

  return (
    <Card className="video-card">
      <div className="video-card-inner">
        <div className="video-img">
          <ReactPlayer
            width="100%"
            height="100%"
            url={details.video_file}
            playing={false}
            controls={true}
          // onProgress={handleProgress}
          />
          {/* {!_.isEmpty(captions) && showCaptions && (
            <div className="captions-overlay">{formatCaptions()}</div>
          )} */}
        </div>
        <div className="video-info-wrapper">
          {/* <div className="video-info">
            <h2>{details.title}</h2>
            <div>
              <span>Created on :</span>
              <p>{details.created_at}</p>
            </div>

            <Switch inputProps={{ 'aria-label': 'primary checkbox' }} />
          </div> */}
          <div className="video-info">
            <div className="video-details">
              <h2>{details.title}</h2>
              <div>
                <span>Created on :</span>
                <p>{details.created_at}</p>
              </div>
            </div>
            {/* <Switch
              checked={showCaptions}
              onChange={() => setShowCaptions(!showCaptions)}
              inputProps={{ 'aria-label': 'Toggle captions' }}
            /> */}
          </div>

          <div className="button-section">
            <div>
              <Button
                className="btn-edit"
                onClick={() => {
                  if (typeof onEdit === "function") {
                    onEdit(details);
                  }
                }}
              >
                Edit
              </Button>
              {details.is_primary !== 1 && (
                <Button
                  className="btn-delete"
                  onClick={() => {
                    if (typeof onDelete === "function") {
                      onDelete(details);
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
            {details.is_primary === 1 ? (
              <Button className="btn-small primary-video">Profile video</Button>
            ) : (
              <Button
                color="dark-pink"
                className="btn-small"
                onClick={() => {
                  if (typeof setAsPrimaryVideo === "function") {
                    setAsPrimaryVideo(details);
                  }
                }}
              >
                Set as Profile
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default VideoCard;

