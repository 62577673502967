import React, { useState } from 'react';
import Slider from 'react-slick';
import { Container, Grid } from "@material-ui/core";
import Modal from "../../../components/common/Modal";

import Paveleen from "../../../assets/images/landing_testimonial/Paveleen-Kaur.jpg";
import Maria from "../../../assets/images/landing_testimonial/Maria-Loza.jpg";
import Nafisa from "../../../assets/images/landing_testimonial/Nafisa-Shikdar.jpg";
import Sharron from "../../../assets/images/landing_testimonial/Sharron.jpg";
import Image from "../../../components/common/Image";

type ICandidateDetails = {
	name: string
	longDescription: string
	image: any
}

const defaultCandidateDetails = {
	name: "",
	longDescription: "",
	image: ""
}

const CandidateStorySlider = () => {

	const [candidateStoryCount, setCandidateStoryCount] = useState<number>(1);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [candidateDetails, setCandidateDetails] = useState<ICandidateDetails>(defaultCandidateDetails)

	const settings = {
		dots: false,
		infinite: true,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		beforeChange: (current, next) => {
			setCandidateStoryCount(next + 1);
		}
	};

	const handleSetCandidateDetails = () => {

		const data = candidatesList[candidateStoryCount - 1]

		if (Object.keys(data).length > 0) {
			setCandidateDetails({
				name: data.name,
				longDescription: data.longDescription,
				image: data.image
			})
			setShowModal(true);
		}
	}

	const handleCloseModal = () => {
		setShowModal(false);
		setCandidateDetails(defaultCandidateDetails)
	}

	const candidatesList = [
		{
			id: "pavleen_k",
			name: "Pavleen K",
			image: Paveleen,
			shortDescription: (<>
				Preet and her team at employHer offer exceptional and reliable recruiting service. Throughout the process, Preet helps through the navigation steps with her exceptional communication and motivation. She keeps the interests and career goals of the client at the centre. My journey became simpler and a great learning experience due to her genuine and mentoring relation with me. I highly recommend her.
			</>),
			longDescription: "Preet and her team at employHer offer exceptional and reliable recruiting service. Throughout the process, Preet helps through the navigation steps with her exceptional communication and motivation. She keeps the interests and career goals of the client at the centre. My journey became simpler and a great learning experience due to her genuine and mentoring relation with me. I highly recommend her."
		},
		{
			id: "nafisa_shikdar",
			name: "Nafisa Shikdar",
			image: Nafisa,
			shortDescription: (<>
				I consider myself very fortunate that I found Preet as my recruiter. I have worked with so many recruiters in the past 4 years and Preet is definitely very different from all of them. I don't want to address her as my "recruiter" cause she is definitely more than that. She is a great motivator, a career coach, a mentor, and she does not know how to give up on her candidates and brings out the best from them. I never met any recruiting agent before who is so committed to making a person succeed. She really cares about her candidates. I would definitely suggest all the recruiters out there to learn communication, to be proactive, and to be a good motivator from Preet. Because sometimes that's all a candidate wants when he/she is trying to succeed.
			</>),
			longDescription: "I consider myself very fortunate that I found Preet as my recruiter. I have worked with so many recruiters in the past 4 years and Preet is definitely very different from all of them. I don't want to address her as my \"recruiter\" cause she is definitely more than that. She is a great motivator, a career coach, a mentor, and she does not know how to give up on her candidates and brings out the best from them. I never met any recruiting agent before who is so committed to making a person succeed. She really cares about her candidates. I would definitely suggest all the recruiters out there to learn communication, to be proactive, and to be a good motivator from Preet. Because sometimes that's all a candidate wants when he/she is trying to succeed."
		},
		{
			id: "maria_loza",
			name: "Maria Loza",
			image: Maria,
			shortDescription: (<>
				Preet is just a wonderful person! I'm so glad to have meet her and have her as my recruiter. She was very supportive and a great advocate. She goes beyond to explain each step, so there was never a surprise for me when it came to the interview processes. She also gives great advice when it comes to climbing the career ladder. She will check in with you even after helping you land a job. I will always recommend her. She's direct and open during conversations. I was never afraid to reach out to her for anything.
			</>),
			longDescription: "Preet is just a wonderful person! I'm so glad to have meet her and have her as my recruiter. She was very supportive and a great advocate. She goes beyond to explain each step, so there was never a surprise for me when it came to the interview processes. She also gives great advice when it comes to climbing the career ladder. She will check in with you even after helping you land a job. I will always recommend her. She's direct and open during conversations. I was never afraid to reach out to her for anything."
		},
		{
			id: "sharron_allen",
			name: "Sharron Allen",
			image: Sharron,
			shortDescription: (<>
				Wow where do I begin. Preet is more than an agent, she truly becomes your biggest cheerleader, supporter and life coach. Regardless of whatever position I take it was an honor to have worked with her because she increased my confidence tenfold. Preet genuinely cares and despite tragedy also hitting her she still checked in to see how things were going. She’s amazing and I’d be remiss to not recommend her not only as an advocate but a friend.
			</>),
			longDescription: "Wow where do I begin. Preet is more than an agent, she truly becomes your biggest cheerleader, supporter and life coach. Regardless of whatever position I take it was an honor to have worked with her because she increased my confidence tenfold. Preet genuinely cares and despite tragedy also hitting her she still checked in to see how things were going. She’s amazing and I’d be remiss to not recommend her not only as an advocate but a friend."
		},

	]

	return (
		<>
			<Container fixed>
				<div className="landing-about-us">
					<Slider {...settings}>
						{candidatesList.map((row, index) => (
							<Grid
								item
								xs={12}
								container
								direction="row"
								justify="space-between"
								alignItems="center"
								className="candidate-slider"
								key={index}
							>
								<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
									<div className="landing-hero-text landing-about-us-text">
										<h5 className="m-0">Candidate</h5>
										<h1>success stories</h1>
										<h6 className="m-0">{row.shortDescription}</h6>
										<span className="span-link" onClick={() => handleSetCandidateDetails()}>Read more</span>
									</div>
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
									<div className="landing-testimonial-img">
										<div className="candidate-img">
											<Image src={row.image} alt="testimonial image" />
										</div>
										<div className="candidate-name">
											<h3>{row.name}</h3>
										</div>
									</div>
								</Grid>
							</Grid>
						))}
					</Slider>
				</div>
				<div className="slider-control">
					<span className="value"> {candidateStoryCount} {` / ${candidatesList.length} `} </span>
				</div>
			</Container>
			<Modal visible={showModal} onClose={() => handleCloseModal()} className="landing-candidate-testimonial-modal">
				<div className="candidate-img">
					<Image src={candidateDetails.image} alt="testimonial image" />
					<h6>{candidateDetails.name}</h6>
				</div>
				<p> {candidateDetails.longDescription} </p>
			</Modal>
		</>
	)
}

export default CandidateStorySlider;
