import React from "react";
import { rootReducersState } from "../../../reducers";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, Grid, Button } from "@material-ui/core";
import gridImageOne from "../../../../assets/images/marketing_solution/1.png";
import gridImageTwo from "../../../../assets/images/marketing_solution/2.png";
import gridImageThree from "../../../../assets/images/marketing_solution/3.png";
import gridImageFour from "../../../../assets/images/marketing_solution/4.png";
import appRoutes from "../../../../routes/app.routes";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles({
  contentwrapper: {
    maxWidth: "1516px",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    height: "100%",
    display: "flex",
    padding: "37px 0px",
    "@media only screen and (max-width: 1199px)": {
      padding: "25px",
      maxWidth: "100%",
      width: "auto",
    },
  },
  infoWrapper: {
    padding: "100px 50px",
    "@media only screen and (max-width: 1199px)": {
      padding: "50px 25px 50px 0",
    },
    "@media only screen and (max-width: 959px)": {
      padding: "50px 0",
    },
    "@media only screen and (max-width: 767px)": {
      padding: "0 0 25px",
    },
  },
  sectionTitle: {
    color: "#31222A",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginBottom: "30px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "20px !important",
      marginBottom: "20px",
    },
  },
  sectionDescription: {
    color: "#666",
    fontSize: "18px",
    lineHeight: "30px",
    maxWidth: "516px",
    width: "100%",
    "@media only screen and (max-width: 767px)": {
      fontSize: "16px !important",
      lineHeight: "1.5",
    },
  },
  continueButton: {
    borderRadius: "4px !important",
    border: "1px solid #FF0990 !important",
    background: "#FF0990",
    "&:hover": {
      backgroundColor: "#FF0990",
    },
    padding: "12px 20px",
    color: "#fff",
    fontSize: "16px",
    "@media only screen and (max-width: 767px)": {
      padding: "6px 8px",
      fontSize: "14px",
    },
  },
  continueButtonWrapper: {
    marginRight: "15px",
    marginTop: "20px",
    "& .MuiButtonBase-root": {
      borderRadius: "4px !important",
    },
  },
  imageCollageWrapper: {
    maxWidth: "600px",
    width: "100%",
    margin: "0 auto",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e2dede",
    border: "1px solid #D2CDCD",
    borderRadius: "8px",
    padding: "40px 40px",
    "@media only screen and (max-width: 1199px)": {
      marginLeft: "0",
    },

    "@media only screen and (max-width: 991px)": {
      padding: "20px",
    },
  },
  imageBox: {
    maxWidth: "100%",
    flexBasis: "50%",
  },
});

const CRMCampaign = () => {
  const classes = useStyles();
  const history = useHistory();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  return (
    <div className={classes.contentwrapper}>
      <Grid container>
        <Grid item md={6} className={classes.infoWrapper}>
          <h2 className={classes.sectionTitle}>
            Create beautiful emails and landing pages
          </h2>
          <p className={classes.sectionDescription}>
            Craft visually stunning emails and landing pages effortlessly with
            our innovative online tool. Simplify the design process with
            drag-and-drop features, making it easy to create beautiful and
            engaging email templates in just a few clicks.
          </p>
          <div className={classes.continueButtonWrapper}>
            <Button
              onClick={() =>
                history.push(
                  appRoutes.startCampaign.generatePath(companySlug, uuidv4())
                )
              }
              className={classes.continueButton}
            >
              Get started
            </Button>
          </div>
        </Grid>
        <Grid
          item
          container
          md={6}
          spacing={1}
          className={classes.imageCollageWrapper}
        >
          <Grid item xs={4} spacing={6} className={classes.imageBox}>
            <img src={gridImageOne} alt="laptop" style={{ width: "100%" }} />
            <img src={gridImageThree} alt="hands" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={4} spacing={6} className={classes.imageBox}>
            <img src={gridImageTwo} alt="work" style={{ width: "100%" }} />
            <img src={gridImageFour} alt="group" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CRMCampaign;
