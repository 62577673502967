import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { formatDate } from "../../../utils/helper";
import Rating from "./contactRating";
import { FlashMessage, Modal } from "../../../components/common";
import DeleteIcon from "../../../assets/svg/delete-icon.svg";
import EditIcon from "../../../assets/svg/edit-admin-icon.svg";
import marketingSolutionServices from "../../../services/marketingSolution.services";
import AddSubscriber from "./addSubscriber";
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
    border: "1px solid #EBEBEB",
    borderRadius: "10px 10px 0px 0px",
    background: "#FBFBFB",
  },
  table: {
    minWidth: "800px",
    whiteSpace: "nowrap",
  },
  columnStyle: {
    color: "#31222A",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.053px",
  },
  emailColumn: {
    color: "#31A0FA",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.3px",
  },
  dataColumns: {
    color: "#31222A",
    fontFamily: "Work Sans",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    letterSpacing: "0.3px",
  },
  customPagination: {
    "& .MuiPagination-root": {
      display: "flex",
      justifyContent: "center",
      padding: "10px 0px",
      "& .Mui-selected": {
        backgroundColor: "#FF0990",
        color: "#ffffff",
      },
    },
  },
  actionWrapper: {
    display: "grid",
    gridTemplateColumns: "20px 20px",
    gap: "8px",
    alignItems: "center",
    "& img": {
      cursor: "pointer",
    },
  },
});

const Contacts = ({
  contactList,
  setContactList,
  page,
  setPage,
  count,
  fetchContactsForPage,
  isFetching
}) => {
  const classes = useStyles();
  const [editModal, setEditModal] = useState(false);
  const [editContact, setEditContact] = useState({});

  const renderTags = (tags) => {
    if (Array.isArray(tags)) {
      return tags[0]?.tag_name;
    }
    return tags;
  };
  const handlePageChange = (event, pageNumber) => {
    setPage(pageNumber);
    fetchContactsForPage(pageNumber);
  };

  const handleRemoveClick = async (id: string) => {
    const response = await marketingSolutionServices.removeContact(id);
    if (response.flag) {
      setContactList((prevContactList) =>
        prevContactList.filter((contact) => contact.id !== id)
      );
      FlashMessage(response.message);
    } else {
      console.error(response.errors); // Log detailed errors if needed
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnStyle}>Email</TableCell>
              <TableCell className={classes.columnStyle}>Name</TableCell>
              <TableCell className={classes.columnStyle}>Address</TableCell>
              <TableCell className={classes.columnStyle}>
                Phone Number
              </TableCell>
              <TableCell className={classes.columnStyle}>Tags</TableCell>
              <TableCell className={classes.columnStyle}>
                Email Marketing
              </TableCell>
              <TableCell className={classes.columnStyle}>Birthday</TableCell>
              <TableCell className={classes.columnStyle}>Created By</TableCell>
              <TableCell className={classes.columnStyle}>
                Contact Rating
              </TableCell>
              <TableCell className={classes.columnStyle}>Created At</TableCell>
              <TableCell className={classes.columnStyle}>
                Last Changed
              </TableCell>
              <TableCell className={classes.columnStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                  <CircularProgress color="secondary" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {contactList.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell className={classes.emailColumn}>
                      {contact.email}
                    </TableCell>
                    <TableCell
                      className={classes.dataColumns}
                    >{`${contact.first_name} ${contact.last_name}`}</TableCell>
                    <TableCell className={classes.dataColumns}>
                      {contact.address_1}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      {contact.phone_number}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      <Tooltip
                        title={contact.tags.map((tag) => tag.tag_name).join(", ")}
                        placement="top"
                      >
                        <span>{renderTags(contact.tags)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      {contact.email_permission ? "Subscribed" : "Unsubscribed"}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      {contact.date_of_birth}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      {contact.creator.first_name + " " + contact.creator.last_name}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      <Rating value={contact.contact_rating} />
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      {formatDate(contact.created_at)}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      {formatDate(contact.updated_at)}
                    </TableCell>
                    <TableCell className={classes.dataColumns}>
                      <div className={classes.actionWrapper}>
                        <img
                          src={DeleteIcon}
                          alt="delete Icon"
                          onClick={() => handleRemoveClick(contact.id)}
                        />
                        <img
                          src={EditIcon}
                          alt="edit"
                          onClick={() => {
                            // history.push(appRoutes.manuallyContact.generatePath(companySlug))
                            setEditModal(true);
                            setEditContact(contact);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}

          </TableBody>
        </Table>
        <div className={classes.customPagination}>
          <Pagination count={count} page={page} onChange={handlePageChange} />
        </div>
      </TableContainer>

      <Modal
        size="width-1000"
        onClose={() => {
          setEditContact({});
          setEditModal(false);
        }}
        visible={editModal}
        title="Edit contact"
      >
        <AddSubscriber existingContact={editContact} setModal={setEditModal} setEditContact={setEditContact} setContactList={setContactList} />
      </Modal>
    </>
  );
};

export default Contacts;
