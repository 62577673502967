import React, { useState, useRef, useEffect } from "react";
import httpRequest from "../../../utils/httpRequest";
import { useSocket } from "../../../components/common/SocketIo";
import _ from "lodash";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
const UploadVideoWithCover = () => {
  const coverInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const [coverImage, setCoverImage] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const socket = useSocket();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const userId = _.get(sessionReducer, "currentUser.id", 0);
  // Handle cover image selection
  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.type.startsWith("image/")) {
      alert("Please select a valid image file for cover.");
      return;
    }
    setCoverImage(file);
  };

  // Handle video file selection
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // Validate file type for mp4 or mov
    if (!file.type.includes("mp4") && !file.type.includes("mov")) {
      alert("Please select a valid video file (mp4 or mov).");
      return;
    }
    setVideoFile(file);
  };

  // Save video and cover image to backend
  const handleSave = async () => {
    if (!coverImage || !videoFile) {
      alert("Please select both a cover image and a video file.");
      return;
    }

    const formData = new FormData();
    // Append the cover image and video file using the keys expected by your backend
    formData.append("cover_image", coverImage, coverImage.name);
    formData.append("files", videoFile, videoFile.name);
    // Append any additional fields
    formData.append("isWechat", false);
    formData.append("title", "My Video");
    formData.append("description", "Uploaded via React app");

    try {
      setIsSaving(true);
      await httpRequest().post(        `/video-studio/app/upload/profile`,        formData     );
      alert("Upload successful!");
      // Reset state and file inputs
      setCoverImage(null);
      setVideoFile(null);
      if (coverInputRef.current) coverInputRef.current.value = "";
      if (videoInputRef.current) videoInputRef.current.value = "";
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Error uploading files");
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
     // Log when the socket connects
    socket.on("connect", () => {
      console.log("✅ Connected to Socket.IO server");
    });
  
     // Join the video room
    const videoRoom = `${userId}_video_room`;
    socket.emit("createVideoRoom", videoRoom);
  // Log the date and time with milliseconds when receiving the upload progress event
  socket.on("profileVideoUploadProgress", (data) => {
    const now = new Date();
    const formattedTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}.${now.getMilliseconds()}`;
    console.log(`🔥 Received uploadProgress Event at ${formattedTime}:`, data);
    setProgress(data.progress);
    if (data.progress === 100) {
    setProgress(100);
    setIsSaving(false);
    setMessage("Upload successful!");
    }
  });
    // Listen for progress updates
    // socket.on("profileVideoUploadProgress", (data) => {
    //   console.log("🔥 Received uploadProgress Event:", data);
    //   // if (data.userId === userId) {
    //     setProgress(data.progress);
    //     if (data.progress === 100) {
    //       setProgress(100);
    //       setIsSaving(false);
    //       setMessage("Upload successful!");
    //     }
    //   // }
    // });
  
    return () => {
      console.log("🛑 Removing WebSocket listeners...");
      // socket.off("profileVideoUploadProgress");
    };
  }, [socket, userId]);

//   useEffect(() => {
//     if (!socket) {
//         console.log("❌ WebSocket instance is null");
        
//         return;

//     }
 
// if(socket){
//   socket.emit("createVideoRoom", `${userId}_video_room`);

// }
//     socket.on("profileVideoUploadProgress", (data) => {
//         console.log("🔥 Received uploadProgress Event:", data);
//         if (data.userId === userId) {
//             setProgress(data.progress);
//             if(data.progress===100){
//               setProgress(100);
//               setIsSaving(false);
//               setMessage("Upload successful!");
//             }
//         }
//     });

//     return () => {
//         console.log("🛑 Removing WebSocket listeners...");
//         // socket.off("uploadProgress");
//         // socket.off("uploadComplete");
//         // socket.off("uploadError");
//     };
// }, [socket,userId]);


  return (
    <div style={{ padding: "20px" }}>
      <h2>Upload Video with Cover Image</h2>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Cover Image:
          <input
            type="file"
            accept="image/*"
            ref={coverInputRef}
            onChange={handleCoverChange}
          />
        </label>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Video File:
          <input
            type="file"
            accept="video/mp4,video/mov"
            ref={videoInputRef}
            onChange={handleVideoChange}
          />
        </label>
      </div>
      <button onClick={handleSave} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save Video"}
      </button>
           {/* ✅ Progress Bar */}
  {progress > 0 && progress < 100 && (
    <>
        <div style={{ marginTop: "10px", width: "100%", height: "10px", backgroundColor: "#ccc" }}>
        <div
          style={{
            width: `${progress}%`,
            height: "100%",
            backgroundColor: progress === 100 ? "green" : "blue",
            transition: "width 0.3s ease-in-out",
          }}
        ></div>
      </div>
      {message && <p style={{ marginTop: "10px", color: progress === 100 ? "green" : "blue" }}>{message}</p>}
  </>
    )}
    </div>
  );
};

export default UploadVideoWithCover;
