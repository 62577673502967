import _ from "lodash";
import React, { useEffect } from "react";
import iconImage from "../icons/pen_icon.svg";
import { Modal, Button } from "../../common";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Image from "../../common/Image";
import CareerDifferentiatorsWork from "./WorkExperinceFields";
import { updateCandidateWorkExperienceRequest } from "../../../reducers/candidate/candidate.reducer";

interface IWorkExperiences {
  experiences: [];
  publicPage: boolean;
  workModal: boolean;
  setWorkModal: any;
  showEditButton?: any;
}

function WorkExperience({
  experiences,
  publicPage,
  workModal,
  setWorkModal,
  showEditButton,
}: IWorkExperiences) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    register,
    errors,
    watch,
    setValue,
    setError,
    reset,
    getValues
  } = useForm({
    defaultValues: { experiences },
  });

  useEffect(() => {
    reset({ experiences });

    // eslint-disable-next-line
  }, [experiences]);

  const getWorkExperience = (formData) => {
    formData?.experiences.map((item) => {
      if (item.job_title_custom) {
        const jobCustomTitle = item.job_title_custom;
        if (item?.job_title_custom?.__isNew__) {
          return (item["jobTitle"] = {
            label: jobCustomTitle?.label
              ? jobCustomTitle.label
              : jobCustomTitle,
            value: jobCustomTitle?.value
              ? jobCustomTitle.value
              : jobCustomTitle,
            __isNew__: true,
          });
        } else {
          return (item["jobTitle"] = {
            label: jobCustomTitle?.label
              ? jobCustomTitle.label
              : jobCustomTitle,
            value: jobCustomTitle?.value
              ? jobCustomTitle.value
              : jobCustomTitle,
          });
        }
      } else {
        const jobTitle = item.jobTitle;
        return (item["jobTitle"] = {
          label: jobTitle?.label ? jobTitle.label : jobTitle,
          value: jobTitle?.value ? jobTitle.value : jobTitle,
        });
      }
    });

    const payload = {
      ...formData,
    };
    setWorkModal(false);
    dispatch(updateCandidateWorkExperienceRequest(payload));
  };

  return (
    <>
      <div className="edit-wrapper">
        {publicPage && <h1>Work Experience</h1>}
        {!_.isEmpty(experiences) && !publicPage && showEditButton && (
          <span onClick={() => setWorkModal(true)}>
            <Image src={iconImage} alt="pen icon" />
          </span>
        )}
      </div>
      <div
        className={`profile-view-card user-description user-description-work ${publicPage && "profile-public-card"
          }`}
      >
        {_.isEmpty(experiences) && publicPage ? (
          <h2 className="bold text-center">No data available</h2>
        ) : (
          _.isEmpty(experiences) && (
            <h2 className="bold link-text" onClick={() => setWorkModal(true)}>
              Add Your Work Experience
            </h2>
          )
        )}
        {experiences.map((experience, index) => (
          <div key={index}>
            <h2 className="bold">
              {_.get(experience, "job_title_custom", "") === "" ||
                _.get(experience, "job_title_custom", "") === null
                ? _.get(experience, "jobTitle.label", "")
                : _.get(experience, "job_title_custom", "")}
            </h2>
            <p
              className="mb-0 wysiwyg-data"
              dangerouslySetInnerHTML={{
                __html: _.get(experience, "description", "") || "-",
              }}
            />
          </div>
        ))}
      </div>
      <Modal
        title="Edit Work Experience"
        visible={workModal}
        className="profile-modal edit-size-model-work share-profile-modal editable-modal"
        onClose={() => setWorkModal(false)}
      >
        <div className="edit-modal-wrapper">
          <form onSubmit={handleSubmit(getWorkExperience)}>
            <CareerDifferentiatorsWork
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              watch={watch}
              setError={setError}
              getValues={getValues}
            />
            <div className="d-flex justify-content-center mt-30">
              <Button
                type="submit"
                color="primary"
                className="submit-button text-uppercase"
              >
                Save
              </Button>
              <Button
                variant="text"
                color="secondary"
                className="cancel-button text-uppercase"
                onClick={() => setWorkModal(false)}
              >
                cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default WorkExperience;
