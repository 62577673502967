import React, { useRef } from "react";
import EmailEditor from "react-email-editor";
import marketingSolutionServices from "../../../../services/marketingSolution.services";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../../routes/app.routes";
import { rootReducersState } from "../../../reducers";
import { useSelector } from "react-redux";
import Button from "../../../../components/common/Button";

const ViewTemplate = () => {
  const emailEditorRef = useRef(null);
  const history = useHistory();
  const desing_json = history?.location?.state?.design_json;
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    if (desing_json) {
      emailEditorRef.current.editor.loadDesign(desing_json);
    }
  };

  const onSave = () => {
    try {
      if (emailEditorRef && emailEditorRef.current) {
        emailEditorRef.current.editor.exportHtml(async (data) => {
          const { design, html } = data;
          const res = await marketingSolutionServices.saveEmailTemplate({
            design,
            html,
          });
          if (res.flag) {
            history.push(appRoutes.emailTemplates.generatePath(companySlug));
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };
  return (
    <div>
      <div>
        <div className="mb-16">
          <Button onClick={onSave}>Save</Button>
        </div>

        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
          minHeight="85vh"
        />
      </div>
    </div>
  );
};

export default ViewTemplate;
