import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import _ from "lodash";
import {
  postLikeRequest,
  postLikeSuccess,
  postLikeFailed,
  getFeedListRequest,
  getFeedListSuccess,
  getFeedListFailed,
  feedPostRequest,
  feedPostSuccess,
  feedPostFailed,
  getFeedDetailsRequest,
  getFeedDetailsSuccess,
  getFeedDetailsFailed,
  editFeedRequest,
  editFeedSuccess,
  editFeedFailed,
  deleteFeedSuccess,
  deleteFeedRequest,
  deleteFeedFailed,
  commentPostRequest,
  commentEditRequest,
  commentPostSuccess,
  commentEditSuccess,
  commentPostFailed,
  getMoreCommentsRequest,
  getMoreCommentsSuccess,
  getMoreCommentsFailed,
  deleteCommentSuccess,
  deleteCommentRequest,
  deleteCommentFailed,
  getRepliesRequest,
  getRepliesSuccess,
  getRepliesFailed,
  commentLikeRequest,
  commentLikeSuccess,
  commentLikeFailed,
  getHashTagPostsSuccess,
  getHashTagPostsFailed,
  getHashTagPostsRequest,
} from "../reducers/feeds/feedPost.reducer";
import feedPage from "../services/feedPage.services";
interface payloadInterface {
  type: String;
  payload: any;
}

function* createFeedPost({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.createPost, payload);
  try {
    if (response.flag === true) {
      yield put(
        feedPostSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(feedPostFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(feedPostFailed({ message: _.get(error, "message", "") }));
  }
}

export function* createFeedPostWatcher() {
  yield takeLatest(feedPostRequest.type, createFeedPost);
}

function* editFeedPost({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.editPost, payload);
  try {
    if (response.flag === true) {
      yield put(
        editFeedSuccess({
          message: _.get(response, "message", ""),
          data: payload.data || {},
        })
      );
    } else {
      yield put(editFeedFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(editFeedFailed({ message: _.get(error, "message", "") }));
  }
}

export function* editFeedPostWatcher() {
  yield takeLatest(editFeedRequest.type, editFeedPost);
}

function* getFeedList({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.getList, payload);
  try {
    if (response.flag === true) {
      yield put(
        getFeedListSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(getFeedListFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(getFeedListFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getFeedListWatcher() {
  yield takeLatest(getFeedListRequest.type, getFeedList);
}

// calling like api

function* feedPostLike({ payload }: payloadInterface) {
  const response = yield* call(feedPage.postLike, payload);
  try {
    if (response.flag === true) {
      yield put(
        postLikeSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data.data", {}),
        })
      );
    } else {
      yield put(postLikeFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(postLikeFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getFeedLikeWatcher() {
  yield takeLatest(postLikeRequest.type, feedPostLike);
}

// calling feed details/ single post API

function* getFeedDetails({ payload }: payloadInterface) {
  const response = yield* call(feedPage.feedDetails, payload);
  try {
    if (response.flag === true) {
      yield put(
        getFeedDetailsSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(
        getFeedDetailsFailed({ message: _.get(response, "message", "") })
      );
    }
  } catch (error) {
    yield put(getFeedDetailsFailed({ message: _.get(error, "message", "") }));
  }
}

export function* feedDetailWatcher() {
  yield takeLatest(getFeedDetailsRequest.type, getFeedDetails);
}

// delete feed post

function* feedPostDelete({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.deleteFeed, payload);

  try {
    if (response.flag === true) {
      yield put(deleteFeedSuccess({ response: response }));
    } else {
      yield put(
        deleteFeedFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(deleteFeedFailed({ message: _.get(error, "message", "") }));
  }
}

export function* peopleDeleteWatcher() {
  yield takeLatest(deleteFeedRequest.type, feedPostDelete);
}

// post comment

function* createFeedComment({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.commentPost, payload.formData);
  try {
    if (response.flag === true) {
      yield put(
        commentPostSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(commentPostFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(commentPostFailed({ message: _.get(error, "message", "") }));
  }
}

export function* createCommentPostWatcher() {
  yield takeLatest(commentPostRequest.type, createFeedComment);
}

// edit comment

function* editFeedComment({ payload }: payloadInterface) {
  const { id, formData } = payload;
  // calling the API
  const response = yield* call(feedPage.editComment, id, formData);
  try {
    if (response.flag === true) {
      yield put(
        commentEditSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(commentPostFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(commentPostFailed({ message: _.get(error, "message", "") }));
  }
}

export function* editCommentPostWatcher() {
  yield takeLatest(commentEditRequest.type, editFeedComment);
}

// get more comments

function* getMoreComments({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.getCommentList, payload);
  try {
    if (response.flag === true) {
      yield put(
        getMoreCommentsSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(
        getMoreCommentsFailed({ message: _.get(response, "message", "") })
      );
    }
  } catch (error) {
    yield put(getMoreCommentsFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getCommentListWatcher() {
  yield takeLatest(getMoreCommentsRequest.type, getMoreComments);
}

// delete comment

function* commentDelete({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.deleteComment, payload);
  try {
    if (response.flag === true) {
      yield put(deleteCommentSuccess({ response: response, payload: payload }));
    } else {
      yield put(
        deleteCommentFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(deleteCommentFailed({ message: _.get(error, "message", "") }));
  }
}

export function* commentDeleteWatcher() {
  yield takeLatest(deleteCommentRequest.type, commentDelete);
}

// get replies

function* getReplies({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(feedPage.getReplyList, payload);
  try {
    if (response.flag === true) {
      yield put(
        getRepliesSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(getRepliesFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(getRepliesFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getReplyListWatcher() {
  yield takeLatest(getRepliesRequest.type, getReplies);
}

// calling comment like api

function* feedCommentLike({ payload }: payloadInterface) {
  const response = yield* call(feedPage.commentLike, payload);
  try {
    if (response.flag === true) {
      yield put(
        commentLikeSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data.data", {}),
        })
      );
    } else {
      yield put(commentLikeFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(commentLikeFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getFeedCommentLikeWatcher() {
  yield takeLatest(commentLikeRequest.type, feedCommentLike);
}

// calling hashtag posts
function* hashTagPosts({ payload }: payloadInterface) {
  const response = yield* call(feedPage.hashTagPosts, payload);
  try {
    if (response.flag === true) {
      yield put(
        getHashTagPostsSuccess({
          message: _.get(response, "message", ""),
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(
        getHashTagPostsFailed({ message: _.get(response, "message", "") })
      );
    }
  } catch (error) {
    yield put(getHashTagPostsFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getHashTagPostWatched() {
  yield takeLatest(getHashTagPostsRequest.type, hashTagPosts);
}
