import _ from "lodash";
import React, { useEffect } from "react";
import iconImage from "../icons/pen_icon.svg";
import { Modal, Button } from "../../common";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { updateCandidateProjectsRequest } from "../../../reducers/candidate/candidate.reducer";
import CareerDifferentiatorsWork from "./ProjectsFields";
import Image from "../../common/Image";

interface IProjects {
  projects: [];
  publicPage: boolean;
  projectModal: boolean;
  showEditButton?: any;
  setProjectModal: any;
}

function Projects({
  projects,
  publicPage,
  projectModal,
  setProjectModal,
  showEditButton,
}: IProjects) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    register,
    errors,
    setValue,
    watch,
    setError,
    reset,
    getValues
  } = useForm({
    defaultValues: { projects },
  });

  useEffect(() => {
    reset({ projects });

    // eslint-disable-next-line
  }, [projects]);

  const getProjects = (formData) => {
    const payload = {
      ...formData,
    };
    setProjectModal(false);
    dispatch(updateCandidateProjectsRequest(payload));
  };

  return (
    <>
      <div className="edit-wrapper">
        {publicPage && <h1>Projects</h1>}
        {!_.isEmpty(projects) && !publicPage && showEditButton && (
          <span onClick={() => setProjectModal(true)}>
            <Image src={iconImage} alt="pen icon" />
          </span>
        )}
      </div>
      <div
        className={`profile-view-card user-description user-description-work ${publicPage && "profile-public-card"
          }`}
      >
        {publicPage && _.isEmpty(projects) ? (
          <h2 className="bold text-center">No data available</h2>
        ) : (
          _.isEmpty(projects) && (
            <h2
              className="bold link-text"
              onClick={() => setProjectModal(true)}
            >
              Add Your Projects
            </h2>
          )
        )}
        {projects.map((project, index) => (
          <div key={index}>
            <h2 className="bold">
              {_.get(project, "title", "")} | {_.get(project, "link", "")}
            </h2>
            <p
              className="mb-0 wysiwyg-data"
              dangerouslySetInnerHTML={{
                __html: _.get(project, "description", "") || "-",
              }}
            />
          </div>
        ))}
      </div>
      <Modal
        title="Edit Projects"
        visible={projectModal}
        className="profile-modal share-profile-modal edit-size-model-projects editable-modal"
        onClose={() => setProjectModal(false)}
      >
        <div className="edit-modal-wrapper">
          <form onSubmit={handleSubmit(getProjects)}>
            <CareerDifferentiatorsWork
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              setError={setError}
              watch={watch}
              getValues={getValues}
            />
            <div className="d-flex justify-content-center mt-30">
              <Button
                type="submit"
                color="primary"
                className="submit-button text-uppercase"
              >
                Save
              </Button>
              <Button
                variant="text"
                color="secondary"
                className="cancel-button text-uppercase"
                onClick={() => setProjectModal(false)}
              >
                cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Projects;
