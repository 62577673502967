import React, { useEffect } from "react";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Input } from "../../components/common";
import {
  forgotPasswordRequest,
  resetForgotPassword,
  resetForgotPasswordMessages,
} from "../../reducers/auth/forgotPassword.reducer";
import { FlashMessage, MessageHelper } from "../../components/common";
import appRoutes from "../../routes/app.routes";
import { ReactComponent as PersonIcon } from "../../assets/svg/person.svg";
import { Helmet } from "react-helmet";
import { rootReducersState } from "../../reducers";
import { InputAdornment } from "@material-ui/core";
import Affirmation from "../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

//captcha
// import httpRequest from "../../utils/httpRequest";
// import API_URLS from "../../utils/apiUrls";
// import { useLocation } from "react-router-dom";
// import AutoModeIcon from '@material-ui/icons/Loop';
const _ = { get };

type Inputs = {
  email: string;
};

const ForgotPassword = (props: any) => {
  // Hooks
  const history = useHistory();
  const dispatch = useDispatch();

  const forgotPassword = useSelector(
    ({ auth }: rootReducersState) => auth.forgotPassword
  );
  const successMessage = _.get(forgotPassword, "successMessage", "");
  const errorMessage = _.get(forgotPassword, "errorMessage", "");
  const loadingFlag = _.get(forgotPassword, "loading", false);
  const forgotPasswordFlag = _.get(forgotPassword, "flag", false);
  // const [captchaImage, setCaptchaImage] = useState("");
  // const [captchaAnswer, setCaptchaAnswer] = useState();
  // const location = useLocation();
  // const path = location.pathname
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenData = _.get(sessionReducer, "currentUser", {});

  const { register, handleSubmit, watch, errors } = useForm<Inputs>();

  const watchEmail = watch("email"); // Watch email change to reset the success or error message

  // Did mount effect
  useEffect(() => {
    // Check if user is already logged in
    const userRole = _.get(tokenData, "role", "");
    if (userRole) {
      history.push("/");
    }

    // Did unmounted, reset the store
    return () => {
      dispatch(resetForgotPassword());
    };

    // eslint-disable-next-line
  }, []);

  // Clear the error or success message only if feasible
  useEffect(() => {
    if (errorMessage || successMessage) {
      dispatch(resetForgotPasswordMessages());
    }

    // eslint-disable-next-line
  }, [watchEmail]);

  // Hook, once user forgot password sent successfully
  useEffect(() => {
    if (forgotPasswordFlag === true) {
      FlashMessage(successMessage);
      dispatch(resetForgotPassword());
      history.push(appRoutes.userLogin.path);
    }

    // eslint-disable-next-line
  }, [forgotPasswordFlag]);

  // useEffect(() => {
  //   if (path === '/forgot-password') {
  //     createCaptcha();
  //   }
  // }, [path])

  const onSubmit = async (formData) => {

    // const captcha = formData.captcha_answer
    // if (captcha === captchaAnswer) {
    //   dispatch(
    //     forgotPasswordRequest({
    //       email: _.get(formData, "email", ""),
    //       token: captcha,
    //       action: "forgot_password",
    //     })
    //   );
    // }

    dispatch(
      forgotPasswordRequest({
        email: _.get(formData, "email", ""),
        // token: captcha,
        action: "forgot_password",
      })
    );
  };


  // const createCaptcha = async () => {
  //   const data = await httpRequest().post(API_URLS.captcha);
  //   const svgImage = data.data.data.data
  //   const responseCaptchaAnswer = data.data.data.text
  //   setCaptchaAnswer(responseCaptchaAnswer)
  //   setCaptchaImage(svgImage)
  // }

  return (
    <>
      <Helmet>
        <title>employHER | Forgot Password</title>
      </Helmet>
      <div className="auth-wrapper">
        <div className="left-part">
          <Affirmation />
        </div>
        <div className="right-part">
          <div>
            <div className="back-website">
              <div className="back">
                <Link to={appRoutes.userLogin.path}>
                  {" "}
                  <ArrowBackIosIcon />{" "}
                </Link>
              </div>
              <Link to={appRoutes.userLogin.path}>Back to website</Link>
            </div>
            <div className="auth-card auth-login-wrapper forgot-password-wrapper">
              <h2 className="auth-card-title">Forgot Password</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <p className="form-group-label">Email</p>
                  <Input
                    name="email"
                    placeholder="Email"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter email address",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Enter valid email address",
                      },
                    })}
                  />
                  {/* <div className="captcha-view">
                    <div dangerouslySetInnerHTML={{
                      __html: captchaImage,
                    }} />

                    <Input
                      name="captcha_answer"
                      placeholder="Enter Captcha Answer"
                      validationObj={errors}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AutoModeIcon className="captcha-icon" onClick={() => createCaptcha()} />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter captcha answer",
                        },
                        pattern: {
                          value: /[0-9]+/,
                          message: "Enter valid captcha answer",
                        },
                        validate: (value) =>
                          value === captchaAnswer ||
                          "The verify captcha do not match",
                      })}
                    />
                  </div> */}
                </div>

                <div className="form-group btn-group">
                  {errorMessage && (
                    <MessageHelper type="error" text={errorMessage} />
                  )}
                  <Button color="primary" type="submit" loading={loadingFlag}>
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
