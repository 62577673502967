import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  submitFeedbackRequest,
  resetSubmitFeedback,
} from "../../../reducers/contactUs/contactUs.reducer";
import {
  SelectNew,
  Input,
  Radio,
  Button,
  SingleFileDropZone,
} from "../../../components/common";
import _ from "lodash";
import { scrollToTop } from "../../../utils/helper";
import contactUsServices from "../../../services/contactUs.services";
import feedbackInfoImg from "../../../assets/images/feedback-info.png";
import feedbackInfoImgMobile from "../../../assets/images/sales-info-mobile.png";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";
import ThankyouFeedback from "./ThankyouFeedback";

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, setError, register, reset } =
    useForm();

  const [followUpFlag, setFollowUp] = useState<string>("0");
  const [feedbackFile, setFeedbackFile] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>(feedbackInfoImg);

  const history = useHistory();

  const contactUsReducer = useSelector(({ contactUs }: any) => contactUs);
  const submitLoading = _.get(contactUsReducer, "submitLoading", false);
  const errorList = _.get(contactUsReducer, "errors", {});
  const submitFlag = _.get(contactUsReducer, "submitFlag", null);

  // Submit response
  useEffect(() => {
    if (submitFlag === true) {
      reset();
      setFeedbackSubmitted(true);
      dispatch(resetSubmitFeedback());
      scrollToTop();
    } else if (submitFlag === false) {
      // Set error message from API
      if (Object.keys(errorList).length > 0) {
        Object.entries(errorList).forEach(([key, value]: any) => {
          setError(key, { type: "manual", message: value });
        });
      }
    }

    // eslint-disable-next-line
  }, [submitFlag]);

  const onSubmit = (formData) => {
    let file: object = {};

    if (feedbackFile) {
      file = { file: feedbackFile };
    }

    dispatch(
      submitFeedbackRequest({
        type: "feedback",
        description: formData.description,
        email_address: formData.email_address,
        followup_flag: followUpFlag,
        user_type: formData.user_type.value,
        ...file,
      })
    );
  };

  const fileUploadSuccess = async (file) => {
    let payload = new FormData();
    payload.append("files", file);
    const fileUpload = await contactUsServices.feedbackFileUpload(payload);
    setFeedbackFile(_.get(fileUpload, "data.encFileName", null));
  };

  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 991) {
        setImageSrc(feedbackInfoImgMobile);
      } else {
        setImageSrc(feedbackInfoImg);
      }
    };

    updateImage(); // Initial check
    window.addEventListener("resize", updateImage); // Listen for window resize

    return () => window.removeEventListener("resize", updateImage); // Cleanup
  }, []);

  return (
    <>
      {!feedbackSubmitted ? (
        <div className="main-inquiry-container">
          <div className="left-side">
            <img src={imageSrc} alt="sales-info" />
          </div>
          <div className="right-side">
            <h2>Contact Our Team</h2>
            <form
              className="contact-us-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-wrapper">
                <div className="form-group form-info">
                  <label htmlFor="info">How are we doing?</label>
                  <div className="info-card">
                    <p>
                      We're constantly striving to enhance the employ
                      <span>HER</span> experience, and we’d love your feedback
                      on what’s working well and how we can improve for our
                      users.
                    </p>
                    <p>
                      Let us know by submitting a Bug Report Form. <br />
                      Your input helps us serve you better!
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <SelectNew
                    className="contact-us-dropdown"
                    name="user_type"
                    options={[
                      { label: "I'm a Candidate", value: "candidate" },
                      { label: "I'm an Employer", value: "company" },
                    ]}
                    externalLabel={{ label: "Tell us about your self" }}
                    placeholder="I'm using employHER as"
                    control={control}
                    validationObj={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select type",
                      },
                    }}
                  />
                </div>
                <div className="form-group">
                  <Input
                    multiline={true}
                    name="description"
                    className="feedback-description"
                    externalLabel={{
                      label: "We’d Love to Hear Your Feedback",
                    }}
                    placeholder="Share your experience with us. What went well? What could have gone better?"
                    validationObj={errors}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter description",
                      },
                      minLength: {
                        value: 10,
                        message:
                          "Description must be between 10 to 1000 characters long",
                      },
                      maxLength: {
                        value: 1000,
                        message:
                          "Description must be between 10 to 1000 characters long",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label className="custom-label">
                    Upload your image <span>(PNG, JPG files are allowed)</span>
                  </label>
                  <div className="custom-input-type-file">
                    <SingleFileDropZone
                      allowFileTypes={["png", "jpg", "jpeg"]}
                      allowFileSize={1048576}
                      onSuccess={(file) => fileUploadSuccess(file)}
                    />
                  </div>
                </div>
                <div className="form-group custom-radio">
                  <label className="custom-label">May we follow up?</label>
                  <Radio
                    name="followup_flag"
                    radioPlacement="block"
                    className="feedback-followup"
                    labelPlacement="end"
                    validationObj={errors}
                    onChange={(e) =>
                      setFollowUp(e?.currentTarget?.value || "0")
                    }
                    control={control}
                    options={[
                      { label: "Yes, please reach out to me", value: "1" },
                      { label: "No, thank you", value: "0" },
                    ]}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please select option",
                      },
                    })}
                  />
                </div>
                {followUpFlag === "1" && (
                  <div className="form-group">
                    <Input
                      name="email_address"
                      placeholder="Email Address"
                      validationObj={errors}
                      externalLabel={{ label: "Email Address" }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter email address",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Enter valid email address",
                        },
                      })}
                    />
                  </div>
                )}

                <div className="form-actions">
                  <Button type="submit" color="primary" loading={submitLoading}>
                    Submit Feedback
                  </Button>
                  <Button
                    color="secondary"
                    disabled={submitLoading}
                    onClick={() => history.push(appRoutes.contactUs.path)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ThankyouFeedback />
      )}
    </>
  );
};

export default FeedbackForm;
