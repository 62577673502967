import React, { useState } from "react";
import List from "./components/list";
import AddContacts from "./components/addContacts";
import { Button } from "@material-ui/core";
import CampaignList from "./components/campaign/campaignList";

const MarketingSolution = () => {
  const [checkTab, setCheckTab] = useState("addContacts");

  const handleButtonClick = (type: string) => {
    switch (type) {
      case "manageContacts":
        setCheckTab(type);
        break;
        case "manageCampaign":
          setCheckTab(type);
          break;
      case "addContacts":
        setCheckTab(type);
        break;
      case "subscriberPreference":
        setCheckTab(type);
        break;
      default:
        break;
    }
  };

  return (
    <div className="marketing-wrapper">
        <div>
          <div className="custom-tab-wrapper">
            <div>
              <ul className="tab-menu">
                <li>
                  <Button
                    className={`${checkTab === "addContacts" ? "active" : ""}`}
                    onClick={() => handleButtonClick("addContacts")}
                  >
                    {" "}
                    Add Contacts{" "}
                  </Button>
                </li>
                <li>
                  <Button
                    className={`${checkTab === "manageCampaign" ? "active" : ""}`}
                    onClick={() => handleButtonClick("manageCampaign")}
                  >
                    Manage Campaign
                  </Button>
                </li>
                <li>
                    <Button
                      className={`${checkTab === "manageContacts" ? "active" : ""}`}
                      onClick={() => handleButtonClick("manageContacts")}
                    >
                      {" "}
                      Manage Contacts{" "}
                    </Button>
                  </li>
                {/* <li>
                    <Button
                      className={`${checkTab === "subscriberPreference" ? "active" : ""}`}
                      onClick={() => handleButtonClick("subscriberPreference")}
                    >
                      {" "}
                      Subscriber Preference {" "}
                    </Button>
                  </li> */}
              </ul>
            </div>
          </div>
          {checkTab === "manageContacts" && (
            <div className="page-jobs">
              <div className="jobs-wrapper">
                <div className="job-content">
                  <div className="job-list">
                    <List />
                  </div>
                </div>
              </div>
            </div>
          )}
          {checkTab === "addContacts" && <AddContacts />}
          {checkTab === "manageCampaign" && (
            <div className="page-jobs">
              <div className="jobs-wrapper">
                <div className="job-content">
                  <div className="job-list">
                    <CampaignList  />
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default MarketingSolution;
