
import { createSlice, current } from "@reduxjs/toolkit";
import { get, isArray, isEmpty, isNumber } from "lodash";
import { ReducerFlag } from "../../types/reducer.types";
const _ = { get, isEmpty, isNumber };
interface IState {
  [key: string]: any;
}

// Initial state for reducer
const initialState: IState = {
  loading: false,
  loadingList: false,
  postLoading: false,
  postCreateFlag: ReducerFlag.INIT,
  flag: false,
  deleteFlag: false,
  uploadLoading: false,
  deleteLoading: false,
  data: [],
  replyData: [],
  postLikeLoading: false,
  commentLikeLoading: false,
  postLikeFlag: ReducerFlag.INIT,
  commentLikeFlag: ReducerFlag.INIT,
  feedListFlag: ReducerFlag.INIT,
  updateFlag: ReducerFlag.INIT,
  feedDetailsLoading: false,
  postDetailFlag: ReducerFlag.INIT,
  postErrorMessage: "",
  message: "",
  commentCreateFlag: ReducerFlag.INIT,
  replyCreateFlag: ReducerFlag.INIT,
  commentListFlag: ReducerFlag.INIT,
  loadingCommentList: false,
  deleteCommentFlag: ReducerFlag.INIT,
  deleteCommentLoading: false,
  commentLoading: false,
  commentReplyFlag: false,
  postReplyLoading: false,
  replyListFlag: ReducerFlag.INIT,
  replyList: [],
  replyEditFlag: ReducerFlag.INIT,
  replyDeleteFlag: ReducerFlag.INIT,
  commentErrorMessage: "",
  likeErrorMessage: "",
  hashtagFlag: ReducerFlag.INIT,
  hashtagData: [],
  isHashtagPage: false,
  pagination: {
    page: 1,
  },
  newCommentLoading: false,
  newCommentId: null,
  newReplyLoading: false,
  newReplyId: null
};

const slice = createSlice({
  name: "postFeed",
  initialState: initialState,
  reducers: {
    feedPostRequest: (state, payload) => {
      return {
        ...state,
        loading: true,
        postLoading: true,
        postCreateFlag: ReducerFlag.INIT,
      };
    },
    feedPostSuccess: (state, { payload }) => {
      const postData = payload?.data?.data || {};
      return {
        ...state,
        loading: false,
        postLoading: false,
        flag: false,
        postCreateFlag: ReducerFlag.SUCCESS,
        data: [{ ...postData }, ...state.data],
      };
    },
    feedPostFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        postLoading: false,
        flag: false,
        postErrorMessage: _.get(payload, "message", ""),
        postCreateFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    resetFeedPostFlagRequest: (state) => {
      return {
        ...state,
        postCreateFlag: ReducerFlag.INIT,
        message: "",
      };
    },

    getFeedListRequest: (state, payload) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
        loadingList: true,
      };
    },
    getFeedListSuccess: (state, { payload }) => {
      const oldData = state.data || [];
      const currentData = _.get(payload, "data.data", []);
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.SUCCESS,
        data: [...oldData, ...currentData] || [],
        detail: _.get(payload, "data", {}),
      };
    },
    getFeedListFailed: (state, { payload }) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    resetFeedList: (state) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.INIT,
        data: [],
        detail: {},
        message: "",
        errors: "",
      };
    },
    feedListFlagReset: (state) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
      };
    },

    updateFeedListData: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        hashtagData: payload
      };
    },

    postLikeRequest: (state, payload) => {
      return {
        ...state,
        postLikeFlag: ReducerFlag.INIT,
        postLikeLoading: true,
      };
    },
    postLikeSuccess: (state, { payload }) => {
      return {
        ...state,
        postLikeLoading: false,
        postLikeFlag: ReducerFlag.SUCCESS,
        likedData: _.get(payload, "data", {}),
      };
    },
    postLikeFailed: (state, { payload }) => {
      return {
        ...state,
        postLikeLoading: false,
        postLikeFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    postLikeReset: (state) => {
      return {
        ...state,
        postLikeFlag: ReducerFlag.INIT,
      };
    },

    getFeedDetailsRequest: (state, payload) => {
      return {
        ...state,
        feedDetailsLoading: true,
        postDetailFlag: ReducerFlag.INIT,
      };
    },
    getFeedDetailsSuccess: (state, { payload }) => {
      return {
        ...state,
        feedDetailsLoading: false,
        postDetailFlag: ReducerFlag.SUCCESS,
        flag: false,
        detail: _.get(payload, "data.data", {}),
      };
    },
    getFeedDetailsFailed: (state, { payload }) => {
      return {
        ...state,
        feedDetailsLoading: false,
        postDetailFlag: ReducerFlag.FAILED,
        flag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updateFeedDetailsRequest: (state, { payload }) => {
      return {
        ...state,
        detail: payload,
      };
    },

    // edit feed post

    editFeedRequest: (state, payload) => {
      return {
        ...state,
        editLoading: true,
        updateFlag: ReducerFlag.INIT,
      };
    },
    editFeedSuccess: (state, { payload }) => {
      const { data } = payload;
      let postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      const updatedData = postData[targetData].map((item: any) => {
        if (item.id === data?.id) {
          item = data;
        }
        return item;
      });
      return {
        ...state,
        editLoading: false,
        flag: false,
        updateFlag: ReducerFlag.SUCCESS,
        data: updatedData,
        hashtagData: updatedData,
      };
    },
    editFeedFailed: (state, { payload }) => {
      return {
        ...state,
        editLoading: false,
        updateFlag: ReducerFlag.FAILED,
        updateMessage: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updatePostFlagReset: (state) => {
      return {
        ...state,
        updateFlag: ReducerFlag.INIT,
      };
    },

    deleteFeedRequest: (state, { payload }) => {
      return {
        ...state,
        deleteflag: ReducerFlag.INIT,
        deleteLoading: true,
      };
    },
    deleteFeedSuccess: (state, { payload }) => {
      const _data = state.data.filter(
        (item: any) => item.id !== payload?.response?.data?.post_id
      );
      return {
        ...state,
        deleteLoading: false,
        deleteflag: ReducerFlag.SUCCESS,
        data: [..._data],
        deleteMessage: _.get(payload, "message", ""),
        deletePostType:payload?.response?.deletePostType
      };
    },
    deleteFeedFailed: (state, { payload }) => {
      return {
        ...state,
        deleteLoading: false,
        deleteflag: ReducerFlag.FAILED,
        deleteMessage: _.get(payload, "message", ""),
        deleteErrors: _.get(payload, "errors", {}),
      };
    },
    deletePostFlagReset: (state) => {
      return {
        ...state,
        deleteflag: ReducerFlag.INIT,
      };
    },

    commentPostRequest: (state, { payload }) => {
      const { optimisticComment, postId } = payload;
      const targetData = state.isHashtagPage ? "hashtagData" : "data";

      // Map through posts to find the matching post
      const updatedPosts = state[targetData].map((post) => {
        // If it's not the post we need, leave it unchanged
        if (post.id !== postId) return post;

        // **Case 1: Top-Level Comment** (no parent_id)
        if (!optimisticComment.parent_id) {
          return {
            ...post,
            post_comments: [optimisticComment, ...(post.post_comments || [])],
            post_comments_count: (post.post_comments_count || 0) + 1
          };
        }

        // **Case 2: Reply to an Existing Comment** (has parent_id)
        const updatedComments = (post.post_comments || []).map((comment) => {
          if (comment.id === optimisticComment.parent_id) {
            return {
              ...comment,
              reply_comments: [
                optimisticComment,
                ...(comment.reply_comments || [])
              ],
              // If you track number of replies at comment level:
              reply_count: (comment.reply_count || 0) + 1
            };
          }
          return comment;
        });

        return {
          ...post,
          post_comments: updatedComments
        };
      });

      return {
        ...state,
        [targetData]: updatedPosts,
        newCommentLoading: optimisticComment.parent_id ? false : true,           // Show spinner for new comment/reply
        newCommentId: optimisticComment.parent_id ? null : optimisticComment.id,
        newReplyLoading: optimisticComment.parent_id ? true : false,           // Show spinner for new comment/reply
        newReplyId: optimisticComment.parent_id ? optimisticComment.id : null, // Track newly added comment/reply ID
        replyCreateFlag: ReducerFlag.INIT,
        commentCreateFlag: ReducerFlag.INIT,

      };
    },

    commentEditRequest: (state, payload) => {
      return {
        ...state,
        loading: true,
        commentLoading: true,
        commentCreateFlag: ReducerFlag.INIT,
        replyEditFlag: ReducerFlag.INIT,
      };
    },


    // commentPostSuccess: (state, { payload }) => {
    //   let newComment = _.get(payload, "data.data", {});
    //   let postData = { ...current(state) };
    //   const isHashTagPage = _.get(postData, "isHashtagPage", false);
    //   const isReply = !!newComment.parent_id;
    //   const tempId = isReply ? postData.newReplyId : postData.newCommentId;
    //   let targetData = isHashTagPage ? "hashtagData" : "data";
    //   if (newComment?.parent_id) {
    //     postData.replyCreateFlag = ReducerFlag.SUCCESS;
    //     postData.commentLoading = false;
    //   } else {
    //     // eslint-disable-next-line array-callback-return
    //     let _posts = postData[targetData].map((item: any) => {
    //       // comparing comment id and post id
    //       let _post = Object.assign({}, item);
    //       try {
    //         if (_post.id === newComment?.post_id) {

    //           _post.post_comments = [newComment, ...item?.post_comments];
    //         }
    //       } catch (error) {
    //         // code
    //       }
    //       return _post;
    //     });

    //     let replyList = postData.replyList;
    //     let emptyReply = {
    //       count: 1,
    //       rows: [],
    //       comment_id: newComment.id,
    //       post_id: newComment.post_id,
    //     };

    //     postData.replyList = [...replyList, ...[emptyReply]];
    //     postData.commentCreateFlag = ReducerFlag.SUCCESS;
    //     postData.commentLoading = false;
    //     postData.newCommentId = null;
    //     postData.newCommentLoading = false;
    //     postData.data = _posts;
    //     postData.hashtagData = _posts
    //   }
    //   return {
    //     ...state,
    //     ...postData,
    //     replyData: newComment?.parent_id ? newComment : {},
    //   };
    // },


    commentPostSuccess: (state, { payload }) => {
      let newComment = _.get(payload, "data.data", {});
      let postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      const targetData = isHashTagPage ? "hashtagData" : "data";

      // Distinguish REPLY vs. TOP-LEVEL
      const isReply = !!newComment?.parent_id;

      // The temporary ID used for optimistic UI
      const tempId = isReply ? postData.newReplyId : postData.newCommentId;

      // Clear loading flags
      postData.commentLoading = false;
      postData.newCommentLoading = false;
      // Reset whichever ID we used
      if (isReply) {
        postData.newReplyId = null;
        postData.replyCreateFlag = ReducerFlag.SUCCESS;
      } else {
        postData.newCommentId = null;
        postData.commentCreateFlag = ReducerFlag.SUCCESS;
      }


      if (isReply) {
        let updatedPosts = postData[targetData].map((postItem) => {
          if (postItem.id !== newComment.post_id) {
            return postItem;
          }

          const updatedComments = (postItem.post_comments || []).map((comment) => {
            if (comment.id !== newComment.parent_id) return comment;

            const cleanedReplies = (comment.reply_comments || []).filter(
              (r) => r.id !== tempId
            );

            return {
              ...comment,
              reply_comments: [newComment, ...cleanedReplies],
              // If you maintain a 'reply_count' at the comment level, you might increment it:
              reply_count: (comment.reply_count || 0) + 1,
            };
          });

          return {
            ...postItem,
            post_comments: updatedComments,
          };
        });

        postData[targetData] = updatedPosts;

      } else {
        let updatedPosts = postData[targetData].map((postItem) => {
          if (postItem.id === newComment.post_id) {
            let filtered = (postItem.post_comments || []).filter(
              (c) => c.id !== tempId
            );
            return {
              ...postItem,
              post_comments: [newComment, ...filtered],
            };
          }
          return postItem;
        });

        let replyList = postData.replyList || [];
        let emptyReply = {
          count: 1,
          rows: [],
          comment_id: newComment.id,
          post_id: newComment.post_id,
        };
        postData.replyList = [...replyList, emptyReply];

        postData[targetData] = updatedPosts;
        postData.data = updatedPosts;
        postData.hashtagData = updatedPosts;
      }

      return {
        ...state,
        ...postData,
        replyData: isReply ? newComment : {},
      };
    },


    // commentPostSuccess: (state, { payload }) => {
    //   const newComment = _.get(payload, "data.data", {});
    //   const postData = { ...current(state) };
    //   const isHashTagPage = _.get(postData, "isHashtagPage", false);
    //   const targetData = isHashTagPage ? "hashtagData" : "data";

    //   // Is it a reply or top-level?
    //   const isReply = !!newComment.parent_id;

    //   // Clean up loading flags
    //   postData.newCommentLoading = false;
    //   postData.newReplyLoading = false;
    //   const tempId = isReply ? postData.newReplyId : postData.newCommentId;
    //   postData.newReplyId = null;
    //   postData.newCommentId = null;

    //   if (isReply) {
    //     postData.replyCreateFlag = ReducerFlag.SUCCESS;
    //   } else {
    //     postData.commentCreateFlag = ReducerFlag.SUCCESS;
    //   }

    //   // Update the relevant post
    //   const updatedPosts = postData[targetData].map((post) => {
    //     if (post.id !== newComment.post_id) return post;

    //     // 1) Remove the placeholder (temp comment) from post_comments or reply_comments
    //     const filteredComments = (post.post_comments || []).map((comment) => {
    //       // If it's a top-level comment, we filter it out below
    //       if (isReply) {
    //         // Remove placeholder from reply_comments
    //         return {
    //           ...comment,
    //           reply_comments: (comment.reply_comments || []).filter(
    //             (r) => r.id !== tempId
    //           ),
    //         };
    //       }
    //       return comment;
    //     }).filter((c) => c.id !== tempId);

    //     // 2) Insert the real server comment
    //     if (!isReply) {
    //       // top-level
    //       return {
    //         ...post,
    //         post_comments: [newComment, ...filteredComments],
    //       };
    //     } else {
    //       // reply
    //       const updatedComments = filteredComments.map((comment) => {
    //         if (comment.id === newComment.parent_id) {
    //           return {
    //             ...comment,
    //             reply_comments: [newComment, ...(comment.reply_comments || [])],
    //           };
    //         }
    //         return comment;
    //       });
    //       return {
    //         ...post,
    //         post_comments: updatedComments,
    //       };
    //     }
    //   });

    //   postData[targetData] = updatedPosts;

    //   return {
    //     ...state,
    //     ...postData,
    //     replyData: isReply ? newComment : {},
    //   };
    // },

    commentEditSuccess: (state, { payload }) => {
      const newComment = _.get(payload, "data.data", {});
      const postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      if (newComment?.parent_id) {
        postData.replyEditFlag = ReducerFlag.SUCCESS;
      } else {
        // eslint-disable-next-line array-callback-return
        let _posts = postData[targetData].map((item: any) => {
          // comparing comment id and post id
          let _post = Object.assign({}, item);
          try {
            if (_post.id === newComment?.post_id) {
              let comments = _post.post_comments;
              _post.post_comments = comments.map((itm: any) => {
                if (itm.id === newComment?.id) {
                  return {
                    ...itm,
                    updated_at: newComment.updated_at,
                    comment_text: newComment.comment_text,
                    comment_type: newComment.comment_type,
                  };
                } else {
                  return itm;
                }
              });
            }
          } catch (error) {
            // code
          }
          return _post;
        });
        postData.data = _posts;
        postData.hashtagData = _posts
      }
      postData.commentLoading = false;
      return {
        ...state,
        ...postData,
        replyData: newComment?.parent_id ? newComment : {},
      };
    },
    commentPostFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        commentLoading: false,
        commentErrorMessage: _.get(payload, "message", ""),
        commentCreateFlag: ReducerFlag.FAILED,
        errors: _.get(payload, "errors", {}),
      };
    },

    commentsCreateFlagReset: (state) => {
      return {
        commentCreateFlag: ReducerFlag.INIT,
        ...state,
      };
    },

    // get more comments

    getMoreCommentsRequest: (state, payload) => {
      return {
        ...state,
        commentListFlag: ReducerFlag.INIT,
        loadingCommentList: true,
      };
    },
    getMoreCommentsSuccess: (state, { payload }) => {
      const moreComments = _.get(payload, "data.data.rows", []);
      let postData = { ...current(state) };
      let oldReplyList = _.get(postData, "replyList", []);
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      let newReplyList: {
        count: any;
        rows: any;
        comment_id: any;
        post_id: any;
      }[] = [];
      // eslint-disable-next-line array-callback-return
      let _posts = postData[targetData].map((item: any) => {
        let _post = Object.assign({}, item);
        try {
          if (_post.id === moreComments?.[0].post_id) {
            _post.post_comments = [...item.post_comments, ...moreComments];
          }
        } catch (error) {
          // code
        }
        return _post;
      });
      postData.loadingCommentList = false;
      postData.commentListFlag = ReducerFlag.SUCCESS;
      postData.data = _posts;
      postData.hashtagData = _posts;
      moreComments.forEach((comment: any) => {
        let replies = _.get(comment, "reply_comments", []);
        newReplyList.push({
          count: replies.length,
          rows: replies,
          comment_id: _.get(comment, "id", "").toString(),
          post_id: _.get(comment, "post_id", ""),
        });
      });
      const finalList = [...oldReplyList, ...newReplyList];
      return {
        ...state,
        ...postData,
        replyList: finalList,
      };
    },
    getMoreCommentsFailed: (state, { payload }) => {
      return {
        ...state,
        loadingCommentList: false,
        commentListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    commentsFlagReset: (state) => {
      return {
        commentListFlag: ReducerFlag.INIT,
        ...state,
      };
    },

    // delete comment

    deleteCommentRequest: (state, { payload }) => {
      return {
        ...state,
        deleteCommentFlag: ReducerFlag.INIT,
        replyDeleteFlag: ReducerFlag.INIT,
        deleteCommentLoading: true,
      };
    },
    // deleteCommentSuccess: (state, { payload }) => {
    //   const response = _.get(payload, "response", {});
    //   const loadingData = _.get(payload, "response.data", []);
    //   const PayloadData = _.get(payload, "payload", {});
    //   const postData = { ...current(state) };
    //   const isHashTagPage = _.get(postData, "isHashtagPage", false);
    //   let targetData = isHashTagPage ? "hashtagData" : "data";
    //   if (PayloadData?.parentId) {
    //     postData.replyDeleteFlag = ReducerFlag.SUCCESS;
    //     postData.deleteCommentLoading = false;
    //   } else {
    //     // eslint-disable-next-line array-callback-return
    //     let _posts = postData[targetData].map((item: any) => {
    //       let currentComments = Object.assign({}, item);
    //       try {
    //         if (item.id === PayloadData.postId) {
    //           currentComments.post_comments_count =
    //             item.post_comments_count - 1;
    //           const _loadingData = isArray(loadingData) ? loadingData : [];
    //           currentComments.post_comments = [
    //             ..._loadingData,
    //             ...item.post_comments.filter((comment: any) => {
    //               return comment.id !== PayloadData.id;
    //             }),
    //           ];
    //         }
    //       } catch (error) {
    //         // code
    //       }
    //       return currentComments;
    //     });
    //     postData.data = _posts;
    //     postData.hashtagData = _posts;
    //     postData.deleteCommentLoading = false;
    //     postData.deleteCommentFlag = ReducerFlag.SUCCESS;
    //   }
    //   return {
    //     ...state,
    //     ...postData,
    //     replyData: PayloadData?.parentId ? PayloadData : {},
    //     deleteCommentMessage: _.get(response, "message", ""),
    //   };
    // },
    deleteCommentSuccess: (state, { payload }) => {
      const response = _.get(payload, "response", {});
      const loadingData = _.get(payload, "response.data", []);
      const PayloadData = _.get(payload, "payload", {});
      const postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      if (PayloadData?.parentId) {
        postData[targetData] = postData[targetData].map((postItem) => {
          if (postItem.id === PayloadData.postId) {
            const updatedComments = (postItem.post_comments || []).map((comment) => {
              if (comment.id === parseInt(PayloadData.parentId)) {
                // 3) Decrement the parent's `reply_count`
                let newReplyCount = (comment.reply_count || 0) - 1;

                let updatedReplyComments = (comment.reply_comments || []).filter(
                  (r) => r.id !== PayloadData.id
                );

                return {
                  ...comment,
                  reply_count: newReplyCount < 0 ? 0 : newReplyCount, // Avoid going negative
                  reply_comments: updatedReplyComments
                };
              }
              return comment;
            });

            return {
              ...postItem,
              post_comments: updatedComments
            };
          }
          return postItem;
        });

        postData.replyDeleteFlag = ReducerFlag.SUCCESS;
        postData.deleteCommentLoading = false;
      } else {
        // eslint-disable-next-line array-callback-return
        let _posts = postData[targetData].map((postItem: any) => {
          let currentComments = { ...postItem };
          try {
            if (postItem.id === PayloadData.postId) {
              // Decrement post_comments_count for top-level comment only
              currentComments.post_comments_count = postItem.post_comments_count - 1;
              const _loadingData = isArray(loadingData) ? loadingData : [];
              currentComments.post_comments = [
                ..._loadingData,
                ...postItem.post_comments.filter(
                  (comment: any) => comment.id !== PayloadData.id
                ),
              ];
            }
          } catch (error) {
            // code
          }
          return currentComments;
        });
        postData.data = _posts;
        postData.hashtagData = _posts;
        postData.deleteCommentLoading = false;
        postData.deleteCommentFlag = ReducerFlag.SUCCESS;
      }

      return {
        ...state,
        ...postData,
        // If reply was deleted, store that payload in replyData for any needed cleanup
        replyData: PayloadData?.parentId ? PayloadData : {},
        deleteCommentMessage: _.get(response, "message", ""),
      };
    },

    deleteCommentFailed: (state, { payload }) => {
      return {
        ...state,
        deleteCommentLoading: false,
        deleteCommentFlag: ReducerFlag.FAILED,
        deleteCommentMessage: _.get(payload, "message", ""),
        deleteErrors: _.get(payload, "errors", {}),
      };
    },
    deleteCommentFlagReset: (state) => {
      return {
        ...state,
        deleteCommentFlag: ReducerFlag.INIT,
      };
    },

    // get replies

    getRepliesRequest: (state, payload) => {
      return {
        ...state,
        replyListFlag: ReducerFlag.INIT,
      };
    },
    getRepliesSuccess: (state, { payload }) => {
      const replies = _.get(payload, "data.data", []);
      return {
        ...state,
        replyData: replies,
        replyListFlag: ReducerFlag.SUCCESS,
      };
    },
    getRepliesFailed: (state, { payload }) => {
      return {
        ...state,
        loadingCommentList: false,
        commentListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    getRepliesReset: (state) => {
      return {
        commentListFlag: ReducerFlag.INIT,
        ...state,
      };
    },
    changeReplyData: (state, { payload }) => {
      return {
        ...state,
        replyList: payload,
      };
    },
    commentLikeRequest: (state, payload) => {
      return {
        ...state,
        commentLikeFlag: ReducerFlag.INIT,
        commentLikeLoading: true,
      };
    },
    commentLikeSuccess: (state, { payload }) => {
      return {
        ...state,
        commentLikeLoading: false,
        commentLikeFlag: ReducerFlag.SUCCESS,
        commentLikedData: _.get(payload, "data", {}),
      };
    },
    commentLikeFailed: (state, { payload }) => {
      return {
        ...state,
        commentLikeLoading: false,
        commentLikeFlag: ReducerFlag.FAILED,
        likeErrorMessage: _.get(payload, "message", ""),
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    commentLikeReset: (state) => {
      return {
        ...state,
        commentLikeFlag: ReducerFlag.INIT,
      };
    },

    getHashTagPostsRequest: (state, payload) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
        loadingList: true,
      };
    },

    getHashTagPostsSuccess: (state, { payload }) => {
      const oldData = state.hashtagData || [];
      const currentData = _.get(payload, "data.data", []);
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.SUCCESS,
        hashtagData: [...oldData, ...currentData] || [],
        hashtagPostsDetail: _.get(payload, "data", {}),
      };
    },

    getHashTagPostsFailed: (state, { payload }) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    resetHashTagPosts: (state) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.INIT,
        message: "",
        errors: "",
      };
    },

    hashtagPostFlagReset: (state) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
      };
    },

    handleIsHashtagPage: (state) => {
      return {
        ...state,
        isHashtagPage: true,
      };
    },

  },
});

// Actions
export const {
  getFeedListRequest,
  getFeedListSuccess,
  getFeedListFailed,
  editFeedRequest,
  editFeedSuccess,
  editFeedFailed,
  resetFeedList,
  feedListFlagReset,
  updateFeedListData,
  feedPostRequest,
  feedPostSuccess,
  feedPostFailed,
  resetFeedPostFlagRequest,
  postLikeRequest,
  postLikeSuccess,
  postLikeFailed,
  postLikeReset,
  getFeedDetailsRequest,
  getFeedDetailsSuccess,
  getFeedDetailsFailed,
  updateFeedDetailsRequest,
  deleteFeedSuccess,
  deleteFeedRequest,
  deleteFeedFailed,
  deletePostFlagReset,
  updatePostFlagReset,
  commentPostRequest,
  commentEditRequest,
  commentPostSuccess,
  commentEditSuccess,
  commentPostFailed,
  commentsCreateFlagReset,
  getMoreCommentsRequest,
  getMoreCommentsSuccess,
  getMoreCommentsFailed,
  commentsFlagReset,
  deleteCommentSuccess,
  deleteCommentRequest,
  deleteCommentFailed,
  deleteCommentFlagReset,
  getRepliesRequest,
  getRepliesSuccess,
  getRepliesFailed,
  getRepliesReset,
  changeReplyData,
  commentLikeRequest,
  commentLikeSuccess,
  commentLikeFailed,
  commentLikeReset,
  getHashTagPostsRequest,
  getHashTagPostsSuccess,
  getHashTagPostsFailed,
  resetHashTagPosts,
  hashtagPostFlagReset,
  handleIsHashtagPage
} = slice.actions;

// Reducers
export default slice.reducer;



