import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import schedulerServices from "../../../services/scheduler.services.ts";
import {
  Button,
  FlashMessage,
  Input,
  SelectNew,
} from "../../../components/common";
import _ from "lodash";
import AccessTime from "@material-ui/icons/AccessTime";
import Description from "@material-ui/icons/Description";
import Public from "@material-ui/icons/Public";
import Calendar from "react-calendar";
import { TIME_ZONE_LIST } from "../../../utils/appConstants";
import { useForm } from "react-hook-form";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import CalendarToday from "@material-ui/icons/CalendarToday";
import HourglassBottom from "@material-ui/icons/HourglassEmptyTwoTone";

import ChipInput from "material-ui-chip-input";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import prevArrow from "../../../assets/svg/arrow-left.svg";
import nextArrow from "../../../assets/svg/arrow-right.svg";
import 'moment-timezone'
import Avatar from "../../../components/common/AvtarImage.tsx";
import httpRequest from "../../../utils/httpRequest.ts";
import API_URLS from "../../../utils/apiUrls.ts";
import Image from "../../../components/common/Image.tsx";
import noCalender from "../../../assets/svg/calendar-not-found.svg";
import { CircularProgress } from "@material-ui/core";



const Schedule = () => {
  // let timeZone;
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    mode: "onBlur"
  });

  const { search } = useLocation();
  const history = useHistory();
  const params = useParams();
  const query = new URLSearchParams(search);
  const { link } = params;

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  const [schedule, setSchedule] = useState<Record<string, any>>({});
  const [workingHour, setWorkingHour] = useState<Array<object>>([]);
  const [slots, setSlots] = useState<Array<string>>([]);
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [guest, setGuest] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [userEventTimeZone, setUserEventTimeZone] = useState<string>('');
  const [guests, setGuests] = useState([]);
  const [timeZone, setTimeZone] = useState<any>({});
const [isFetching,setIsFetching]=useState(false);
const [loadSlot,setLoadSlot]=useState(true);

  const userData = sessionReducer.currentUser;
  useEffect(() => {
    fetchEventTypes();
    const currentDate = moment(Date()).format("YYYY-MM-DD");
    history.push(`${link}?date=${currentDate}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  ;
  useEffect(() => {
    prepareSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, timeZone])

  const prepareSlots = async () => {
    setLoadSlot(true);
        if (selectedDate && timeZone?.value) {
          console.log(selectedDate,"inside prepare")
      const currentDate = moment(selectedDate).format("l");
      history.push(`${link}?date=${currentDate}`);
      setSelectedSlot("");
      if (workingHour.length > 0) {
        const payload = {
          selectedDate:  moment(selectedDate).format('YYYY-MM-DD'),
          timeZone: timeZone?.value,
          assignedSlotId: schedule.id,
          slug: link || ''
        }
     
        const data = await httpRequest().post(`${API_URLS.generateSlots}`, payload);
        setSlots(data.data.slots)
      }
    }
    setLoadSlot(false);
  };

  const fetchEventTypes = async () => {
    const slug = link || "";
    setIsFetching(true);
    try {
      const result = await schedulerServices.getEventTypeBySlug(slug);
      if (result.flag) {
        setSchedule(result.data);
        await fetchWorkingHours(_.get(result, "data.user_id", ""));
      } else {
        FlashMessage(result.message, "error");
      }
      setIsFetching(false);
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
    setIsFetching(false);
  };

  const fetchWorkingHours = async (id) => {
   
    try {
      const result = await schedulerServices.getWorkingHours(id);
      const time = result.data.timeZone
      const finalTimeZone = TIME_ZONE_LIST.find(row => row.value === time)
      if (finalTimeZone?.value) {
        setValue("location_id", finalTimeZone)
        setUserEventTimeZone(finalTimeZone.value)
        setTimeZone(finalTimeZone);
      }
      if (result.flag) {
        if (result.data.workingHours) {
          setWorkingHour(result.data.workingHours);
          prepareSlots();

        } else {
          FlashMessage("Working hours are not selected", "error");
        }
      } else {
        FlashMessage(result.message, "error");
      }
    
    } catch (error) {
      
      FlashMessage(error?.message, "error");
    }
  };


  // useEffect(() => {
  // }, [workingHour]);

  const cardClicked = (e) => {
    let startTime = moment(e, "HH:mm");
    let to_time = startTime.add(_.get(schedule, "duration", ""), "minutes");
    setGuest(true);
    setTimeZone(timeZone);
    const currentDate = moment(selectedDate).format("l");
    history.push(
      `${link}?date=${currentDate}&time=${e}To${to_time.format("HH:mm")}`
    );
    if (Object.keys(userData).length > 0) {
      const name =
        _.get(userData, "first_name", "") +
        " " +
        _.get(userData, "last_name", "");
      setTimeout(() => {
        setValue("email", _.get(userData, "email", ""));
        setValue("name", name);
      }, 100);
    }
  };

  const slotCard = (row, index) => {
    return (
      <div className="slot-field-wrapper">
        {selectedSlot === row ? (
          <div>
            {!query.get("time") ? (
              <div className="slot-selected-card">
                <h5>
                  <span>
                    {slotNumber(row)}
                    {slotEx(row)}
                  </span>
                </h5>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    cardClicked(row);
                  }}
                >
                  Confirm
                </Button>
              </div>
            ) : (
              <div className="slot-card selected-slot">
                <div className="cursor-pointer">
                  <h4>
                    {slotNumber(row)} {slotEx(row)}
                  </h4>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className="slot-card"
            onClick={(e) => {
              setSelectedSlot(row);
            }}
          >
            <div className="cursor-pointer">
              <h4>
                {slotNumber(row)} {slotEx(row)}
              </h4>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleAddGuest = (email) => {
    const guestArray: any = [...guests];
    guestArray.push(email);
    setGuests(guestArray);
  };

  const handleRemoveGuest = (email, index) => {
    const guestArray = [...guests];
    if (index > -1) {
      guestArray.splice(index, 1);
      setGuests(guestArray);
    }
  };

  const validateEmail = (emails) => {
    var valid = true;
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      if (emails[i] === "" || !regex.test(emails[i])) {
        valid = false;
      }
    }
    return valid;
  };

  const slotEx = (hour: any) => {
    if (parseInt(hour.split(":")[0]) >= 12) return "PM";
    else return "AM";
  };

  const slotNumber = (hour: any) => {
    if (parseInt(hour.split(":")[0]) < 12) return hour;
    else
      return parseInt(hour.split(":")[0]) === 12
        ? 12 + ":" + hour.split(":")[1]
        : parseInt(hour.split(":")[0]) - 12 + ":" + hour.split(":")[1];
  };

  const onSubmit = async (formData) => {
    let date = query.get("date") || new Date();
    if (validateEmail(guests)) {
      const payload = {
        name: _.get(schedule, "event_name", ""),
        event_id: _.get(schedule, "id", ''),
        first_name: _.get(formData, "name", "").split(" ")[0],
        last_name: _.get(formData, "name", "").split(" ")[1],
        email: _.get(formData, "email", ""),
        duration: _.get(schedule, "duration", ""),
        date: moment(date).format("YYYY-MM-DD"),
        guests: guests,
        from_time: query.get("time")?.split("To")[0],
        to_time: query.get("time")?.split("To")[1],
        location_id: timeZone.value,
        additional_notes: _.get(formData, "additional_notes", ""),
        user_id: _.get(schedule, "user_id", ""),
      };

      try {
        const result = await schedulerServices.addSchedule(payload);
        if (result.flag) {
          setConfirmed(true);
          FlashMessage(result.message, "success");
        } else {
          FlashMessage(Object.values(result.errors)[0], "error");
        }
      } catch (error) {
        FlashMessage(error, "error");
      }
    } else {
      setEmailError(true);
    }
  };

  const handleBack = () => {
    const currentDate = moment(Date()).format("l");
    if (query.get("time")) {
      setSelectedSlot("");
      history.push(`${link}?date=${currentDate}`);
    } else {
      history.push(`/scheduler/${_.get(params, "candidateSlug", "")}`);
    }
  };

  const handleListPage = () => {
    history.push(`/scheduler/${_.get(params, "candidateSlug", "")}`);
  };
  if (isFetching) {
    return (
      <div className="loading-skeleton">
        <CircularProgress color="secondary" className="circular-progress" />
      </div>
    )
  }
  if (workingHour.length === 0 ) {
    return (
      <>
        <div className="schedule-container">
          <div className="schedule-header">
            <div className="no-calender">
              <Image src={noCalender} alt="no calender image" />
            </div>
            <span            >
              User has not set the working hours
            </span>

          </div>
        </div>
      </>

    )
  }
  return (
    <>
      <div className="schedule-container">
        {!confirmed && (
          <div className="schedule-date-wrapper">
            <div className="schedule-header">
              <h3>Scheduling Page </h3>
              <h5>Select a Date & Time</h5>
            </div>
            <div className="schedule-card-wrapper">
              <div className="left-col">
                <div className={!!userEventTimeZone ? " add-card" : ""}>
                  <ArrowBackOutlinedIcon
                    className="cursor-pointer back-icon"
                    onClick={handleBack}
                  />
                  <div className="profile-upload-icon">
                    {/* <span> */}
                    <Avatar
                      size="md"
                      banner={false}
                      src={_.get(schedule, "user.profile_image", "")}
                      alt="eh-company-profile"
                    />
                    {/* </span> */}
                  </div>
                  <div>
                    <b>
                      {_.get(schedule, "user.first_name", "")}{" "}
                      {_.get(schedule, "user.last_name", "")}
                    </b>
                  </div>
                  <div className="job-title-custom">
                    {_.get(schedule, "user.candidateProfile.job_title_custom", "")
                      || _.get(schedule, "user.candidateProfile.jobTitle.title", "")
                    }
                  </div>
                  <div className="description-content pt-5">
                    <div
                      className="color-circle"
                      style={{
                        backgroundColor: _.get(schedule, "event_color", ""),
                      }}
                    />
                    <h4>{_.get(schedule, "event_name", "")}</h4>
                  </div>
                  <div className="description-content">
                    <AccessTime />
                    <span>{_.get(schedule, "duration", "")}</span>
                  </div>
                  {query.get("time") && (
                    <>
                      <div className="description-content">
                        <HourglassBottom />
                        <span>
                          {slotNumber(query.get("time")?.split("To")[0])}
                          {slotEx(query.get("time")?.split("To")[0])}
                          <span className="m-5">-</span>
                          {slotNumber(query.get("time")?.split("To")[1])}
                          {slotEx(query.get("time")?.split("To")[1])}
                        </span>
                      </div>
                      <div className="description-content">
                        <CalendarToday />
                        <span>
                          {moment(selectedDate).format("dddd MMMM Do YYYY")}
                        </span>
                      </div>
                    </>
                  )}

                  <div className="description-content">
                    <Description />
                    <span
                      className="wysiwyg-data"
                      dangerouslySetInnerHTML={{
                        __html: _.get(schedule, "description", ""),
                      }}
                    />
                  </div>
                  <div>
                    {timeZone && (
                      <div className="description-content">
                        <Public className="" />
                        <span>{timeZone.value}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="date-content">
                {query.get("time") ? (
                  <div className="calendar-wrapper">
                    <h3>Enter Details</h3>
                    <form
                      id="form2"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div>
                        <p className="form-label">Name</p>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="form-input"
                          validationObj={errors}
                          control={control}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter name",
                            },
                          })}
                        />
                      </div>
                      <div>
                        <p className="form-label">Email</p>
                        <Input
                          name="email"
                          className="form-input"
                          placeholder="Email"
                          validationObj={errors}
                          control={control}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter email address",
                            },
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Enter valid email address",
                            },
                          })}
                        />
                      </div>
                      {guest && (
                        <>
                          <Button
                            color="secondary"
                            className="primary mt-10"
                            onClick={() => {
                              setGuest(false);
                            }}
                          >
                            Add Guest
                          </Button>
                        </>
                      )}
                      <div>
                        {!guest && (
                          <div>
                            <p className="form-label">Guest Email(s)</p>
                            <ChipInput
                              value={guests}
                              onAdd={(email) => handleAddGuest(email)}
                              onDelete={(email, index) =>
                                handleRemoveGuest(email, index)
                              }
                              variant="outlined"
                              placeholder="Add guests"
                              className="w-100 form-input"
                            />
                            {emailError && (
                              <p className="email-error">Enter valid email</p>
                            )}
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="form-label">
                          Please share anything that will help prepare for our
                          meeting.
                        </p>
                        <Input
                          type="text"
                          name="additional_notes"
                          placeholder="Additional Details"
                          className="form-input"
                          validationObj={errors}
                          inputRef={register({})}
                        />
                      </div>
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="text-uppercase mt-10"
                        >
                          Schedule Event
                        </Button>
                      </div>
                      <div className="form-group text-center"></div>
                    </form>
                  </div>
                ) : (
                  <form>
                    <div className="calendar-date-wrapper">
                      <Calendar
                        minDate={moment().toDate()}
                        onChange={(date) => handleDateChange(date)}
                        value={selectedDate}
                        next2Label={false}
                        prev2Label={false}
                        prevLabel={
                          <span className="custom-prev-label">
                            <img src={prevArrow} alt="arrow" />
                          </span>
                        }
                        nextLabel={
                          <span className="custom-prev-label">
                            <img src={nextArrow} alt="arrow" />
                          </span>
                        }
                      />
                    </div>
                    <div className="time-zone">
                      <h5>Time Zone</h5>
                      <SelectNew name="location_id"
                        options={TIME_ZONE_LIST.map(row => ({
                          value: row.value,
                          label: row.label
                        }
                        ))}

                        onChange={(row) => {
                          setTimeZone(row)
                        }}
                        value={{
                          value: timeZone?.value,
                          label: timeZone?.label,
                        }}

                      />
                    </div>
                  </form>
                )}
              </div>
                <div className="right-col">
                {!!timeZone.value ? (
                  <div className="confirm-slot">
                  <div className="date-wrapper">
                    {moment(selectedDate).format("MMMM Do YYYY")}
                  </div>
                  <div className="slots-wrapper">
                    {loadSlot ? (
                    <CircularProgress color="secondary" className="circular-progress" />
                    ) : (
                    <>
                      {slots.length > 0 ? (
                      <>
                        {slots.map((row, index) => slotCard(row, index))}
                      </>
                      ) : (
                      <h6>No Slots</h6>
                      )}
                    </>
                    )}
                  </div>
                  </div>
                ) : (
                  <h6>Select a Date</h6>
                )}
                </div>
            </div>
          </div>
        )}
        {confirmed && (
          <div className="scheduled-card-wrapper">
            <div>
              <div className="schedule-header confirm-header">
                <h3>Confirmed</h3>
                <h5>You are scheduled</h5>
              </div>
              <div className="confirm-card">
                <div className="back-btn">
                  <ArrowBackOutlinedIcon
                    className="cursor-pointer back-icon"
                    onClick={handleListPage}
                  />
                </div>
                <div className="d-flex">
                  <h4>{_.get(schedule, "event_name", "")}</h4>
                </div>
                <div className="description-content">
                  <AccessTime />
                  <span>{_.get(schedule, "duration", "")}</span>
                </div>
                {query.get("time") ? (
                  <>
                    <div className="description-content">
                      <HourglassBottom />
                      <span className="">
                        {slotNumber(query.get("time")?.split("To")[0])}
                        {slotEx(query.get("time")?.split("To")[0])}
                        <span className="m-5">-</span>
                        {slotNumber(query.get("time")?.split("To")[1])}
                        {slotEx(query.get("time")?.split("To")[1])}
                      </span>
                    </div>
                    <div className="description-content">
                      <CalendarToday />
                      <span className="">
                        {moment(selectedDate).format("dddd MMMM Do YYYY")}
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="description-content">
                  <Description />
                  <span
                    className="wysiwyg-data"
                    dangerouslySetInnerHTML={{
                      __html: _.get(schedule, "description", ""),
                    }}
                  />
                </div>
                <div>
                  {timeZone && (
                    <div className="description-content">
                      <Public />
                      <span>{timeZone.value}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
          
      </div>
 
    </>
  );
};

export default Schedule;
