import React, { useEffect, useState } from "react";
import copyToClipboard from "copy-to-clipboard";
import FeedCommonFooter from "../components/FeedFooter";
import FeedListContent from "../components/FeedListContent";
import FeedBodyHeader from "../FeedList/components/FeedHeader";
import { FlashMessage } from "../../../components/common";
import { Helmet } from "react-helmet";
// import RelatedFeed from './components/RelatedFeed';
import eh_logo from "../../../assets/eh_logo.png";
import Skeleton from "@material-ui/lab/Skeleton";
import { rootReducersState } from "../../../reducers";
import {
  getFeedDetailsRequest,
  postLikeRequest,
  updateFeedDetailsRequest,
  postLikeReset,
  updatePostFlagReset,
} from "../../../reducers/feeds/feedPost.reducer";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import appRoutes from "../../../routes/app.routes";
import { useHistory, useParams } from "react-router-dom";
import { ReducerFlag } from "../../../types/reducer.types";
const FeedDetails = () => {
  const params = useParams();
  const [previewImage, setPreviewImage] = useState<string>(eh_logo);
  const dispatch = useDispatch();
  const history = useHistory();
  const feedPostData = useSelector(
    ({ postFeed }: rootReducersState) => postFeed
  );
  const {
    updateFlag,
    updateMessage
  } = useSelector(({ postFeed }: rootReducersState) => postFeed.postFeed);
  const postDetails = _.get(feedPostData, "postFeed.detail", {});
  const detailsLoading = _.get(feedPostData, "postFeed.feedDetailsLoading", "");
  const detailsFlag = _.get(feedPostData, "postFeed.postDetailFlag", "");
  const likeFlag = _.get(feedPostData, "postFeed.postLikeFlag", false);
  const postFeedLikeData = _.get(feedPostData, "postFeed.likedData", {});

  useEffect(() => {
    if (postDetails?.post_media?.length) {
      setPreviewImage(postDetails.post_media[0].media_thumbnail);
    }
  }, [postDetails.post_media, previewImage]);

  useEffect(() => {
    dispatch(getFeedDetailsRequest(_.get(params, "slug", "")));

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(updateFlag)) {
      if (updateFlag === ReducerFlag.SUCCESS) {
        dispatch(getFeedDetailsRequest(_.get(params, "slug", "")));
      } else if (updateFlag === ReducerFlag.FAILED) {
        FlashMessage(updateMessage, "error");
      }
      dispatch(updatePostFlagReset());
    }


    // eslint-disable-next-line
  }, [updateFlag]);

  // Hook, post like and unlike
  useEffect(() => {
    if (likeFlag === ReducerFlag.SUCCESS) {
      dispatch(
        updateFeedDetailsRequest({
          ...postDetails,
          liked: postFeedLikeData.liked,
          post_likes_count: postFeedLikeData.totalLikes,
        })
      );
      dispatch(postLikeReset());
    }

    // eslint-disable-next-line
  }, [likeFlag]);

  useEffect(() => {
    (async function () {
      if (detailsFlag === ReducerFlag.FAILED) {
        return history.push(appRoutes.Feed.path);
      }
    })();

    // eslint-disable-next-line
  }, [detailsFlag]);

  const handlePostLike = (postDetails: any) => {
    dispatch(postLikeRequest({ post_id: postDetails.id }));
  };

  const handleCopyPostLink = (postDetails: any) => {
    const postLink = appRoutes.FeedDetails.generateFullPath(postDetails.slug);
    copyToClipboard(postLink);
    FlashMessage("Copied post link");
  };

  return (
    <>
      <Helmet>
        <meta property="og:image" content={previewImage} />
        <meta property="twitter:image" content={previewImage} />
      </Helmet>
      <div className="max-container">
        <div className="details-container">
          <div className="feed-content feed-details-content">

            {detailsLoading ? (
              <Skeleton width="20%" variant="text" />
            ) : (
              <FeedBodyHeader data={postDetails} />
            )}
            {detailsLoading ? (
              [1, 2, 3].map((row, i) => {
                return <Skeleton key={i} width="100%" variant="text" />;
              })
            ) : (
              <FeedListContent data={postDetails} />
            )}
            {detailsLoading ? (
              <Skeleton width="20%" variant="text" />
            ) : (
              <FeedCommonFooter
                data={postDetails}
                postLike={(postDetails) => handlePostLike(postDetails)}
                handleCopyPostLink={(postDetails) =>
                  handleCopyPostLink(postDetails)
                }
              />
            )}
          </div>
          {/* <RelatedFeed /> */}
        </div>
      </div>
    </>
  );
};

export default FeedDetails;
