// import React, { useEffect, useState } from "react";
// import DatePicker from "react-multi-date-picker";
// import httpRequest from "../../utils/httpRequest";
// import API_URLS from "../../utils/apiUrls";
// import AreaChart from "./AreaChart";

// const Chart = () => {
//   const [jobData, setJobData] = useState([]);
//   const [companyData, setCompanyData] = useState([]);
//   const [jobDateRange, setJobDateRange] = useState([null, null]);
//   const [companyDateRange, setCompanyDateRange] = useState([null, null]);
//   const [errorMessage, setErrorMessage] = useState("");

//   const today = new Date();
//   const lastMonth = new Date(today);
//   lastMonth.setMonth(today.getMonth() - 1);

//   const formatDate = (date) => date?.toISOString().split("T")[0];

//   const maxDate = formatDate(today);
//   const minDate = formatDate(lastMonth);

//   const fetchData = async () => {
//     try {
//       const [startJobDate, endJobDate] = jobDateRange;
//       const [startCompanyDate, endCompanyDate] = companyDateRange;

//       const payload = {
//         startDate: startJobDate,
//         endDate: endJobDate,
//       };
//       const companyPayload = {
//         startDate: startCompanyDate,
//         endDate: endCompanyDate,
//       };

//       const jobResponse = await httpRequest().post(API_URLS.jobChart, payload);
//       const companyResponse = await httpRequest().post(API_URLS.companyChart, companyPayload);

//       setJobData(jobResponse.data.data);
//       setCompanyData(companyResponse.data.data);
//     } catch (error) {
//       console.log(error.response?.data?.message || "An error occurred");
//       setErrorMessage(error.response?.data?.message || "An error occurred");
//     }
//   };

//   useEffect(() => {
//     fetchData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [jobDateRange, companyDateRange]);

//   // Handle date change for both job and company date ranges
//   const handleDateChange = (setter, minDate, maxDate) => (date) => {
//     if (date && date.length === 2) {
//       const [startDate, endDate] = date;
//       const startStr = startDate.format("YYYY-MM-DD");
//       const endStr = endDate.format("YYYY-MM-DD");

//       // Check if the selected date range is within the allowed limits
//       if (startStr < minDate || endStr > maxDate) {
//         setErrorMessage("Please select a date range within the last month.");
//       } else {
//         setErrorMessage("");
//         setter([startStr, endStr]);
//       }
//     }
//   };

//   return (
//     <div className="main-chart-container">
//       <div className="chart-container">
//         <div className="chart-box">
//           <div className="date-picker-container">
//             <label>Select Job Date Range:</label>
//             <DatePicker
//               value={jobDateRange}
//               onChange={handleDateChange(setJobDateRange, minDate, maxDate)}
//               range
//               format="YYYY-MM-DD"
//               placeholder="Select Date Range"
//               calendarPosition="bottom-left"
//               maxDate={maxDate}
//               minDate={minDate}
//             />
//           </div>
//           {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//           <h3>Job Analytics</h3>
//           <AreaChart data={jobData} />
//         </div>

//         <div className="chart-box">
//           <div className="date-picker-container">
//             <label>Select Company Date Range:</label>
//             <DatePicker
//               value={companyDateRange}
//               onChange={handleDateChange(setCompanyDateRange, minDate, maxDate)}
//               range
//               format="YYYY-MM-DD"
//               placeholder="Select Date Range"
//               calendarPosition="bottom-left"
//               maxDate={maxDate}
//               minDate={minDate}
//             />
//           </div>
//           {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//           <h3>Company Analytics</h3>
//           <AreaChart data={companyData} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Chart;


import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import AreaChart from "./AreaChart";

const Chart = () => {
  const [jobData, setJobData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const today = new Date();
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);

  const formatDate = (date) => date?.toISOString().split("T")[0];

  const maxDate = formatDate(today);
  const minDate = formatDate(lastMonth);

  const defaultJobRange = [formatDate(lastWeek), maxDate];
  const [jobDateRange, setJobDateRange] = useState(defaultJobRange);
  const [companyDateRange, setCompanyDateRange] = useState(defaultJobRange);

  const fetchData = async () => {
    try {
      const [startJobDate, endJobDate] = jobDateRange;
      const [startCompanyDate, endCompanyDate] = companyDateRange;

      const payload = {
        startDate: startJobDate,
        endDate: endJobDate,
      };
      const companyPayload = {
        startDate: startCompanyDate,
        endDate: endCompanyDate,
      };

      const jobResponse = await httpRequest().post(API_URLS.jobChart, payload);
      const companyResponse = await httpRequest().post(API_URLS.companyChart, companyPayload);

      setJobData(jobResponse.data.data);
      setCompanyData(companyResponse.data.data);
    } catch (error) {
      console.log(error.response?.data?.message || "An error occurred");
      setErrorMessage(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDateRange, companyDateRange]);

  const handleDateChange = (setter, minDate, maxDate) => (date) => {
    if (date && date.length === 2) {
      const [startDate, endDate] = date;
      const startStr = startDate.format("YYYY-MM-DD");
      const endStr = endDate.format("YYYY-MM-DD");

      if (startStr < minDate || endStr > maxDate) {
        setErrorMessage("Please select a date range within the last month.");
      } else {
        setErrorMessage("");
        setter([startStr, endStr]);
      }
    }
  };

  return (
    <div className="main-chart-container">
      <div className="chart-container">
        <div className="chart-box">
          <div className="date-picker-container">
            <label>Select Job Date Range:</label>
            <DatePicker
              value={jobDateRange}
              onChange={handleDateChange(setJobDateRange, minDate, maxDate)}
              range
              format="YYYY-MM-DD"
              placeholder="Select Date Range"
              calendarPosition="bottom-left"
              maxDate={maxDate}
              minDate={minDate}
            />
          </div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <h3>Job Analytics</h3>
          <AreaChart data={jobData} />
        </div>

        <div className="chart-box">
          <div className="date-picker-container">
            <label>Select Company Date Range:</label>
            <DatePicker
              value={companyDateRange}
              onChange={handleDateChange(setCompanyDateRange, minDate, maxDate)}
              range
              format="YYYY-MM-DD"
              placeholder="Select Date Range"
              calendarPosition="bottom-left"
              maxDate={maxDate}
              minDate={minDate}
            />
          </div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <h3>Company Analytics</h3>
          <AreaChart data={companyData} />
        </div>
      </div>
    </div>
  );
};

export default Chart;
