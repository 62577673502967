import React, { useState } from 'react';
import Slider from 'react-slick';
import Grid from "@material-ui/core/Grid";
import Modal from "../../../components/common/Modal";

import Courtney from "../../../assets/images/landing_testimonial/Courtney-Ulwelling.jpg"
import Tim from "../../../assets/images/landing_testimonial/Tim-Tilda.jpg";
import Michael from "../../../assets/images/landing_testimonial/Michael-Roman.jpg";
import Destiny from "../../../assets/images/landing_testimonial/Destiny-Brandt.jpg";
import AaronGoodin from "../../../assets/images/landing_testimonial/Aaron-Goodin.jpg";
import AnthonyAdkins from "../../../assets/images/landing_testimonial/Anthony-Adkins.jpg";
import Commablack from "../../../assets/images/comma-black.png";
import Image from "../../../components/common/Image";

type IEmployerDetails = {
	name: string
	longDescription: string
	image: any
}

const defaultEmployerDetails = {
	name: "",
	longDescription: "",
	image: ""
}

const EmployerStorySlider = () => {

	const [showModal, setShowModal] = useState<boolean>(false);
	const [employerDetails, setEmployerDetails] = useState<IEmployerDetails>(defaultEmployerDetails)

	const slidersSetting = {
		dots: true,
		infinite: true,
		speed: 3000,
		autoplaySpeed: 8000,
		autoplay: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
			  className: "slider-height", 
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},

			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					initialSlide: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			}
		]
	};

	const handleSetEmployerDetails = (id: string) => {

		const data = employersList.find(row => row.id === id)

		setEmployerDetails({
			name: data.name,
			longDescription: data.longDescription,
			image: data.image
		})

		setShowModal(true);

	}

	const handleCloseModal = () => {
		setShowModal(false);
		setEmployerDetails(defaultEmployerDetails)
	}

	const employersList = [
		{
			id: "courtney_ulwelling",
			name: "Courtney Ulwelling",
			image: Courtney,
			shortDescription: (<>
				I’ve worked with Preet and employHER for a couple of years
				now, and have been very impressed with both the mission of her
				firm as well as the quality of service and candidates
				delivered. She is eterna...
			</>),
			longDescription: "I've worked with Preet for a couple of years now, and have been very impressed with both the mission of her firm as well as the quality of service and candidates delivered. She is eternally optimistic and positive, very thorough in her research and representation of candidates. She is truly a joy to work with!"
		},
		{
			id: "destiny_brandt",
			name: "Destiny Brandt",
			image: Destiny,
			shortDescription: (<>
				Wow where do I begin?
				Preet has the most amazing
				energy, enthusiasm and grit.
				She has such an incredible
				story and has used her
				experiences to lift people
				up and motivate them.
				She is a dedicated prof...
			</>),
			longDescription: "Wow where do I begin? Preet has the most amazing energy, enthusiasm and grit. She has such an incredible story and has used her experiences to lift people up and motivate them. She is a dedicated professional who has given so many people and women a fighting chance at being successful in the professional world. I am thankful I found a friend and mentor in Preet. She is trustworthy, empathetic and loyal to her clients, candidates and team."
		},
		{
			id: "tim_talda",
			name: "Tim Talda",
			image: Tim,
			shortDescription: (<>
				Preet does an excellent job
				of determining the actual
				needs of her clients by both
				reviewing written
				descriptions about the
				position, and then
				interviewing the hiring
				manager for maximum...
			</>),
			longDescription: "Preet does an excellent job of determining the actual needs of her clients by both reviewing written descriptions about the position, and then interviewing the hiring manager for maximum understanding. She then provides a wide range of potential applicants, and then follows up for feedback on how each candidate is both perceived and how they compared to the written and unwritten requirements. She continues to iterate and refine her candidate pool to improve an ever-increasing chance of a successful match. For those interested in finding candidates from the widest possible candidate pool I would strongly recommend considering Preet for your hiring needs."
		},
		{
			id: "michael_roman",
			name: "Michael Roman",
			image: Michael,
			shortDescription: (<>
				It’s a pleasure to work together with Preet, who is an incredibly effective and
				enthusiastic CEO with her firm, employHER. She has a very strong work ethic and
				unparalleled analytical and problem solv...
			</>),
			longDescription: "It’s a pleasure to work together with Preet, who is an incredibly effective and enthusiastic CEO with her firm, employHER. She has a very strong work ethic and unparalleled analytical and problem solving"
		},
		{
			id: "aaron_goodin",
			name: "Aaron Goodin",
			image: AaronGoodin,
			shortDescription: (<>
				I approached Preet to recruit a range of different roles within my team. Preet’s
				professionalism and dedication to the task at hand was nothing short of excellent.
				One of the most proficient professionals...
			</>),
			longDescription: "I approached Preet to recruit a range of different roles within my team. Preet’s professionalism and dedication to the task at hand was nothing short of excellent. One of the most proficient professionals I have worked with. Her dedication to relationship building and ability to focus on the clients needs were inspiring. Personally, I had a friend reach out who was in need of work, I forwarded his information on to Preet and she was able to source him a few open positions that day. Thats going above and beyond for your clients."
		},
		{
			id: "anthony_adkins",
			name: "Anthony Adkins",
			image: AnthonyAdkins,
			shortDescription: (<>
				Preet is a pure networker and connector. She understands not only a client’s needs,
				she develops a very thorough strategy to accomplish the set goal that is being pursued.
				In addition, she is a very good...
			</>),
			longDescription: "Preet is a pure networker and connector. She understands not only a client’s needs, she develops a very thorough strategy to accomplish the set goal that is being pursued. In addition, she is a very good communicator and is great at utilizing her network and resources both internal and external to her organization. She is a great asset to have within any company.I'll send you their pictures as well."
		}
	]

	return (
		<>
			<Slider {...slidersSetting}>
				{employersList.map((row, key) => (
					<Grid key={key} item md={12} sm={12} xs={12}>
						<div className="l-testimonial-block">
							<div className="sign">
								<Image src={Commablack} alt="comma" />
							</div>
							<p> {row.shortDescription} </p>
							<span className="span-link" onClick={() => handleSetEmployerDetails(row.id)}>Read more</span>
							<h6>{row.name}</h6>
							<div className="employer-avatar">
								<Image src={row.image} alt="employer avatar" />
							</div>
						</div>
					</Grid>
				))}
			</Slider>
			<Modal visible={showModal} onClose={() => handleCloseModal()} className="landing-employer-testimonial-modal">
				<Image src={Commablack} alt="comma" />
				<p> {employerDetails.longDescription} </p>
				<div className="employer-details">
					<h6>{employerDetails.name}</h6>
					<div>
						<Image src={employerDetails.image} alt="employer image" />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default EmployerStorySlider;
