import { CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  ConfirmDialog,
  FlashMessage,

} from "../../../components/common";
import { rootReducersState } from "../../../reducers";
import {
  getMoreCommentsRequest,
  deleteCommentRequest,
  commentsCreateFlagReset,
  deleteCommentFlagReset,
  getRepliesRequest,
  changeReplyData,
  commentLikeRequest,
  updateFeedListData,
} from "../../../reducers/feeds/feedPost.reducer";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { ReducerFlag } from "../../../types/reducer.types";
import dots from "../../../assets/svg/dots.svg";
import { IconButton } from "@material-ui/core";
import Image from "../../../components/common/Image";
import CommentPost from "./CommentPost";
import List from "./List";
import { ReactComponent as Heart } from "../../../assets/svg/heart.svg";
import { ReactComponent as HeartActive } from "../../../assets/svg/heart-active.svg";
import appRoutes from "../../../routes/app.routes";
import { convertUTCToLocal, openUrlInNewTab } from "../../../utils/helper";
import Avatar from "../../../components/common/AvtarImage";
import Report from "../../../components/common/Report";
interface CommentsProps {
  first_name?: string;
  last_name?: string;
  profile_image?: string;
  data: any;
}

function Comments({ data }: CommentsProps) {
  const [commentId, setCommentId] = useState({});
  const [showMenu, setShowMenu] = useState(null);
  const [page, setPage] = useState<number>(2);
  const [replyPage, setReplyPage] = useState<number>(2);
  const pageSize = 5;
  const dispatch = useDispatch();
  const {
    commentCreateFlag,
    commentErrorMessage,
    deleteCommentMessage,
    deleteCommentLoading,
    deleteCommentFlag,
    replyListFlag,
    replyData,
    replyList,
    replyEditFlag,
    replyCreateFlag,
    commentLikeFlag,
    commentLikedData,
    isHashtagPage,
  } = useSelector(({ postFeed }: rootReducersState) => postFeed.postFeed);

  const [openDeleteCommentModal, setOpenDeleteCommentModal] =
    useState<boolean>(false);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  const currentUserId = _.get(sessionReducer, "currentUser.id", "");
  const firstName = _.get(sessionReducer, "currentUser.first_name", "");
  const lastName = _.get(sessionReducer, "currentUser.last_name", "");
  const currentUserImage = _.get(
    sessionReducer,
    "currentUser.profile_image",
    ""
  );
  const { newCommentLoading, newCommentId } = useSelector(
    ({ postFeed }: rootReducersState) => postFeed.postFeed
  );
  const [editComment, setEditComment] = useState<any>({});
  const [replyComment, setReplyComment] = useState<any>({});
  const targetData = isHashtagPage ? 'hashtagData' : "data";
  const feedReducer = useSelector(
    ({ postFeed }: rootReducersState) => postFeed
  );
  const postResponse = _.get(feedReducer, "postFeed", {});
  const postData = _.get(postResponse, targetData, []);
  const [openCommentReport, setOpenCommentReport] = useState(false)
  // const targetData = isHashtagPage ? hashtagPostsDetail : detail
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(commentCreateFlag)) {
      if (commentCreateFlag === ReducerFlag.SUCCESS) {
      } else if (commentCreateFlag === ReducerFlag.FAILED) {
        FlashMessage(commentErrorMessage, "error");
      }

      dispatch(commentsCreateFlagReset());
    }

    // eslint-disable-next-line
  }, [commentCreateFlag]);

  // delete message
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(deleteCommentFlag)) {
      setOpenDeleteCommentModal(false);
      if (deleteCommentFlag === ReducerFlag.SUCCESS) {
      } else if (deleteCommentFlag === ReducerFlag.FAILED) {
        FlashMessage(deleteCommentMessage, "error");
      }
      dispatch(deleteCommentFlagReset());
    }

    // eslint-disable-next-line
  }, [deleteCommentFlag]);

  // load more payload
  const payload = {
    id: data.id,
    query: {
      page,
      pageSize,
    },
  };

  // Load more comment
  const onLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
    dispatch(getMoreCommentsRequest(payload));
  };

  const handleEdit = (data: any) => {
    setEditComment(data);
  };

  const setComment = (data: any) => {
    setEditComment({});
  };

  const handleReply = (formData: any) => {
    setReplyComment(formData);
  };

  const moreReply = (formData: any) => {
    const payload = {
      id: formData.id,
      query: {
        page: replyPage,
        pageSize,
        post_id: formData.post_id,
      },
    };
    setReplyPage((prevPage) => prevPage + 1);
    dispatch(getRepliesRequest(payload));
  };

  useEffect(() => {
    if (replyCreateFlag === ReducerFlag.SUCCESS) {

      const oldData = replyList;
      let currentData = replyData;
      if (oldData.length > 0) {
        const newData = oldData.map((item: any) => {
          if (parseInt(item.comment_id) === parseInt(currentData.parent_id)) {
            let user = {
              first_name: _.get(sessionReducer, "currentUser.first_name", ""),
              last_name: _.get(sessionReducer, "currentUser.last_name", ""),
              user_id: _.get(sessionReducer, "currentUser.id", ""),
              profile_image: _.get(
                sessionReducer,
                "currentUser.profile_image",
                ""
              ),
            };

            currentData = {
              ...currentData,
              user,
            };
            let rows = [...item.rows, ...[currentData]];
            return {
              ...item,
              rows: rows || [],
              count: item.count + 1,
            };
          } else {
            return item;
          }
        });

        dispatch(changeReplyData(newData));
      }
    } else if (replyCreateFlag === ReducerFlag.FAILED) {
      FlashMessage(commentErrorMessage, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyCreateFlag]);

  useEffect(() => {
    if (replyListFlag === ReducerFlag.SUCCESS) {
      const oldData = replyList;
      const isAvailable =
        oldData.length > 0
          ? oldData.filter((item) => item?.comment_id === replyData?.comment_id)
          : [];
      const result =
        oldData.length > 0
          ? oldData.filter((item) => item?.comment_id !== replyData?.comment_id)
          : [];

      const filterData =
        isAvailable?.length > 0
          ? [
            {
              ...isAvailable?.[0],
              rows: [...replyData?.rows, ...isAvailable?.[0]?.rows],
            },
          ]
          : [...replyList, replyData];
      const rows =
        isAvailable?.length > 0 ? [...result, ...filterData] : filterData;

      dispatch(changeReplyData(rows));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyListFlag]);

  useEffect(() => {
    if (replyEditFlag === ReducerFlag.SUCCESS) {
      const oldData = replyList;
      const newData = oldData.map((item: any) => {
        if (item.comment_id === replyData.parent_id) {
          let rows = item.rows.map((row: any) => {
            if (row.id === replyData.id) {
              return {
                ...row,
                comment_text: replyData.comment_text,
                updated_at: replyData.updated_at,
                comment_type: replyData.comment_type,
              };
            } else {
              return row;
            }
          });
          return {
            ...item,
            rows: rows || [],
          };
        } else {
          return item;
        }
      });

      dispatch(changeReplyData(newData));
    } else if (replyEditFlag === ReducerFlag.FAILED) {
      FlashMessage(commentErrorMessage, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyEditFlag]);

  const handleLike = (value: any) => {
    dispatch(
      commentLikeRequest({ post_id: value.post_id, comment_id: value.id })
    );
  };

  useEffect(() => {
    if (commentLikeFlag === ReducerFlag.SUCCESS) {
      if (
        commentLikedData.parentId === null &&
        data.id === commentLikedData.postId
      ) {

        const updateRow = data?.post_comments.map((row) => {
          if (row.id === commentLikedData.commentId && row.parent_id === null) {
            return {
              ...row,
              liked: commentLikedData.liked,
              comment_likes_count: commentLikedData.totalLikes,
            };
          } else {
            return row;
          }
        });

        // const updatedData = targetData.data.map((post) => {
        //   if (post.id === commentLikedData.postId) {
        //     return {
        //       ...post,
        //       post_comments: updateRow,
        //     };
        //   } else {
        //     return post;
        //   }
        // });
        const updatedData = postData.map((post) => {
          if (post.id === commentLikedData.postId) {
            return {
              ...post,
              post_comments: updateRow,
            };
          } else {
            return post;
          }
        });
        dispatch(updateFeedListData(updatedData));

      }
    }

    // eslint-disable-next-line
  }, [commentLikeFlag]);

  const handleDocumentClick = (event) => {
    // Close the dropdown when clicking outside
    if (!event.target.closest('.feed-header-more')) {
      setShowMenu(null);
    }
  };

  useEffect(() => {
    // Add click event listener to the document
    document.addEventListener('click', handleDocumentClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <>
      <div className="comments-wrapper">
        <div className="comments-input">
          <CommentPost
            data={data}
            item={undefined}
            setComment={undefined}
            reason={"main-comment"}
          />
        </div>
        {!_.isEmpty(data.post_comments) && (
          <div className="comments-content">
            {(data?.post_comments).map((item: any, index) => {
              return (
                <div key={index}>
                  {item && item["parent_id"] === null && (
                    <Grid container key={index}>
                      <Grid item xs={12}>

                        <div className="comment-inner-wrapper">
                          <div className="user-img">
                            {_.isEmpty(item?.user?.profile_image) &&
                              !currentUserImage ? (
                              <IconButton
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                              >
                                <Avatar />
                              </IconButton>
                            ) : (
                              <Avatar
                                src={item?.user?.profile_image || currentUserImage}
                              />
                            )}{" "}
                          </div>
                          <div className="comment-content-wrapper">
                            <div className="user-comment comment-card">
                              <h6
                                className="cursor-pointer link"
                                onClick={() => {
                                  const slug = item?.user?.slug;
                                  if (slug) {
                                    const url =
                                      appRoutes.candidatePublicProfile.generateFullPath(
                                        slug
                                      );
                                    openUrlInNewTab(url);
                                  }
                                }}
                              >
                                {_.get(item, "user.first_name", firstName) +
                                  " " +
                                  _.get(item, "user.last_name", lastName)}
                              </h6>
                              {(newCommentLoading && item.id === newCommentId) ? (
                                <div className="loading-placeholder">
                                  <CircularProgress color="secondary" className="circular-progress" />
                                </div>
                              ) : _.get(editComment, "id") === item.id ? (
                                <CommentPost
                                  data={data}
                                  item={item}
                                  setComment={setComment}
                                  reason={"edit-comment"}
                                />
                              ) : item.comment_type === 'image' ? (
                                <Image
                                  src={item.comment_text}
                                  height="50px"
                                />
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.comment_text?.replace(
                                      /(\r\n|\n|\r)/gm,
                                      "<br/>"
                                    ),
                                  }}
                                />
                              )}
                            </div>
                            <div className="mb-5 d-flex gap-1 mt-2">
                              <span className="like-btn cursor-pointer">
                                {_.get(item, "liked", 0) === 1 ? (
                                  <span className="like-active">
                                    <HeartActive
                                      width={13}
                                      height={13}
                                      onClick={() => {
                                        handleLike(item);
                                      }}
                                    />
                                  </span>
                                ) : (
                                  <Heart
                                    width={13}
                                    height={13}
                                    onClick={() => {
                                      handleLike(item);
                                    }}
                                  />
                                )}
                              </span>
                              <span className="font-sm">
                                {" "}
                                {_.get(item, "comment_likes_count", 0)}{" "}
                              </span>
                              <span
                                className="font-sm cursor-pointer"
                                onClick={() => {
                                  handleReply(item);
                                }}
                              >
                                | Reply
                              </span>
                              <span className="font-sm">
                                &bull; {_.get(item, "reply_count", 0)}{" "}
                                {_.get(item, "reply_count", 0) <= 1
                                  ? "reply"
                                  : "replies"}
                              </span>
                            </div>
                            <div>
                              {_.get(replyComment, "id") === item.id && (
                                <CommentPost
                                  data={data}
                                  item={replyComment}
                                  setComment={setComment}
                                  reason={"reply-comment"}
                                />
                              )}
                            </div>
                            <div>
                              {replyList &&
                                replyList.length > 0 &&
                                replyList.map((itm: any, key) => {
                                  return (
                                    <div key={key}>
                                      {parseInt(itm.comment_id) === item.id &&
                                        itm.rows.length > 0 && (
                                          <div>
                                            <div
                                              className="load-more ml-10"
                                              onClick={() => {
                                                moreReply(item);
                                              }}
                                              hidden={
                                                itm.rows.length >=
                                                item.reply_count
                                              }
                                            >
                                              <span className="previous-button">
                                                <b> Previous Comments </b>
                                              </span>
                                            </div>
                                            <List data={itm} />
                                          </div>
                                        )}
                                    </div>
                                  );
                                })}
                            </div>

                            <div className="comment-content-footer mt-10">
                              <span className="post-time">
                                {convertUTCToLocal(item.created_at)}
                              </span>
                              {currentUserId === item.user_id ? (
                                <div
                                  onClick={() =>
                                    setShowMenu((prevIndex) => (prevIndex === index ? null : index))
                                  }
                                  className="feed-header-more "
                                >
                                  <Image
                                    src={dots}
                                    alt="postImage"
                                    className="dot-icon"
                                  />
                                  {showMenu === index && (
                                    <ul>
                                      {item.comment_type !== 'image' && (
                                        <li
                                          onClick={() => {
                                            handleEdit(item);
                                          }}
                                        >
                                          Edit Comment
                                        </li>
                                      )}

                                      <li
                                        onClick={() => {
                                          setCommentId({
                                            postId: item.post_id,
                                            id: item.id,
                                            isLast:
                                              data?.post_comments.length === 1
                                                ? true
                                                : false,
                                          });
                                          setOpenDeleteCommentModal(true);
                                        }}
                                      >
                                        Delete Comment
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    setShowMenu((prevIndex) => (prevIndex === index ? null : index))
                                  }
                                  className="feed-header-more"
                                >
                                  <Image src={dots} alt="postImage" className="dot-icon" />
                                  {showMenu === index && (
                                    <ul>
                                      <li onClick={() => setOpenCommentReport(true)}>Report Comment</li>
                                    </ul>
                                  )}
                                </div>
                              )}

                              {openCommentReport && (
                                <Report
                                  referenceId={item.id}
                                  type="post_comment"
                                  onClose={() => setOpenCommentReport(false)}
                                  openReports={true}
                                />
                              )}
                            </div>

                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )
                  }

                </div>

              );
            })}

            {data.post_comments_count > 1 &&
              data.post_comments.length < data.post_comments_count && (
                <div className="load-more" onClick={onLoadMore}>
                  <span>
                    <b>Load older comments</b>
                  </span>
                </div>
              )}
          </div>
        )}
      </div >
      {/* delete comment starts here */}
      < ConfirmDialog
        visible={openDeleteCommentModal}
        bodyText="Are you sure you want to delete this comment?"
        confirmText="Delete"
        loading={deleteCommentLoading}
        onConfirm={() => dispatch(deleteCommentRequest(commentId))
        }
        onCancel={() => setOpenDeleteCommentModal(false)}
      />
    </>
  );
}

export default Comments;
