import React from "react";

const SolutionDropdown = () => {
  return (
    <div className="dropdown-menu">
      <ul className="sub-ul">
        <li>Contingent Staffing</li>
        <li>Direct Hire</li>
      </ul>
    </div>
  );
};

export default SolutionDropdown;