import CloudUploadRounded from "@material-ui/icons/CloudUploadRounded";
import React, { useRef, useState } from "react";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {
  Button,
} from "@material-ui/core";
import { FlashMessage } from "../../../components/common";
import marketingSolutionServices from "../../../services/marketingSolution.services";
import {
  SAMPLE_CSV_DOWNLOAD,
  EMAIL_SUBSCRIPTION_OPTIONS,
  SubscriptionStatus,
} from "../../../utils/appConstants";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";
import { useSelector, useDispatch } from "react-redux";
import { rootReducersState } from "../../../reducers";
import {
  setParsedContacts,
  setSubscriptionStatus,
} from "../../../reducers/company/companyMarketingSolution.reducer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { SelectNew } from "../../../components/common";


const UploadContact = () => {
  const [contactFile, setContactFile] = useState<File>();
  const [selectedVal, setSelectedVal] = useState("");
  const inputRef: any = useRef();
  const history = useHistory();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  const dispatch = useDispatch();

  const handleContinue = async () => {
    console.log("contactFile", contactFile);

    if (contactFile) {
      try {
        const formData = new FormData();
        formData.append("file", contactFile);
        formData.append("tags", JSON.stringify([1, 2, 3]));

        // const xlsData = await contactFile.arrayBuffer();
        // const workbook = XLSX.read(xlsData);
        // const workSheet = workbook.Sheets[workbook.SheetNames[0]];
        // const jsonData = XLSX.utils.sheet_to_json(workSheet, {
        //   raw: true,
        // });

        // Check if any record exists
        // if (jsonData.length === 0) {
        //   FlashMessage("At least one record is required", "error");
        //   return;
        // }

        try {
          const result = await marketingSolutionServices.parseContactsFile(
            formData
          );

          if (result.flag) {
            // FlashMessage(result.message, "info");
            dispatch(setParsedContacts(result?.data));
            history.push(appRoutes.createTags.generatePath(companySlug));
          } else {
            // handleErrors(result);
            FlashMessage(result.message, "error");
          }
        } catch (error) {
          FlashMessage(error?.message, "error");
        }
      } catch (error) {
        FlashMessage("Please upload valid File", "error");
        console.log("error", error);
      }
    }
  };

  const handleSubscriptionStatusChange = (value) => {
    setSelectedVal(value);
    const { label } = value;
    if (label in SubscriptionStatus) {
      const subscriptionStatus = SubscriptionStatus[label];
      dispatch(setSubscriptionStatus(subscriptionStatus));
    }
  };

  const handleDownload = () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = SAMPLE_CSV_DOWNLOAD;
    a.download = "Sample File";
    a.click();
    window.URL.revokeObjectURL(SAMPLE_CSV_DOWNLOAD);
    a.remove();
  };

  const _handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setContactFile(file);
    } else {
      FlashMessage("Please upload valid File", "error");
    }
  };

  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <div className='upload-contact-wrapper'>
        <h3>Upload your contacts file</h3>
        <div className="select-wrapper">
          <SelectNew
            name="email_subscription"
            placeholder="Select Status"
            options={EMAIL_SUBSCRIPTION_OPTIONS}
            isSearchable={false}
            className="select-box"
            value={selectedVal}
            onChange={handleSubscriptionStatusChange}
          />
          <Button className="back-btn btn-link" onClick={handleBack}>
            <ArrowBackIosIcon
              width="10"
              height="18"
              style={{ color: "#FF0990A6" }}
            />{" "}
            Back
          </Button>
        </div>
      </div>
      <div className="draggable-field">
        <input
          ref={inputRef}
          hidden
          accept=".csv"
          type="file"
          onChange={_handleUploadFile}
          placeholder="Banner"
        />
        <div
          className="dropzone-wrapper"
          onClick={() => inputRef.current.click()}
        >
          <label>
            {contactFile ? (
              <>
                <span className='dropzone-text'>
                  {contactFile.name}
                </span>
                <InsertDriveFileIcon fontSize="large" color="primary" />
              </>
            ) : (
              <>
                <span className='dropzone-text'>
                  Browse Contact
                </span>
                <CloudUploadRounded fontSize="large" color="primary" />
              </>
            )}
          </label>
        </div>
      </div>
      <div className="continue-btn-wrapper">
          <Button onClick={handleContinue} className='continue-btn btn btn-primary'>
            Continue
          </Button>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={handleDownload}
        >
          Download Sample File
        </div>
      </div>
    </>
  );
};
export default UploadContact;
