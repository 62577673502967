import React, { useEffect, useState } from "react";
import marketingSolutionServices from "../../../../services/marketingSolution.services";
import { Card, CardContent, Button, makeStyles, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../../routes/app.routes";
import { rootReducersState } from "../../../reducers";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 530,
    marginTop: 20,
    minHeight: 200,
    position: "relative",
    display: "flex",
    alignItems: "center",
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "&:hover $hoverOverlay": {
      // boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.2)",
      // border: "2px solid #ff0a90",
      opacity: 1,
    },
  },
  content: {
    textAlign: "left",
    pointerEvents: "none",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    pointerEvents: "none",
  },
  hoverOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    pointerEvents: "none",
  },
  loadButton: {
    pointerEvents: "auto",
  },
}));

const EmailTemplates = () => {
  const [templatesList, setTemplatesList] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const filteredList = templatesList?.filter(
    (item: any) => item.id === Number(id)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: any = await marketingSolutionServices.fetchEmailTemplates();
        if (res.flag) {
          setTemplatesList(res?.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleLoadDesign = (design) => {
    if (!_.isEmpty(design)) {
      history.push({
        pathname: appRoutes.emailEditor.generatePath(companySlug),
        state: { design_json: design.design_json, id: design.template_id, is_campaign: false },
      });
    }
  };

  return (
    <Grid container spacing={4}>
      {(id && !_.isEmpty(filteredList) ? filteredList : templatesList)?.map(
        (template: any, index) => (
          <Grid item xs={id && !_.isEmpty(filteredList) ? 12 : 4}  key={index}>
            <Card key={index} className={classes.card}>
              <div className={classes.overlay}></div>
              <CardContent
                className={classes.content}
                dangerouslySetInnerHTML={{ __html: template.design_html }}
              />
              <div className={classes.hoverOverlay}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.loadButton}
                  onClick={() => handleLoadDesign(template)}
                >
                  Edit Template
                </Button>
              </div>
            </Card>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default EmailTemplates;
