import React, {  useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Videocam as VideoCamIcon,
  CloseRounded as CloseIcon,
} from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import { ReactComponent as Logo } from "./../../assets/svg/Logo.svg";
import { ReactComponent as ToggleIcon } from "./../../assets/svg/toggle-icon.svg";
import UserProfileIcon from "../../components/common/UserProfileIcon";
import HeaderSearchBar from "../../pages/SearchAndConnect/components/HeaderSearchBar";
import { get, isEmpty } from "lodash";
import appRoutes from "../../routes/app.routes";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../reducers";
import { useLocation } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Notifications from "@material-ui/icons/Notifications";
import { Badge } from "@material-ui/core";
import GradeIcon from "@material-ui/icons/Grade";
import useSessionUser from "../../hooks/useSessionUser";
import MessageIcon from "@material-ui/icons/Message";
import SolutionDropdown from "./SolutionDropdown";
import IndustryDropdown from "./IndustryDropdown";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { Button, Modal } from "../../components/common";
import ProdQr from "./../../assets/svg/prod_mobile_download_qr.svg";
import StageQr from "./../../assets/svg/stage_mobile_download_qr.svg";
import { IS_DEVELOPMENT } from "../../utils/appConstants";

const _ = { get, isEmpty };
const DefaultHeader = () => {
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenUser = _.get(sessionReducer, "currentUser", {});
  const receivedCount = _.get(sessionReducer, "currentUser.receivedCount", 0);
  const notificationCount = _.get(
    sessionReducer,
    "currentUser.notificationCount",
    0
  );
  const candidateMessageCount = _.get(
    sessionReducer,
    "currentUser.candidateMessageCount",
    0
  );
  const [openNav, setOpenNav] = useState(false);
  const [openDownloadApp, setOpenDownloadApp] = useState(false);
  const location = useLocation();
  const { IS_COMPANY } = useSessionUser();

  const handleMobileMenuClick = () => {
    setOpenNav(!openNav);
  };
  const handleActiveLink = (isActive: boolean): string =>
    isActive ? "active" : "inactive";

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const [isDropdown, setDropdown] = useState(false);

  const handleMouseEnterEvent = () => {
    setDropdown(true);
  };

  const handleMouseLeaveEvent = () => {
    setDropdown(false);
  };
  // const downloadAppClick = () => {
  //   openUrlInNewTab('https://play.google.com/store/apps/details?id=com.app.employher');

  // }

  return (
    <header className="default-navbar loggedin">
      <nav className="navbar-expand-lg">
        <span className="navBrand navbar-brand">
          <NavLink to={appRoutes.home.path}>
            <Logo />
          </NavLink>
        </span>

        {![appRoutes.home.path].includes(location.pathname) &&
          !_.isEmpty(tokenUser) && (
            <HeaderSearchBar key={1} className="desktop-search" />
          )}
        <div className="nav-link desktop-navbar-nav" id="desktop-navbar-nav">
          <div className="justify-content-end w-100 navLinks navbar-nav">
            {!IS_COMPANY && (
              <>
                <div className="nav-item mobile-search">
                  {![appRoutes.home.path].includes(location.pathname) &&
                    !_.isEmpty(tokenUser) && (
                      <HeaderSearchBar
                        key={1}
                        searchLabel
                        className="mobile-search"
                      />
                    )}
                </div>
                {_.isEmpty(tokenUser) ? (
                  <div className="nav-item">
                    <NavLink
                      to={appRoutes.home.path}
                      exact
                      className={handleActiveLink}
                    >
                      <span className="icon">
                        <HomeIcon />
                      </span>
                      <span>Home</span>
                    </NavLink>
                  </div>
                ) : (
                  <div className="nav-item">
                    <NavLink
                      to={appRoutes.Feed.path}
                      className={handleActiveLink}
                    >
                      <span className="icon">
                        <HomeIcon />
                      </span>
                      <span>Home</span>
                    </NavLink>
                  </div>
                )}
                {_.isEmpty(tokenUser) && (
                  <div className="nav-item">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <DeviceHubIcon />
                      <span className="menu">
                        <span>Solutions</span>

                        {isDropdownVisible && <SolutionDropdown />}
                      </span>
                    </a>
                  </div>
                )}

                {_.isEmpty(tokenUser) && (
                  <div className="nav-item">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      onMouseEnter={handleMouseEnterEvent}
                      onMouseLeave={handleMouseLeaveEvent}
                    >
                      <BusinessIcon />
                      <span className="menu">
                        <span>Industry Expertise</span>
                        {isDropdown && <IndustryDropdown />}
                      </span>
                    </a>
                  </div>
                )}
                {!_.isEmpty(tokenUser) && (
                  <div className="nav-item">
                    <NavLink
                      to={appRoutes.candidateConnections.path}
                      className={handleActiveLink}
                    >
                      <span className="icon">
                        <PeopleAltIcon />
                      </span>
                      {receivedCount > 0 ? (
                        <Badge
                          className="badge-blue"
                          badgeContent={receivedCount}
                        >
                          <span>Connections</span>
                        </Badge>
                      ) : (
                        <span>Connections</span>
                      )}
                    </NavLink>
                  </div>
                )}
                <div className="nav-item">
                  <NavLink
                    to={appRoutes.candidateJobSearch.path}
                    className={handleActiveLink}
                  >
                    <span className="icon">
                      <BusinessCenterIcon />
                    </span>
                    <span>Careers</span>
                  </NavLink>
                </div>
                {!_.isEmpty(tokenUser) && (
                  <div className="nav-item">
                    <NavLink
                      to={appRoutes.candidateVideos.path}
                      className={`video-studio ${handleActiveLink}`}
                    >
                      <VideoCamIcon /> <span>Video Studio</span>
                    </NavLink>
                  </div>
                )}
                {!_.isEmpty(tokenUser) && (
                  <div className="nav-item">
                    <NavLink
                      to={appRoutes.candidateMessages.altPath}
                      className={handleActiveLink}
                    >
                      <span className="icon">
                        <MessageIcon />
                      </span>
                      {candidateMessageCount > 0 ? (
                        <Badge
                          className="badge-blue-message"
                          badgeContent={candidateMessageCount}
                        >
                          <span>Messages</span>
                        </Badge>
                      ) : (
                        <span>Messages</span>
                      )}
                    </NavLink>
                  </div>
                )}
              </>
            )}
            {!_.isEmpty(tokenUser) && (
              <div className="nav-item">
                <NavLink
                  to={appRoutes.connectionNotifications.path}
                  className={handleActiveLink}
                >
                  <span className="icon">
                    <Notifications />
                  </span>
                  {notificationCount > 0 ? (
                    <Badge
                      className="badge-blue"
                      badgeContent={notificationCount}
                    >
                      <span>Notifications</span>
                    </Badge>
                  ) : (
                    <span>Notifications</span>
                  )}
                </NavLink>
              </div>
            )}
            {_.isEmpty(tokenUser) && (
              <div className="nav-item">
                <NavLink
                  to={appRoutes.contactUs.path}
                  className={handleActiveLink}
                >
                  <span className="icon">
                    <GradeIcon />
                  </span>
                  <span>Contact Us</span>
                </NavLink>
              </div>
            )}
            <UserProfileIcon key="default-header" />

            {_.isEmpty(tokenUser) && (
              <div className="nav-item">
                <Button
                  className="download-app-btn"
                  onClick={() => setOpenDownloadApp(true)}
                >
                  Download the App
                  {/* Get the App */}
                  <CallMadeIcon />
                </Button>
              </div>
            )}
          </div>
        </div>
        <button
          onClick={handleMobileMenuClick}
          aria-controls="responsive-navbar-nav"
          type="button"
          aria-label="Toggle navigation"
          className="navbar-toggler mobile-nav-icon collapsed"
        >
          <span className="navbar-toggler-icon">
            {openNav ? <CloseIcon /> : <ToggleIcon />}
          </span>
        </button>
        <Modal
          visible={openDownloadApp}
          onClose={() => setOpenDownloadApp(false)}
          title="Download the App"
          className="download-app-modal"
        >
          <div className="download-app-content">
            <div className="app-qr">
              {/* <AppQRCode key={Date.now()} /> */}
              <img
                src={IS_DEVELOPMENT ? StageQr : ProdQr}
                alt="QR Code"
                key={Date.now()}
              />
            </div>
            <p className="scan-text">
              Scan this QR code to install the app on your phone
            </p>
            <p className="light-gray-text">
              You’ll be redirected to the correct app store <br />{" "}
              automatically.
            </p>
            <div>
              <Button
                type="submit"
                color="primary"
                className="submit-button text-uppercase"
                onClick={() => setOpenDownloadApp(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </nav>
    </header>
  );
};

export default DefaultHeader;
