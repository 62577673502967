import React from 'react';
import Chart from 'react-apexcharts';

const AreaChart = ({ data }) => {
  // Format the date to '10 May 2024' format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  // Format dates
  const dates = data.map(item => formatDate(item.date));
  const totalViews = data.map(item => item.total_count);
  const uniqueViews = data.map(item => item.unique_count);

  const options = {
    chart: {
      type: 'area',
      stacked: true,
      toolbar: { show: false },
    },
    colors: ['#4CAF50', '#2196F3'], // Colors for Total and Unique views
    xaxis: {
      categories: dates,
      title: { text: 'Date' },
    },
    yaxis: {
      title: { text: 'View Count' },
    },
    title: {
      text: 'Total and Unique Views',
      align: 'center',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.1,
        stops: [0, 90, 100],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
  };

  const series = [
    {
      name: 'Total Views',
      data: totalViews,
    },
    {
      name: 'Unique Views',
      data: uniqueViews,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height={400} />
    </div>
  );
};

export default AreaChart;
