import React   from "react";
import ContactUsHeader from "./components/ContactUsHeader";
import { Helmet } from "react-helmet";

const ContactUs = () => {

  return (
    <>
      <Helmet>
        <title>employHER | Contact Us</title>
        <meta
          name="description"
          content="Have a question regarding any job or any technical difficulty? Contact us any time or submit a report to get the help that you need."
        />
      </Helmet>
      <div className="max-container">
        <div className="contact-us-content">
          <ContactUsHeader />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
